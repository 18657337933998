/* Scss Document */
#main .mailForm .mailTable td input[type="password"],
input.ss-input--password[type="password"] {
	padding: 2px 8px;
	width: 388px;
	height: 35px;
	font-size: 1.4rem;
	box-sizing: border-box;
	appearance: none;
	-webkit-appearance: none;
	border: 1px solid #C4C4C3;
	border-radius: 0;
}

.errorText {
	margin: 50px 0 50px 6px;
	font-size: 1.8rem;
	line-height: 1.8;
	text-align: center;
}

.shopping-payment-reserv {
	margin-top: 20px;
	line-height: 1.67;
	color: #ff0000;
}

.shopping-confirm-text {
	margin-top: 30px;
	line-height: 1.67;
	color: #ff0000;
	text-align: center;
	@media all and (max-width: 767px) {
		margin-left: 25px;
		margin-right: 25px;
		font-size: 16px;
		text-align: left;
	}
}

.cart-arrival {
	color: #ff0000;
	font-size: 14px;
}

.cart-note {
	color: #ff0000;
	margin-bottom: 40px;

	&__label {
		font-size: 16px;
	}

	dl {
		margin-top: 5px;

		dt {
			font-weight: bold;
		}
	}

	.mini {
		font-size: 12px;
		margin-top: 5px;
	}
}

#main .step2 .sec03.u-mb-15 {
	margin-bottom: 15px;
}

.p-cart__product-calculator {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	@media all and (max-width: 767px) {
		border-top: #c5c5c4 solid 1px;
		border-bottom: #c5c5c4 solid 1px;
	}

	&-quantity {
		margin: 0 5px;
		@media all and (max-width: 767px) {
			font-size: 1.5em;
		}
	}

	&-button {
		border: #EFEFEF solid 1px;
		background: rgba(0,0,0,0.02);
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0;
		font-size: 15px;
		@media all and (max-width: 767px) {
			border-top: none;
			border-right: #c5c5c4 solid 1px;
			border-bottom: none;
			border-left: #c5c5c4 solid 1px;
		}
		&:disabled {
			background: rgba(0,0,0,0.12);
		}
	}
}

#main .step2 textarea[name^="offer"] {
	height: auto;
}
@media all and (max-width: 767px) {
	#main .mailForm .mailTable td input[type="password"],
	input.ss-input--password[type="password"] {
		padding: 2px 12px;
		width: 100%;
		height: 46px;
		font-size: 1.25rem;
	}

	.comProduct .numUl li .num span {
		padding-top: 0;
	}

	.present-complete-text {
		margin: 0 15px;
	}

	#main .step2 .sec05 .checkUl li {
		width: 100%;
	}
}

#main .comPhotoUl li .txtBox .icon-box {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -2px;

	.icon {
		width: auto;
		margin: 2px;
		padding: 5px 6px 2px;
	}
}
@media all and (max-width: 767px) {
	#main .comPhotoUl li .txtBox .icon-box {
		.icon {
			font-size: 10px;
		}
	}
}
//201911改修分
.ss-container {
	position: relative;
	z-index: 1;
	@media all and (max-width: 767px) {
		//padding-bottom: 51px;
	}
}

.ss-header__logo {
	#gHeader.ss-header .ss-header__contents & {
		@media all and (max-width: 767px) {
			margin: 0 109px 0 15px;
			text-align: left;
		}
	}
}

.ss-search-products {
	&__modal-triger {
		display: block;
		width: 100%;
		height: 46px;
		font-size: 1.65rem;
		border: 1px solid #F1F1F1;
		padding: 2px 10px 3px;
		box-sizing: border-box;
		background-color: rgb(248, 248, 248);
		border-radius: 4px;
		line-height: 39px;
		position: relative;
		color: #000;

		&:link:hover {
			color: #000;
		}

		&:after {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 5px 0 5px;
			border-color: #000000 transparent transparent transparent;
		}
	}

	&__radio {
		&-field {
			display: none;

			&-item {
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				border-bottom: solid 1px rgba(0,0,0,0.24);
				position: relative;

				&:last-child {
					border-bottom: none;
				}
			}
		}

		&-label {
			-ms-flex-positive: 1;
			flex-grow: 1;
			padding: 20px;
			font-size: 1.6rem;
			@media all and (max-width: 767px) {
				padding: 10px 15px;
				font-size: 1.4rem;
			}
		}

		&-input {
			display: none;
		}

		&-button {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: #fff;
			border: 3px solid #c2c2c2;

			&-inner {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				transform: translate(-50%, -50%);
				background: #c2c2c2;
			}

			.ss-search-products__radio-input:checked + & {
				border: 3px solid #1a73e8;

				.ss-search-products__radio-button-inner {
					background: #1a73e8;
				}
			}
		}
	}
}

.ss-modal {
	z-index: 1001;

	.modaal-container {
		border-radius: 8px;
	}

	.modaal-content-container {
		padding: 0;
	}

	.modaal-close {
		&:hover {
			background: #000;

			&:after,
			&:before {
				background: #fff;
			}
		}
	}
}

.ss-aw-recommend {
	&__list {
		#main & {
			margin-bottom: 59px;
			@media all and (max-width: 767px) {
				margin-bottom: 30px;
			}
		}

		&-item {
			display: -ms-flexbox;
			display: flex;
		}
	}

	&__link {
		-ms-flex-direction: column;
		flex-direction: column;

		#main .ss-aw-recommend__list .ss-aw-recommend__list-item & {
			display: -ms-flexbox;
			display: flex;
		}
	}

	&__title {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;

		&-icon {
			line-height: 1;

			&-pic {
				transform: translateY(-2px);
			}
		}

		&-text {
			margin-left: 5px;
		}
	}

	&__price {
		padding-right: 0;

		&--base,
		&--taxin {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: baseline;
			align-items: baseline;
		}

		&-text {
			margin-right: 5px;
			white-space: nowrap;
			@media all and (max-width: 767px) {
				margin-right: 2px;
			}
		}

		&-number {
			#main .ss-aw-recommend__list .ss-aw-recommend__list-item .ss-aw-recommend__price--base & {
				font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
				line-height: 1.3;
				letter-spacing: 0.025em;
				font-weight: bold;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				@media all and (max-width: 767px) {
					font-size: 1.75rem;
					margin-right: 2px;
				}
			}

			.ss-aw-recommend__price--taxin & {
				font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
				font-size: 1.8rem;
				letter-spacing: 0.025em;
				margin-right: 5px;
				@media all and (max-width: 767px) {
					font-size: 1.35rem;
					margin-right: 2px;
				}
			}
		}
	}

	&__actions {
		margin-top: 5px;
		-ms-flex-positive: 1;
		flex-grow: 1;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;

		&-item {
			font-size: 1.4rem;
			position: relative;
			top: auto;
			right: auto;
		}
	}
}

.ss-aw-ranking {
	#main & {
		margin-top: 60px;
	}

	&__price {
		padding-right: 0;

		&--base,
		&--taxin {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: baseline;
			align-items: baseline;
		}

		&-text {
			font-size: 1.2rem;
			margin-right: 3px;
			white-space: nowrap;
			@media all and (max-width: 767px) {
				font-size: 1.1rem;
				margin-right: 2px;
			}
		}

		&-number {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			letter-spacing: 0.025em;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			.ss-aw-ranking__price--base & {
				font-size: 2.0rem;
				font-weight: bold;
				@media all and (max-width: 767px) {
					font-size: 1.75rem;
				}
			}

			.ss-aw-ranking__price--taxin & {
				font-size: 1.6rem;
				@media all and (max-width: 767px) {
					font-size: 1.4rem;
				}
			}
		}

		&-unit {
			margin-left: 3px;
			font-size: 1.2rem;
			@media all and (max-width: 767px) {
				font-size: 1.1rem;
				margin-left: 2px;
			}
		}
	}
}

.ss-checked-items {
	@media all and (max-width: 767px) {
		margin-top: 30px;
		margin-bottom: 35px;
	}

	&__price {
		padding-right: 0;

		&--base,
		&--taxin {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: baseline;
			align-items: baseline;
		}

		&-text {
			font-size: 1.2rem;
			margin-right: 3px;
			white-space: nowrap;
			@media all and (max-width: 767px) {
				font-size: 1.0rem;
				margin-right: 2px;
			}
		}

		&-number {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			letter-spacing: 0.025em;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			.ss-checked-items__price--base & {
				font-size: 2.0rem;
				font-weight: bold;
				@media all and (max-width: 767px) {
					font-size: 1.4rem;
				}
			}

			.ss-checked-items__price--taxin & {
				font-size: 1.6rem;
				@media all and (max-width: 767px) {
					font-size: 1.2rem;
				}
			}
		}

		&-unit {
			margin-left: 3px;
			font-size: 1.2rem;
			@media all and (max-width: 767px) {
				font-size: 1.0rem;
				margin-left: 2px;
			}
		}
	}
}

.ss-news-facebook {
	#main & {
		margin-top: 80px;
		margin-bottom: 110px;
		@media all and (max-width: 767px) {
			margin-top: 50px;
			margin-bottom: 90px;
		}
	}

	&__item {
		@media all and (max-width: 767px) {
			width: 100%;
			max-width: 500px;
			margin: auto;
		}

		#main .ss-news-facebook & {
			height: auto;
		}
	}

	& .fb-page {
		@media all and (max-width: 767px) {
			width: 100%;
		}
	}

	& .fb-page > span,
	& iframe {
		@media all and (max-width: 767px) {
			width: 100% !important;
		}
	}
}

.ss-news {
	&__container {
		position: relative;
		border: 1px solid #A8A8A7;
		padding: 1px;
	}

	&__scroll {
		position: relative;
		height: 326px;

		&-contents {
			padding: 30px 55px 15px 40px;
			@media all and (max-width: 767px) {
				padding: 25px 30px 15px 15px;
			}

			dt {
				margin-top: 5px;
				font-size: 1.2rem;
				float: left;
				@media all and (max-width: 767px) {
					margin-top: 5px;
					font-size: 1.1rem;
				}
			}

			dd {
				padding: 0 0 20px 7em;
				font-size: 1.3rem;
				line-height: 1.89;
				@media all and (max-width: 767px) {
					padding: 0 0 19px 6.2em;
					font-size: 1.25rem;
					line-height: 1.84;
				}
			}

			a {
				text-decoration: underline;
				display: inline-block;
			}
		}

		.ps__rail-x,
		.ps__rail-y {
			background: #fff!important;
			opacity: 1.0!important;
		}
	}
}

.ss-column {
	&__list {
		&-item {
			#main .ss-column & {
				@media all and (max-width: 767px) {
					float: none;
					width: auto;
				}
			}
		}
	}
}

.ss-fixed-tel {
	display: none;
	@media all and (max-width: 767px) {
		display: block;
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 200;
		transition: transform 250ms ease-out;
		transform: translateY(100%);

		&.is-show {
			transform: translateY(0%);
		}
	}

	&__link {
		background: #8cc220;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 9px;
	}

	&__icon {
		width: 33px;
		margin-right: 10px;
	}

	&__text {
		font-size: 2.0rem;
		line-height: 1.2;
		color: #fff;
	}
}

.ss-footer {
	@media all and (max-width: 767px) {
		position: relative;
	}

	&__page-top {
		#gFooter.ss-footer & {
			@media all and (max-width: 767px) {
				position: absolute;
				bottom: auto;
				top: -22px;
			}
		}
	}
}

.ss-product-list {
	&__icon {
		#main .comPhotoUl li .txtBox & {
			margin-bottom: 7px;
			@media all and (max-width: 767px) {
				margin-bottom: 4px;
			}
		}
	}

	&__catch {
		color: #ff8a06;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: 1.475;
		@media all and (max-width: 767px) {
			font-size: 1.25rem;
		}
	}

	&__price {
		margin-bottom: 10px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: baseline;
		align-items: baseline;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		@media all and (max-width: 767px) {
			-ms-flex-direction: column;
			flex-direction: column;
			margin-bottom: 8px;
			line-height: 1.3;
		}

		&--base,
		&--taxin {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: baseline;
			align-items: baseline;
			@media all and (max-width: 767px) {
				width: 100%;
			}
		}

		&--separator {
			margin: 0 5px;
			@media all and (max-width: 767px) {
				display: none;
			}
		}

		&-text {
			font-size: 1.6rem;
			margin-right: 6px;
			white-space: nowrap;
			@media all and (max-width: 767px) {
				font-size: 1.1rem;
				margin-right: 4px;
			}
		}

		&-number {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			letter-spacing: 0.025em;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			.ss-product-list__price--base & {
				font-size: 2.6rem;
				font-weight: bold;
				@media all and (max-width: 767px) {
					font-size: 1.9rem;
				}
			}

			.ss-product-list__price--taxin & {
				font-size: 2.2rem;
				@media all and (max-width: 767px) {
					font-size: 1.6rem;
				}
			}
		}

		&-unit {
			margin-left: 3px;
			font-size: 1.6rem;
			@media all and (max-width: 767px) {
				font-size: 1.1rem;
				margin-left: 2px;
			}
		}
	}
}

.ss-product-detail {
	&__price {
		margin-bottom: 20px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: baseline;
		align-items: baseline;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		@media all and (max-width: 767px) {
			-ms-flex-pack: end;
			justify-content: flex-end;
		}

		&--base,
		&--taxin {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: baseline;
			align-items: baseline;
		}

		&--separator {
			margin: 0 5px;
		}

		&-text {
			font-size: 1.6rem;
			margin-right: 6px;
			white-space: nowrap;
			@media all and (max-width: 767px) {
				font-size: 1.4rem;
				margin-right: 4px;
			}
		}

		&-number {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			letter-spacing: 0.025em;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			.ss-product-detail__price--base & {
				font-size: 3.0rem;
				font-weight: bold;
				@media all and (max-width: 767px) {
					font-size: 2.25rem;
				}
			}

			.ss-product-detail__price--taxin & {
				font-size: 2.35rem;
				@media all and (max-width: 767px) {
					font-size: 2.0rem;
				}
			}
		}

		&-unit {
			margin-left: 3px;
			font-size: 1.6rem;
			@media all and (max-width: 767px) {
				font-size: 1.4rem;
				margin-left: 2px;
			}
		}
	}
}

.ss-related {
	&__price {
		padding-right: 0;

		&--base,
		&--taxin {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: baseline;
			align-items: baseline;
		}

		&-text {
			font-size: 1.2rem;
			margin-right: 3px;
			white-space: nowrap;
			@media all and (max-width: 767px) {
				font-size: 1.1rem;
				margin-right: 2px;
			}
		}

		&-number {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			letter-spacing: 0.025em;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			.ss-related__price--base & {
				font-size: 2.0rem;
				font-weight: bold;
				@media all and (max-width: 767px) {
					font-size: 1.4rem;
				}
			}

			.ss-related__price--taxin & {
				font-size: 1.6rem;
				@media all and (max-width: 767px) {
					font-size: 1.2rem;
				}
			}
		}

		&-unit {
			margin-left: 3px;
			font-size: 1.2rem;
			@media all and (max-width: 767px) {
				font-size: 1.1rem;
				margin-left: 2px;
			}
		}
	}
}

.ss-related-categories {
	@media all and (max-width: 767px) {
		margin-left: 0;
		margin-right: 0;
	}

	& &__title {
		margin-bottom: 15px;
	}

	&__list {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -7px;

		&-item {
			margin: 7px;
		}
	}

	&__link {
		display: block;
		background: #eee3d6;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		padding: 5px 10px 5px 15px;
		border-radius: 4px;

		&-text {
			font-weight: bold;
		}

		&:after {
			content: ">";
			display: block;
			margin-left: 15px;
			font-weight: bold;
		}
	}
}
//201912修正
@media all and (max-width: 767px) {

	#container {
		padding-top: 52px;
	}

	#gHeader {
		&.ss-header {
			padding-top: 11px;
		}

		.hBox {
			.ss-header__logo {
				min-height: 41px;
			}
		}

		.hList {
			width: auto;

			.ss-header__button {
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				width: auto;
				padding: 6px 10px;
				min-height: 52px;

				&-image {
					&-pic {}
				}

				&-text {
					margin-right: 5px;
				}
			}
		}
	}

	.ss-header__logo {
		&-link {
			display: block;
			width: 85px;
		}
	}
}

.ss-product-detail {

	&__color {
		display: flex;
		align-items: center;
		margin: 15px 0;

		&-label {
			display: flex;
			align-items: center;
			padding-right: 1em;

			&:after {
				content: "：";
				width: 0.5em;
			}
		}

		&-detail {
			flex-grow: 1;
		}

		&-table {
			width: 100%;
			border-collapse: collapse;

			&-tr {
				text-align: center;
			}

			&-th {
				font-size: 0.875em;
				padding: 5px;
				border: #A2A2A2 solid 1px;
			}

			&-td {
				padding: 5px;
				border: #A2A2A2 solid 1px;
			}
		}

		&-circle {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			margin: 5px auto;
		}
	}

	&__modal {
		&-contents {
			display: none;
		}
		.modaal-close {
			position: absolute;
			background: #8e8e8e;
			@media all and (max-width: 767px) {
				top: -20px;
				right: -20px;
			}
			&:hover {
				&:after,
				&:before {
					background: #fff;
				}
			}
		}

		@media all and (max-width: 767px) {
			.modaal-content-container {
				padding: 0 25px 25px;
			}
		}

		h3 {
			margin-bottom: 27px;
			padding: 18px 0 19px;
			font-size: 2.8rem;
			text-align: center;
			background-color: #F4EFEB;
		}

		h3 img {
			margin: -5px 25px 0 0;
			width: 47px;
			vertical-align: middle;
		}

		.text {
			margin-bottom: 45px;
			font-size: 1.6rem;
			text-align: center;
			line-height: 1.9;
		}

		.title {
			margin-bottom: 32px;
			font-size: 1.6rem;
			text-align: center;
			line-height: 1.75;
			letter-spacing: -0.5px;
		}

		.title .bg {
			margin-bottom: 16px;
			padding: 4px 8px;
			display: inline-block;
			font-size: 1.6rem;
			line-height: 1.5;
			background: #F9F4F0;
		}

		.title .line {
			margin-bottom: 16px;
			padding-bottom: 9px;
			display: inline-block;
			font-size: 2.2rem;
			font-weight: bold;
			line-height: 1.5;
			border-bottom: 1px solid #F9F4F0;
		}

		.txt {
			margin-top: 5px;
			display: block;
			font-size: 1.4rem;
		}

		.txtList {
			margin: 0 auto 28px;
			width: 552px;
		}

		.txtList li {
			padding: 9px 0;
		}

		.txtList li dl {
			display: table;
		}

		.txtList dt {
			width: 20%;
			display: table-cell;
			vertical-align: middle;
			font-weight: bold;
		}

		.txtList dt span {
			padding: 2px 0;
			min-width: 120px;
			display: inline-block;
			font-size: 1.8rem;
			text-align: center;
			background: #F0D0C9;
			border-radius: 3px;
		}

		.txtList dd {
			padding-left: 12px;
			font-size: 1.6rem;
			display: table-cell;
			vertical-align: middle;
			letter-spacing: -0.5px;
		}

		.photo1 {
			margin: 0 0 68px 5px;
			text-align: center;
		}

		.photo1 img {
			width: 457px;
		}

		.sizeUl {
			margin-bottom: 21px;
		}

		.sizeUl li {
			width: 46.7%;
			float: left;
		}

		.sizeUl li:nth-child(2) {
			float: right;
		}

		.sizeUl li p {
			margin: 25px 0 9px;
			font-size: 1.6rem;
		}

		.sizeUl li .bg {
			margin: 0 0 -3px;
			padding: 5px 0 6px;
			font-size: 2rem;
			text-align: center;
			background-color: #F8F3EE;
		}

		.sizeUl li .bg img {
			margin: -3px 6px 0 0;
			width: 26px;
			vertical-align: middle;
		}

		.sizeUl li table {
			width: 100%;
			border-collapse: collapse;
		}

		.sizeUl li th {
			font-size: 1.6rem;
			background-color: #E5E5E5;
		}

		.sizeUl li td,
		.sizeUl li th {
			padding: 9px 0;
			text-align: center;
			vertical-align: middle;
			border: 1px solid #808080;
			border-left: none;
		}

		.sizeUl li td {
			padding: 10px 0;
		}

		.sizeUl li td:last-child,
		.sizeUl li th:last-child {
			border-right: none;
		}

		.sizeUl li .wid01 {
			width: 24%;
		}

		.sizeUl li .wid02 {
			width: 43.5%;
		}

		.sizeUl li .wid03 {
			width: 32.5%;
		}

		.sizeUl li .wid04 {
			width: 28%;
		}

		.sizeUl li .wid05 {
			width: 24%;
		}

		.note {
			margin-bottom: 72px;
			font-size: 1.6rem;
		}

		.photoUl {
			margin: 42px -20px 69px;
			text-align: center;
			overflow: hidden;
		}

		.photoUl li {
			width: 179px;
			display: inline-block;
			vertical-align: top;
		}

		.photoUl li:nth-child(2) {
			margin: 0 65px 0 57px;
			width: 261px;
		}

		.photoUl li span {
			margin-bottom: 16px;
			padding: 1px 0 2px;
			min-width: 120px;
			display: inline-block;
			font-size: 1.8rem;
			text-align: center;
			border-radius: 3px;
			background-color: #EBCCC5;
		}

		.borderBox {
			margin: 0 auto 23px;
			padding: 19px 0 28px;
			width: 602px;
			text-align: center;
			box-sizing: border-box;
			border: 2px solid #000;
			border-radius: 3px;
		}

		.borderBox .ttl {
			margin-bottom: 18px;
			font-size: 2rem;
			font-weight: bold;
		}

		.borderBox p img {
			margin: -3px 25px 0 0;
			width: 228px;
			vertical-align: middle;
		}

		.txtUl {
			text-align: center;
		}

		.txtUl li {
			padding-left: 1em;
			text-indent: -1em;
			display: inline-block;
			text-align: left;
			line-height: 1.65;
			letter-spacing: -0.5px;
		}
		@media all and (max-width: 767px) {
			#pagePath {
				margin-bottom: 32px;
			}

			h3 {
				margin: -3px -25px 19px;
				padding: 18px 0 15px;
				font-size: 1.73rem;
				line-height: 1.3;
			}

			h3 img {
				margin: -2px 12px 0 0;
				width: 32px;
			}

			.text {
				margin: 0 5px 34px;
				font-size: 1.4rem;
				line-height: 1.65;
			}

			.title {
				margin-bottom: 19px;
				font-size: 1.4rem;
				text-align: left;
				line-height: 1.8;
			}

			.title .bg {
				margin: 0 auto -12px;
				padding: 1px 0;
				max-width: 145px;
				display: block;
				font-size: 1.4rem;
				line-height: 1.5;
				text-align: center;
			}

			.title .line {
				margin-bottom: -17px;
				padding-bottom: 9px;
				display: block;
				font-size: 1.6rem;
				line-height: 1.5;
				text-align: center;
			}

			.txt {
				margin-top: 0;
			}

			.txtList {
				margin: 0 14px 25px;
				width: auto;
			}

			.txtList dt span {
				padding: 1px 0;
				min-width: 100px;
				display: inline-block;
				font-size: 1.4rem;
			}

			.txtList dd {
				padding-left: 8px;
				font-size: 1.3rem;
				line-height: 1.4;
			}

			.photo1 {
				margin: 0 0 46px;
			}

			.photo1 img {
				width: 97%;
			}

			.sizeUl {
				margin: 0 25px 16px;
			}

			.sizeUl li {
				width: auto;
				float: none !important;
			}

			.sizeUl li:not(:last-child) {
				margin-bottom: 40px;
			}

			.sizeUl li p {
				margin: 15px 0 6px;
				font-size: 1.3rem;
			}

			.sizeUl li .bg {
				padding: 5px 0 4px;
				font-size: 1.5rem;
				text-align: center;
				background-color: #F8F3EE;
			}

			.sizeUl li .bg img {
				margin: -3px 4px 0 0;
				width: 21px;
				vertical-align: middle;
			}

			.sizeUl li table {
				width: 100%;
				border-collapse: collapse;
			}

			.sizeUl li th {
				font-size: 1.4rem;
				background-color: #E5E5E5;
			}

			.sizeUl li td,
			.sizeUl li th {
				padding: 6px 0;
				text-align: center;
				vertical-align: middle;
				border: 1px solid #808080;
				border-left: none;
			}

			.sizeUl li td {
				padding: 8px 0 7px;
			}

			.sizeUl li td:last-child,
			.sizeUl li th:last-child {
				border-right: none;
			}

			.note {
				margin: 0 0 54px 27px;
				padding-left: 1em;
				text-indent: -1em;
				font-size: 1.4rem;
				letter-spacing: -0.5px;
			}

			.photoUl {
				margin: 31px 28px 50px;
				text-align: center;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				overflow: inherit;
			}

			.photoUl li {
				width: 47%;
			}

			.photoUl li:nth-child(1) {
				order: 1;
			}

			.photoUl li:nth-child(3) {
				order: 2;
			}

			.photoUl li:nth-child(2) {
				margin: -26px 0 0 -10%;
				width: 67%;
				order: 3;
			}

			.photoUl li span {
				margin-bottom: 10px;
				padding: 0;
				min-width: 80px;
				display: inline-block;
				font-size: 1.2rem;
				text-align: center;
				border-radius: 3px;
				background-color: #EBCCC5;
			}

			.borderBox {
				margin: 0 17px 13px;
				padding: 9px 0 8px;
				width: auto;
				border-width: 1px;
			}

			.borderBox .ttl {
				margin-bottom: 8px;
				font-size: 1.3rem;
			}

			.borderBox p img {
				margin: 0 auto 6px;
				width: 182px;
				display: block;
			}

			.txtUl {
				margin: 0 17px;
				text-align: left;
			}

			.txtUl li {
				padding-left: 0;
				text-indent: 0;
				display: block;
				line-height: 1.7;
			}
		}
		@media all and (-ms-high-contrast:none) {
			h3 {
				padding: 21px 0 16px;
			}

			.title .bg {
				padding: 6px 8px 2px;
			}

			.txtList dt span {
				padding: 4px 0 0;
			}

			.sizeUl li .bg {
				padding: 7px 0 4px;
			}

			.sizeUl li td,
			.sizeUl li th {
				padding: 11px 0 7px;
			}

			.photoUl li span {
				padding: 3px 0 0;
			}
		}

		h3 {
			margin-bottom: 31px;
			padding: 18px 0 19px;
			font-size: 2.8rem;
			text-align: center;
			background-color: #F4EFEB;
		}

		h3 img {
			margin: -5px 25px 0 0;
			width: 47px;
			vertical-align: middle;
		}

		.text {
			margin-bottom: 28px;
			font-size: 1.8rem;
			text-align: center;
			line-height: 1.8;
		}

		.material {
			overflow: hidden;
			margin: 0 auto;
			width: 750px;
		}

		.material .inner {
			margin: 42px 0 -1px;
			padding: 0 0 43px 180px;
			position: relative;
			background: url("../img/kiji/line01.jpg") repeat-x left bottom;
			background-size: 5px 1px;
		}

		.material .inner .lBox {
			float: left;
		}

		.material .inner .lBox > img {
			width: 150px;
			position: absolute;
			left: 0;
			top: 7px;
		}

		.material .inner .lBox p {
			margin-bottom: 11px;
			font-size: 2.6rem;
			font-weight: bold;
		}

		.material .inner .lBox ul {
			margin-bottom: 20px;
		}

		.material .inner .lBox li {
			margin-right: 2px;
			padding-top: 6px;
			width: 36px;
			height: 36px;
			display: inline-block;
			box-sizing: border-box;
			font-size: 1.7rem;
			font-weight: bold;
			color: #fff;
			text-align: center;
			border-radius: 100%;
			background: #8FC320;
		}

		.material .inner .lBox li.gray {
			background-color: #909090;
		}

		.material .inner .lBox dl {
			font-size: 1.6rem;
		}

		.material .inner .lBox dt {
			margin-bottom: 3px;
			color: #7FBD26;
		}

		.material .inner .lBox dd {
			line-height: 1.6;
		}

		.material .inner .rBox {
			padding: 7px 5px 0 0;
			float: right;
		}

		.material .inner .rBox img {
			width: 237px;
		}

		.material .inner > p {
			padding-top: 23px;
			clear: both;
			font-size: 1.6rem;
			line-height: 1.8;
			letter-spacing: -0.5px;
		}

		.material .inner .txt {
			padding-top: 17px;
			color: #6D6D6D;
			font-size: 1.4rem;
		}

		.material .inner ul {
			padding-top: 17px;
		}

		.material .inner > ul > li {
			margin: 4px 36px 4px 0;
			display: inline-block;
		}

		.material .inner > ul > li a {
			color: #6D6D6D;
			text-decoration: underline;
		}

		.material .inner > ul > li a:hover {
			text-decoration: none;
		}
		@media all and (max-width: 767px) {
			#pagePath {
				margin-bottom: 32px;
			}

			h3 {
				margin: -3px -25px 19px;
				padding: 18px 0 15px;
				font-size: 1.73rem;
				line-height: 1.3;
			}

			h3 img {
				margin: -2px 12px 0 0;
				width: 32px;
			}

			.text {
				margin: 0 20px 7px;
				font-size: 1.4rem;
				line-height: 1.65;
			}

			.material {
				width: auto;
			}

			.material .inner {
				margin: 32px 0 -1px;
				padding: 0 0 26px;
				position: relative;
				background: url("../img/kiji/line01.jpg") repeat-x left bottom;
				background-size: 2px 1px;
			}

			.material .inner .lBox {
				float: none;
				overflow: hidden;
				position: relative;
				z-index: 1;
			}

			.material .inner .lBox > img {
				padding-top: 4px;
				width: 28%;
				position: static;
				float: left;
			}

			.material .inner .lBox > div {
				width: 67.5%;
				float: right;
			}

			.material .inner .lBox p {
				margin-bottom: 11px;
				font-size: 1.7rem;
			}

			.material .inner .lBox ul {
				margin-bottom: 13px;
			}

			.material .inner .lBox li {
				margin-right: 1px;
				padding-top: 3px;
				width: 25px;
				height: 25px;
				font-size: 1.1rem;
				border-radius: 100%;
			}

			.material .inner .lBox dl {
				font-size: 1.4rem;
			}

			.material .inner .lBox dt {
				margin-bottom: 3px;
			}

			.material .inner .lBox dd {
				line-height: 1.6;
			}

			.material .inner .rBox {
				margin-top: 21px;
				padding: 0 5px 0 0;
				float: none;
				text-align: center;
			}

			.material .inner .rBox img {
				width: 60%;
			}

			.material .inner > p {
				padding: 23px 0 2px;
				font-size: 1.4rem;
				letter-spacing: -0.5px;
				line-height: 1.85;
			}

			.material .inner .txt {
				font-size: 1.3rem;
			}

			.material .inner > ul > li {
				margin: 5px 0;
				display: block;
				font-size: 1.3rem;
			}

			.material .inner > ul > li a:hover {
				text-decoration: underline;
			}
		}
		@media all and (-ms-high-contrast:none) {
			h3 {
				padding: 21px 0 16px;
			}

			.material .inner .lBox li {
				padding-top: 8px;
			}
		}
	}

	&__cv {
		margin-top:-116px;
		padding-top:116px;
		@media all and (max-width: 767px) {
			margin-top:-67px;
			padding-top:67px;
		}
		&-actions {
			margin: 40px 0;
			@media all and (max-width: 767px) {
				margin: 40px 25px;
			}
			&-item {
				margin: 0 auto;
				width: 100%;
				max-width: 650px;
			}
		}
		&-link {
			padding: 7px 80px;
		    display: block;
		    font-size: 2.4rem;
		    font-weight: bold;
		    font-family: 'Hiragino Kaku Gothic ProN';
		    text-align: center;
		    border: 4px solid #FF8B07;
			background: url("../../img/accordion/icon02.png") no-repeat right 62px center;
		    background-size: 18px 10px;
			@media all and (max-width: 767px) {
				padding: 18px 40px 17px 25px;
		        font-size: 1.6rem;
		        border: 3px solid #FF8B07;
		        background-size: 11px auto;
				background-position: right 20px center;
			}
		}
	}

}
#js-product-detail__oyasumi-mask--sp {
	@media all and (max-width: 767px) {
		padding-top: 15px;
	    margin-top: -15px;
	}
}

//202001修正
.ss-form {
	&__title {
		display: flex;
		&-required {
			flex-shrink: 0;
			align-self: flex-start;
			#main .mailForm .mailTable th & {
				line-height: 28px;
				margin: 0 10px 0 0;
			}
			#main form .tableType.is-present th & {
				padding: 0 11px 1px;
				float: right;
				color: #fff;
				font-size: 1.2rem;
				font-weight: normal;
				background-color: #E50012;
				line-height: 28px;
				margin: 0 10px 0 0;
			}
		}
		&-text {
			margin-right: 5px;
			line-height: 2;
		}
	}
	&__list {
		@media all and (max-width: 767px) {
			display: flex;
			align-items: center;
			margin: 0 -5px;
		}
		&-item {
			@media all and (max-width: 767px) {
				#main .mailForm .mailTable .nameUl & {
					display: flex;
					margin: 0 5px;
					&:first-child {
						margin: 0 5px;
					}
				}
				span {
					margin-right: 5px;
					flex-shrink: 0;
				}
				#main .mailForm .mailTable .nameUl & {
					span {
						width: auto;
						min-width: 44px;
					}
				}
			}
		}
	}
	&__zip {
		@media all and (max-width: 767px) {
			display: flex;
			align-items: center;
		}
	}
	&__birth {
		@media all and (max-width: 767px) {
			display: flex;
			align-items: center;
			#main .mailForm .mailTable td & {
				.ss-form__birth-day {
					margin-right: 0;
				}
			}
			#main .mailForm .mailTable td & {
				.wid03 {
					margin: 0;
				}
			}
		}
	}
	&__actions {
		@media all and (max-width: 767px) {
			#main & {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				justify-content: center;
			}
			#main .step1_login & {
				margin: 0 -5px;
			}
			&-item {
				#main .step1_login .comSubmit & {
					width: auto;
					margin: 0 5px;
					flex-grow: 1;
				}
			}
		}
	}
	&__submit {
		&-actions {
			@media all and (max-width: 767px) {
				#main .step1_unregistered &,
				#main .step3 & {
					margin-left: 14px;
					margin-right: 14px;
				}
				#main .step2 & {
					margin-left: -8px;
					margin-right: -8px;
				}
			}
			#main & {
				@media all and (max-width: 767px) {
					display: flex;
				}
			}
			#main .step1_unregistered & {
				@media all and (max-width: 767px) {
					margin-top: 40px;
				}
			}
			&-item {
				#main .step1_unregistered .comSubmit &,
				#main .step2 .comSubmit &,
				#main .step3 .comSubmit & {
					@media all and (max-width: 767px) {
						margin: 0 8px;
						width: auto;
						flex-grow: 1;
					}
				}
			}
		}
		&-button {
			#main .comSubmit li & {
				font-size: 1em;
				line-height: 1.5;
				padding: 10px;
				@media all and (max-width: 767px) {
					padding: 15px 5px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 100% !important;
					min-width: 0;
				}
			}
		}
	}

	&-error {
		display: flex;
		margin: 30px 0;
		&__text {
			font-size: 1.6rem;
			@media all and (max-width: 767px) {
				font-size: 2.0rem;
			}
			.attention {
				font-size: 1.6rem;
				display: inline-block;
			}
		}
	}
}
.ss-entry {
	@media all and (max-width: 767px) {
		&__actions {
			#main & {
				flex-wrap: nowrap;
				margin-left: -8px;
				margin-right: -8px;
			}
			&-item {
				#main .member2 .comSubmit &,
				#main .member3 .comSubmit & {
					flex-grow: 1;
					margin: 0 8px;
					width: auto;
					&.return {
						width: auto;
						margin: 0 8px;
					}
				}
			}
		}
	}
	&__text {
		#main .member2 & {
			color: inherit;
		}
	}
}
.ss-cart {
	@media all and (max-width: 767px) {
		&__layout {
			display: flex;
			align-items: center;
			margin: 0 -10px;
			&-item {
				margin: 0 10px;
			}
		}
	}
}
.ss-shopping-login {
	&__title {
		@media all and (max-width: 767px) {
			#main & {
				margin-left: -18px;
				margin-right: -18px;
			}
		}
	}
	&__box {
		margin-top: 10px;
	}
	&__contents-title {
		display: flex;
		align-items: center;
		&-icon {
			width: 20px;
			margin-right: 5px;
		}
		&-text {
			flex-grow: 1;
		}

	}
}
.ss-shopping-deliv {
	&__address {
		display: flex;
		flex-direction: column;
		margin-bottom: 21px;
		@media (max-width: 767px) {
			margin: 0 25px 15px;
		}

		&-label {
			position: relative;
		}
		&-radio {
			#main .step1_unregistered & {
				&[type="radio"] {
					display: block;
				}
			}
			position: absolute;
			top: 25px;
			left: 20px;
			margin: 0;
		}
		&-detail {
			flex-grow: 1;
			padding: 20px 20px 20px 53px;
			border: 1px solid #C5C5C4;
			.ss-shopping-deliv__address-radio:checked + & {
				background: #ffefef;
			}
		}
		&-title {
			font-weight: bold;
			#main .step1_unregistered form & {
				margin: 0;
				@media (max-width: 767px) {
					text-align: left;
				}
			}
		}
		&-text {
			#main .step1_unregistered form & {
				margin: 0;
				@media (max-width: 767px) {
					text-align: left;
				}
			}
		}
		&-actions {
			display: flex;
			justify-content: flex-end;
			margin: 10px -10px 0;
			&-item {
				margin: 0 10px;
				a {
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	&__notes {
		#main .step1_unregistered & {
			font-size: 16px;
			line-height: 1.84;
		}
	}
	&__error {
		display: flex;
		margin: 30px 0;
		&__text {
			font-size: 1.6rem;
			@media all and (max-width: 767px) {
				font-size: 2.0rem;
			}
		}
		&-text {
			.attention {
				@media all and (max-width: 767px) {
					font-size: 1.6rem;
				}
			}
		}
	}
}
.ss-shopping-payment {
	&__method-detail {
		@media (max-width: 767px) {
			display: none;
			padding: 5px 0;
		}
		&.is-open {
			@media (max-width: 767px) {
				display: block;
			}
		}
	}
	&__title {
		display: flex;
		#main .step2 .sec01 &,
		#main .step2 .sec03 &,
		#main .step2 .sec04 & {
			margin-bottom: 16px;
		}

		&.has-border {
			padding-bottom: 5px;
			border-bottom: solid 1px #C5C5C4;
		}

		&-mark {
			font-size: 13px;
			padding: 7px;
			line-height: 1.2;
			margin-right: 10px;
			flex-shrink: 0;
			align-self: flex-start;
			@media (max-width: 767px) {
				font-size: 10px;
				padding: 5px;
			}
			&.is-required {
				color: #fff;
				background: #E50012;
			}
			&.is-optional {
				color: #555;
				background: #dcdcdc;
			}
		}
	}

	&__check-list {
		display: flex;
		flex-wrap: wrap;
		#main form td & {
			margin-left: -5px;
			margin-right: -5px;
		}
		&-item {
			#main .step2 .sec05 ul & {
				width: 50%;
				box-sizing: border-box;
				padding: 0 5px;
				margin: 10px 0;
				&:not(:last-child) {
					margin: 10px 0;
				}
			}
		}
	}

	&__coupon {
		display: flex;
		margin-bottom: 35px;
		&-parts {
			display: flex;
		}
	}
	&__modal {
		display: flex;
		&-trigger {
			background: #ff8b06;
			padding: 0 10px;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			&.is-disabled {
				pointer-events: none;
			}
			&:visited {
				color: #fff;
			}
			&-text {
				color: #fff;
				white-space: nowrap;
			}
		}
		&-actions {
			display: flex;
			justify-content: center;
			margin-top: 20px;
			&-item {}
		}
		&-button {
			display: block;
			text-align: center;
			background: #fff;
			padding: 10px 20px;
			border: solid 1px #C5C5C4;
			font-weight: bold;
			font-size: 1.4rem;
			cursor: pointer;
		}
	}
	&__modaal-wrapper {
		textarea[name='offer'] {
			width: 100%;
			min-height: 200px;
			border: none;
			padding: 0;
			font-size: 1.2em;
			overflow-y: scroll;
			transform: translateZ(0);
		}
		.modaal-content-container {
			padding: 50px 30px 20px;
			@media all and (max-width: 767px) {
				padding: 50px 15px 20px;
			}
		}
		.modaal-close {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			&:before,
			&:after {
				background: #C5C5C4;
			}
		}
	}

	&__check {
		&-label {
			font-weight: bold;
		}
		&-notes {
			margin-top: 10px;
		}
	}
}
.ss-shopping-complete {
	padding: 0 25px;
	&__title {
		color: #ff8b06;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1.3;
		&:before,
		&:after {
			content: '';
			width: 2px;
			height: 30px;
			background: #ff8b06;
			margin: 0 10px;
		}
		&:before {
			transform: rotate(-30deg);
		}
		&:after {
			transform: rotate(30deg);
		}
	}
	&__contents {
		margin: 30px 0;
	}
	&__image {
		width: 100%;
		max-width: 750px;
		margin: 20px auto;
	}
	&__message {
		text-align: center;
		&-label {
			font-size: 20px;
			font-weight: bold;
		}
		&-detail {
			margin-top: 10px;
		}
		&-text {
			display: flex;
			flex-direction: column;
			&--main {
				font-size: 16px;
			}
			&--sub {
				font-size: 16px;
				margin-top: 5px;
			}
		}
	}
}
.ss-present {
	&__check-list {
		display: flex;
		flex-wrap: wrap;
		#main form td & {
			margin-left: -5px;
			margin-right: -5px;
		}
		&-item {
			width: 50%;
			box-sizing: border-box;
			padding: 0 5px;
		}
	}
}
.ss-nonmember {
	&__table {
		tr {
			&.is-close {
				display: none;
			}
		}
	}
}
.attention {
	color: #E20012;
}


#gFooter .fBox .fLink {
    margin-bottom: 15px;
}
#gFooter .fBox .fLogo {
	padding-top: 20px;
}
@media all and (max-width: 767px) {
	#gFooter .fBox .fLink {
		margin-bottom: 16px;
	}
	#main .comLoginBox .rBox .title {
		margin-bottom: 5px;
		font-size: 1.75rem;
	}
	#main .cart .ttl {
		font-size: 16px;
		line-height: 1.84;
	}
	#main .step1_login .txt {
		font-size: 16px;
		text-align: center;
		span {
			//font-size: 14px;
		}
	}
	#main .step1_login .btmTxt {
		font-size: 16/12 + em;
	}
	#pagePath {
		margin: 11px 15px;
	}
	#main .member2 .formImg,
	#main .member3 .formImg,
	#main .member4 .formImg {
		margin: 0 -8px;
	}
	#main .step1_unregistered form .addTxt {
		text-align: center;
		a {
			display: inline-block;
		}
	}
	#main .step2 textarea {
		height: 100px;
	}
	#main .step2 .sec03 input {
		width: auto;
	}
	#main .step2 .tableBox td p {
		font-size: 1.4rem;
	}
	#main .step2 .sec04 .note {
		color: #000;
	}
	#main .step3 .txt {
		font-size: 16px;
		line-height: 1.84;
	}
	#main .step3 .topBox ul .num {
		width: 120px;
	}
	#main .cart .thStyle01 input[type="checkbox"] + label {
		font-weight: normal;
		font-size: 1.55rem;
	}
	.attention {
		font-size: 20px;
	}
}

.ss-amazon-pay {
	&__cart-button {
		#main .comSubmit & {
			margin: 7px 20px;
			vertical-align: top;
		}
		#main .step1_login .comSubmit & {
			@media (max-width: 767px) {
				margin-top: 14px;
			}
		}
	}
	&__block {
		#main & {
			&.step1_login {
				margin: 0;
				table {
					margin: 0;
				}
			}
			&.step3 {
				margin: 0 0 40px;
				.topBox {
					@media (max-width: 767px) {
						margin: 0;
					}
					.shopBox {
						@media (max-width: 767px) {
							margin: 0;
						}
					}
				}
				.inner {
					margin: 0;
				}
			}
		}
	}
	#amazon_info {
		#main & {
			.sec05 {
				@media (max-width: 767px) {
					margin: 0 0 40px;
				}
				h3 {
					margin: 0 0 12px;
					@media (max-width: 767px) {
						margin: 0 0 12px;
					}
				}
			}
		}
	}
	.widget-container {
		width: 100% !important;
		iframe {
			width: 100%;
		}
	}
}

//KAMO_RENEW3-166 KMS-134 【フォーム改善2】申し込みフォームの改善
.ss-form {
	&__zip {
		display: flex;
		align-items: center;
		&-button {
			cursor: pointer;
		}
	}
	&__notes {
		margin: 10px 0 0;
		&-link {
			&:link {
				text-decoration: underline;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.ss-entry {
	&__personal-info {
		&-title {
			#main .ss-entry__personal-info & {
				margin: 44px 0 10px;
				@media all and (max-width: 767px) {
					margin: 23px 0 7px;
				}
			}
		}
	}
}
.ss-credit {
	&__info {
		text-align: center;
		&-title {
			color: #E20012;
			font-size: 18px;
			font-weight: bold;
		}
		&-text {
			font-size: 15px;
			margin-top: 5px;
		}
		&-card {
			margin: 10px 0 20px;
			text-align: center;
			&-label {
				font-size: 15px;
			}
			&-detail {
				width: 100%;
				max-width: 400px;
				margin: auto;
			}
		}
	}
	&__example {
		font-size: 90%;
		margin-top: 5px;
		&-label {}
		&-detail {}
		&-text {
			font-size: 1em;
		}
	}
	&__instruction {
		display: flex;
		justify-content: center;
		margin-bottom: 40px;
		@media (max-width: 767px) {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		&-text {
			@media all and (max-width: 767px) {
				font-size: 15.5px;
			}
		}
	}
	&__actions {
		@media all and (max-width: 767px) {
			#main & {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				justify-content: center;
				margin-left: -5px;
				margin-right: -5px;
			}
		}
		#main .step2 & {
			margin-bottom: 40px;
			@media all and (max-width: 767px) {
				margin-bottom: 30px;
			}
		}
		&-item {
			@media all and (max-width: 767px) {
				#main .step2 .comSubmit & {
					width: auto;
					margin: 0 5px;
					flex-grow: 1;
				}
			}
		}
		&-button {
			#main .ss-credit__actions .ss-credit__actions-item & {
				@media all and (max-width: 767px) {
					padding: 15px 5px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 100% !important;
					min-width: 0;
					font-size: 15px;
				}
			}
		}
	}
	&__attention {
		display: flex;
		justify-content: center;
		text-align: center;
		@media all and (max-width: 767px) {
			text-align: left;
		}
		&-text {
			font-size: 18px;
		}
	}
	&__register-card {
		margin-top: 20px;
		margin-bottom: 40px;
		padding: 20px;
		border: 1px solid #C4C4C3;
		@media all and (max-width: 767px) {
			margin-bottom: 0px;
			padding: 20px 15px;
		}
		&-label {
			font-size: 14px;
		}
	}
}
#main .mailForm {
	&.is-module {
		@media all and (max-width: 767px) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.mailTable td {
		input[type="text"] {
			&::placeholder {
				color: rgba(#000, 0.3);
			}
			&.is-size-auto {
				width: auto;
			}
		}
		select {
			&.is-size-auto {
				@media all and (max-width: 767px) {
					width: auto;
				}
			}
		}
	}
}

//KAMO_RENEW3-197 [KMS-283] 【フォーム改修】カートインボタンの改善
#main .topBox .rCard .wrap a.ss-btn {
	margin-right: 10px;
	margin-left: 0;
}
#main .topBox .rCard .addBox.ss-addBox {
	width: 320px;
	right: 0;
    left: auto;
}
#main .topBox .rCard .addInner.ss-addInner {
	padding: 14px 28px;
}
#main .topBox .rCard ul.ss-list {
    overflow: visible;
	display: flex;
	justify-content: center;
	margin: 10px -5px 0;
}
#main .topBox .rCard li.ss-list-item {
	float: none;
	margin: 0 5px;
	white-space: nowrap;
}
#main .topBox .rCard li.ss-list-item:nth-child(2) {
	float: none;
}
#main .topBox .rCard li.ss-list-item a {
	color: #fff;
    background-color: #FF8B07;
}
#main .topBox .rCard li.ss-list-item.close a {
	color: #FF8B07;
    border: 1px solid #FF8B07;
	background: #fff;
}
@media (max-width: 767px) {
	#main .topBox .rCard .addBox.ss-addBox {
		width: auto;
		left: 0;
	}
	#main .topBox .rCard .addInner.ss-addInner {
		padding: 10px 12px 13px;
	}
}

// KAMO_RENEW3-198 KMS-324 【フォーム改善4】クレジットカード登録機能追加
.ss-credit__register-card {
	&.is-another {
		padding: 0;
	}
}
.ss-credit__register-card-contents {
	.is-registered & {
		display: flex;
		align-items: center;
	}
}
.ss-credit__register-card-label {
	.is-registered & {
		margin-left: 10px;
		&--em {
			font-weight: bold;
			font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
		}
	}
}
.ss-credit__select-regist-card {
	display: flex;
	margin-left: 15px;
	&-item {
		flex-grow: 1;
		display: flex;
		padding-right: 10px;
		margin: 5px 0;
		&-input {}
		&-text {
			word-break: break-all;
		}
	}
	input[type="button"] {
		color: #000;
	    background: #dad7d3;
	    border: none;
	    padding: 5px 5px;
		border-radius: 2px;
	}
	.btn_area {
		align-self: center;
	}
}
.ss-credit__register-card-accordion {
	padding: 20px;
	&-label {
		display: flex;
		justify-content: space-between;
		font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
		font-weight: bold;
		font-size: 14px;
		&:after {
			content: "+";
			display: block;
			width: 1.5em;
			text-align: center;
			.is-active & {
				content: "-";
			}
		}
	}
	&-detail {
		display: none;
		padding: 20px 0 0;
		.is-active & {
			display: block;
		}
	}
	&-title {
		margin-bottom: 10px;
	}
	&-tetx {
		color: #999;
	}
}

.ss-mypage-change-card {
	.information {
		margin: 10px 0;
	}
	.attention {
		font-size: 1em;
		@media (max-width: 767px) {
			font-size: 1em;
		}
	}
	.ss-credit__info {
		text-align: left;
	}
}
.ss-mypage-change-card-layout {
	margin-bottom: 40px;
	border-bottom: 1px solid #C4C4C3;
	@media (max-width: 767px) {
		margin: 0 17px 40px;
	}
}
.ss-mypage-change-card-title {
	text-align: center;
	font-size: 1.9rem;
	margin-bottom: 20px;
	@media (max-width: 767px) {
		font-size: 1.8rem;
	}
}
.ss-mypage-change-card-table {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 40px;
	border-collapse: collapse;
	th {
		text-align: center;
	    vertical-align: middle;
	    border: 1px solid #C4C4C3;
		background: #f4efeb;
		padding: 11px 15px;
		@media (max-width: 767px) {
			font-size: 1.5rem;
			padding: 15px 16px;
		}
	}
	td {
		border: 1px solid #C4C4C3;
	}
	p {
		text-align: center;
		padding: 40px 15px;
		font-size: 15px;
		@media (max-width: 767px) {
			padding: 40px 16px;
		}
	}
	&.is-center {
		text-align: left;
	}
}
.ss-mypage-change-card-data {
	display: flex;
	&.is-checked {
		background: #ffefef;
	}
	&__input,
	&__key,
	&__detail {

	}
	&__input {
		width: 80px;
		flex-shrink: 0;
		border-right: 1px solid #C4C4C3;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 11px 15px;
		box-sizing: border-box;
		@media (max-width: 767px) {
			width: 60px;
			padding: 15px 16px;
		}
	}
	&__key {
		width: 60px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 767px) {
			width: 40px;
		}
	}
	&__detail {
		flex-grow: 1;
		padding: 11px 15px;
		box-sizing: border-box;
		@media (max-width: 767px) {
			padding: 15px 16px;
		}
	}
}

#main .comTopBox ul.ss-mypage-navi {
	margin: 0 -10px;
	justify-content: flex-start;
	li {
		padding: 0 10px;
	}
}
#main .comSubmit li.ss-mypage-change-card__actions-item {
	@media (max-width: 767px) {
		width: 100%;
		margin: 0;
	}
}
#main .comSubmit li a.ss-mypage-change-card__button {
	width: 100%;
	max-width: 240px;
	margin: 0 auto 40px;
	background: #949494;
	border-color: #949494;
	color: #fff;
}

//KAMO_RENEW3-239 KMS-165 【システム改修】商品詳細ページの"２枚組"購入方法の見直しについて
.ss-tab {
	&__notes {
		color: #000;
		font-size: 18px;
		margin-top: 25px;
		margin-bottom: -15px;
		//animation: flash 1000ms infinite;
		width: 50%;
		text-align: center;
		white-space: nowrap;
		@media (max-width: 767px) {
			font-size: 15px;
		}
		&.is-right {
			margin-left: auto;
		}
		&.is-left {
			margin-right: auto;
		}
	}
	margin: 25px 0 10px;
	&__label {
		display: flex;
		padding: 0 4px;
		&-item {
			width: calc(48% - 8px);
			margin: 0 4px;
			border: #e8e8e8 solid 2px;
			border-bottom: none;
			background: #e8e8e8;
			padding: 4px 15px;
			font-size: 14px;
			font-weight: bold;
			border-radius: 10px 10px 0 0;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			&.is-active {
				width: calc(52% - 8px);
				color: #fff;
				font-size: 18px;
				background: #FF8B07;
				border: #FF8B07 solid 2px;
				border-bottom: none;
				position: relative;
				transform: translateY(-6px);
				&:after {
					content: "";
					width: calc(100% + 4px);
					height: 6px;
					position: absolute;
					bottom: -6px;
					left: -2px;
					background: #FF8B07;
				}
			}
			&:last-child {
				position: relative;
			}
			&-icon {
				width: 30px;
				position: absolute;
				left: 10px;
				top: -2px;
			}
		}
	}
	&__contents {
		&-item {
			padding: 15px 25px;
			background: #f6f1eb;
			display: none;
			//border-radius: 0 0 10px 10px;
			@media (max-width: 767px) {
				padding: 15px;
			}
			&.is-active {
				display: block;
				border: #FF8B07 solid 2px;
			}
		}
		&-comment {
			color: #E20012;
			margin-bottom: 20px;
			@media (max-width: 767px) {
				font-size: 14px;
				//text-align: center;
			}
		}
	}
	&__table {
		&-th {
			#main .topBox .rBox .styleTable & {
				@media (max-width: 767px) {
					width: 31.5%;
				}
			}
		}
	}
	.ss-product-detail__price {
		justify-content: flex-start;
		margin-bottom: 10px;
		@media (max-width: 767px) {
			justify-content: center;
		}
	}
}

@keyframes flash {
	50%{
		opacity: 0.25;
	}
}

//KAMO_RENEW3-243 KMS-412 【TOPページ】レイアウト変更・管理画面改修
.ss-slider {
	#main & {
		margin: 44px 0 69px;
		@media(max-width: 767px) {
			margin: 33px 0 33px;
		}
		.slider .slick-dots {
			@media(max-width: 767px) {
				margin-top: 12px;
			}
			li {
				@media(max-width: 767px) {
					width: 12px;
					height: 12px;
					background: url('../../img/top/dot02.png') no-repeat;
					background-size: 12px 12px !important;
					margin: 0 8px;
				}
				&.slick-active {
					background: url('../../img/top/dot01.png') no-repeat;
				}
			}
		}
		.arrow {
			@media(max-width: 767px) {
				display: block;
			    margin: 0;
			    width: auto;
			    position: static;
			}
		}
		.prev {
			@media(max-width: 767px) {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-100%);
			}
		}
		.next {
			@media(max-width: 767px) {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-100%);
			}
		}
	}
}
.ss-campaign {
	margin-bottom: 55px;
	@media (max-width: 767px) {
		margin-bottom: 25px;
	}
	&__body {
		padding: 0 20px;
		max-width: 970px;
		margin: 0 auto;
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		@media (max-width: 767px) {
			flex-direction: column;
		}
		&-item {
			width: 50%;
			padding: 0 10px;
			box-sizing: border-box;
			flex-grow: 1;
			margin: 0 0 10px;
			@media (max-width: 767px) {
				width: auto;
			}
		}
	}
	&__link {
		display: block;
		text-align: center;
	}
}
.ss-media {
	margin: 55px 0 63px;
	@media (max-width: 767px) {
		margin: 25px 0 35px;
	}
	&__title {
		img {
			width: 100%;
		}
	}
	&__body {
		padding: 0 20px;
		max-width: 970px;
		margin: 0 auto;
	}
	&__contents {
		border: solid 3px #c6a45c;
		padding: 18px;
		position: relative;
		@media (max-width: 767px) {
			border: solid 1px #c6a45c;
			padding: 10px 16px;
		}
	}
	&__list {
		&__item {
		}
	}
	&__item {
		padding: 0 18px;
		@media (max-width: 767px) {
			padding: 0 2px;
		}
	}
	.slick-prev,
	.slick-next {
		position: absolute;
		transform: translateY(-50%);
		width: 10px;
		height: 0;
		padding-top: 44px;
		overflow: hidden;
		border: none;
		background: none;
		cursor: pointer;
	}
	.slick-prev {
		top: 50%;
		left: -8px;
		background: url('../../img/common/prev.png') no-repeat center;
		background-size: cover;
		@media (max-width: 767px) {
			left: -14px;
		}
	}
	.slick-next {
		top: 50%;
		right: -8px;
		background: url('../../img/common/next.png') no-repeat center;
		background-size: cover;
		@media (max-width: 767px) {
			right: -14px;
		}
	}
}
.ss-recommend {
	.ss-aw-recommend__title {
		display: flex;
		flex-direction: column;
		#main & {
			border: none;
			padding: 0;
			margin-bottom: 22px;
			@media (max-width: 767px) {
				margin-bottom: 13px;
			}

		}
		&:after {
			content: "";
			display: block;
			width: 422px;
			height: 17px;
			background: url('../../img/top/line_title.png') no-repeat center;
			@media (max-width: 767px) {
				width: 349.5px;
				height: 14px;
				background: url('../../img/top/line_title_sp.png') no-repeat center;
				background-size: cover;
			}
		}
	}
	.ss-aw-recommend__title-text {
		font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		@media (max-width: 767px) {
			flex-direction: row;
			letter-spacing: 0.04em;
			font-size: 20px;
		}
		&:before {
			content: "";
			display: block;
			width: 28px;
			height: 26px;
			background: url('../../img/top/ico_title_recommend.png') no-repeat center;
			margin: 0 0 5px;
			@media (max-width: 767px) {
				width: 25px;
				height: 23px;
				margin: 0 10px 0 0;
				background-size: cover;
			}
		}
	}
	.ss-aw-recommend__list {
		#main & {
			margin-bottom: 26px;
			@media (max-width: 767px) {
				margin-bottom: 0;
			}
		}
	}
	.ss-aw-recommend__list-item {
		&:nth-child(n + 7) {
			display: none;
		}
		@media (max-width: 767px) {
			&:nth-child(n + 5) {
				display: none;
			}
		}
	}
	.is-active {
		.ss-aw-recommend__list-item {
			&:nth-child(n + 7) {
				display: block;
			}
			@media (max-width: 767px) {
				&:nth-child(n + 5) {
					display: block;
				}
			}
		}
	}
	&__actions {
		&-button {
			width: 100%;
			max-width: 400px;
		    margin: 0 auto;
		    display: block;
		    border: #b4b4b3 solid 3px;
		    border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px 10px;
			position: relative;
			cursor: pointer;
			background: #fff;
			box-sizing: border-box;
			@media (max-width: 767px) {
				border: #b4b4b3 solid 2px;
				border-radius: 5px;
			}
			&-text {
				//font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
				font-size: 20px;
				letter-spacing: 0.02em;
				//font-weight: bold;
				font-feature-settings: "palt";
				&.is-open {
					display: block;
					.is-active & {
						display: none;
					}
				}
				&.is-close {
					display: none;
					.is-active & {
						display: block;
					}
				}
				@media (max-width: 767px) {
					font-size: 17px;
					letter-spacing: 0.05em;
				}
			}
			&-icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%);
			    margin: 0 0 0 141px;
			    display: block;
			    line-height: 1;
				@media (max-width: 767px) {
					width: 14.5px;
					margin: 0 0 0 118px;
				}
				.is-active & {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}
	.ss-aw-recommend__actions-item {
		font-size: 16px;
		@media (max-width: 767px) {
			font-size: 13px;
		}
	}
}
.ss-commitment {
	margin-top: 73px;
	background: #ffedd9;
	@media (max-width: 767px) {
		margin-top: 39px;
	}
	&__body {
		padding: 0 20px;
		max-width: 970px;
		margin: 0 auto;
	}
	&__heading {
		padding: 42px 0 0;
		@media (max-width: 767px) {
			padding: 24px 0 0;
		}
	}
	&__title {
		display: flex;
		flex-direction: column;
		align-items: center;
		&:after {
			content: "";
			display: block;
			width: 422px;
			height: 17px;
			background: url('../../img/top/line_title.png') no-repeat center;
			@media (max-width: 767px) {
				width: 349.5px;
				height: 14px;
				background: url('../../img/top/line_title_sp.png') no-repeat center;
				background-size: cover;
			}
		}
		&-text {
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			font-size: 20px;
			letter-spacing: 0.05em;
			font-weight: bold;
			font-feature-settings: "palt";
			display: flex;
			flex-direction: column;
			align-items: center;
			@media (max-width: 767px) {
				flex-direction: row;
				font-size: 20px;
			}
			&:before {
				content: "";
				display: block;
				width: 33px;
				height: 30px;
				background: url('../../img/top/ico_title_commitment.png') no-repeat center;
				margin: 0 0 5px;
				@media (max-width: 767px) {
					width: 28.5px;
					height: 25.5px;
					background-size: cover;
					margin: 0 10px 0 0;
				}
			}
		}
	}
	&__contents {
		padding: 32px 0 65px;
		@media (max-width: 767px) {
			padding: 11px 0 27px;
		}
	}
	&__list {
		display: flex;
		margin: 0 -10px;
		@media (max-width: 767px) {
			margin: 0 -4.5px;
		}
		&-item {
			width: 50%;
			padding: 0 10px;
			box-sizing: border-box;
			@media (max-width: 767px) {
				padding: 0 4.5px;
			}
		}
	}
	&__item {
		&-link {
			display: flex;
			@media (max-width: 767px) {
				flex-direction: column;
			}
		}
		&-image {
			width: (247 / 455) * 100#{'%'};
			margin: 0;
			flex-shrink: 0;
			@media (max-width: 767px) {
				width: auto;
			}
		}
		&-contents {
			margin: 0 0 0 20px;
			padding: 11px 0 0;
			@media (max-width: 767px) {
				margin: 0 0 0 0px;
			}
		}
		&-title {
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			font-size: 18px;
			@media (max-width: 767px) {
				font-size: 15px;
			}
		}
		&-text {
			font-size: 16px;
			line-height: (22/16);
			margin: 10px 0 0;
			@media (max-width: 767px) {
				font-size: 15px;
				line-height: (36/24);
				margin: 4px 0 0;
			}
		}
		&-actions {
			font-size: 16px;
			text-align: right;
			margin: 20px 0 0;
			padding: 0 14px 0 0;
			@media (max-width: 767px) {
				font-size: 13px;
				margin: 0;
				padding: 0;
			}
		}
	}
}
.ss-columns {
	margin-top: 131px;
	background: #ffebeb;
	padding-bottom: 47px;
	@media(max-width: 767px) {
		margin-top: 98px;
		padding-bottom: 28px;
	}
	&__body {
		padding: 0 20px;
		max-width: 970px;
		margin: 0 auto;
	}
	&__heading {
		text-align: center;
	}
	&__title {
		transform: translateY(-79px);
		@media(max-width: 767px) {
			transform: translateY(-68px);
			max-width: 256px;
			margin: auto;
		}
	}
	&__contents {
		margin: -46px 0 0;
		@media(max-width: 767px) {
			margin: -55px 0 0;
		}
	}
	&__banner {
		margin: -46px 0 0;
		text-align: center;
	}
	&__date {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 27px 0 0;
		@media(max-width: 767px) {
			justify-content: flex-start;
			margin: 12px 0 0;
		}
		&-icon {
			width: 70px;
			height: 25px;
			line-height: 25px;
			color: #fff;
			background: #e90000;
			text-align: center;
			font-size: 18px;
			letter-spacing: 0.05em;
			margin: 0 15px 0 0;
			@media(max-width: 767px) {
				font-size: 10px;
				width: 44px;
				height: 16px;
				line-height: 16px;
			}
		}
		&-text {
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			font-size: 18px;
			font-weight: bold;
			@media(max-width: 767px) {
				font-size: 15px;
			}
		}
	}
	&__list {
		display: flex;
		margin: 25px -11px 0;
		@media(max-width: 767px) {
			flex-direction: column;
			margin-top: 12px;
		}
		&-item {
			width: 25%;
			padding: 0 11px;
			box-sizing: border-box;
			display: flex;
			@media(max-width: 767px) {
				width: auto;
				margin-bottom: 15px;
				display: block;
			}
		}
	}
	&__item {
		display: flex;
		@media(max-width: 767px) {
			display: block;
		}
		&-link {
			display: flex;
			flex-direction: column;
			width: 100%;
			@media(max-width: 767px) {
				flex-direction: row;
			}
		}
		&-label {
			font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif;
			font-size: 16px;
			text-align: center;
			padding: 4px 10px;
			@media(max-width: 767px) {
				font-size: 15px;
				padding: 3px 5px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&.is-pc {
				display: block;
				@media(max-width: 767px) {
					display: none;
				}
			}
			&.is-sp {
				display: none;
				@media(max-width: 767px) {
					display: block;
				}
			}
			.ss-columns__list-item:nth-child(1) & {
				background: #eac4b2;
			}
			.ss-columns__list-item:nth-child(2) & {
				background: #8fb95b;
			}
			.ss-columns__list-item:nth-child(3) & {
				background: #e9bd63;
			}
			.ss-columns__list-item:nth-child(4) & {
				background: #99c2bb;
			}
		}
		&-image {
			margin: 14px 0 0;
			flex-shrink: 0;
			@media(max-width: 767px) {
				margin: 0;
				width: (286 / 670) * 100#{'%'};
			}
			&-box {
				width: 100%;
				height: 0;
				padding-top: (180 / 216) * 100#{'%'};
				position: relative;
				overflow: hidden;
				background: #000;
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
		&-contents {
			background: #fff;
			padding: 16px 10px;
			flex-grow: 1;
			@media(max-width: 767px) {
				padding: 7px 10px;
				width: calc(100% - 42.68657%);
			}

		}
		&-title {
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			font-size: 16px;
			font-weight: bold;
			min-height: 3em;
			@media(max-width: 767px) {
				display: none;
			}
		}
		&-date {
			color: #9a9a9a;
			font-size: 12px;
			margin: 4px 0 0;
			@media(max-width: 767px) {
				display: none;
			}
		}
		&-text {
			font-size: 16px;
			line-height: (22/12);
			margin: 3px 0 0;
			@media(max-width: 767px) {
				font-size: 13px;
				line-height: (36/24);
				margin: 7px 0 0 9px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
		&-actions {
			font-size: 16px;
			text-align: right;
			margin: 8px 0 0;
			@media(max-width: 767px) {
				font-size: 13px;
				margin: 1px 0 0;
			}
		}
	}
	&__actions {
		margin: 45px 0 0;
		@media(max-width: 767px) {
			margin: 9px 0 0;
		}
		&-button {
			display: block;
			font-size: 20px;
			line-height: 54px;
			width: 100%;
			max-width: 400px;
			height: 54px;
			margin: 0 auto;
			color: #fff;
			background: #f18e8a;
			text-align: center;
			border-radius: 27px;
			padding: 0 64px;
			box-sizing: border-box;
			position: relative;
			@media(max-width: 767px) {
				font-size: 17px;
				height: 45px;
				line-height: 45px;
				max-width: 275px;
				padding: 0 36px 0 23px;
			}
			&:visited,
			&:hover,
			&:link {
				color: #fff;
			}
			&:after {
				position: absolute;
				top: 50%;
				right: 44px;
				transform: translateY(-50%);
				content: "";
				display: block;
				width: 10px;
				height: 17px;
				background: url('../../img/top/arrow-column.png') no-repeat center;
				@media(max-width: 767px) {
					width: 8px;
					height: 14px;
					right: 23px;
					background-size: cover;
				}
			}
		}
	}
	&__article {
		padding: 14px 0 0;
		@media(max-width: 767px) {
			padding: 0;
		}
		&-link {
			display: flex;
			@media(max-width: 767px) {
				flex-direction: column;
			}
		}
		&-image {
			width: 582px;
			width: (582 / 930) * 100#{'%'};
			margin: 0;
			flex-shrink: 0;
			text-align: center;
			@media(max-width: 767px) {
				width: auto;
				margin: 4px 0 0;
			}
		}
		&-contents {
			margin: 0 0 0 44px;
			@media(max-width: 767px) {
				margin: 0;
			}
		}
		&-info {
			display: flex;
			flex-direction: column;
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			font-weight: bold;
			@media(max-width: 767px) {
				align-items: center;
			}
			&.is-pc {
				display: flex;
				@media(max-width: 767px) {
					display: none;
				}
			}
			&.is-sp {
				display: none;
				@media(max-width: 767px) {
					display: flex;
				}
			}
			&--sub {
				font-size: 16px;
				@media(max-width: 767px) {
					font-size: 15px;
				}
			}
			&--main {
				font-size: 20px;
				@media(max-width: 767px) {
					font-size: 18px;
				}
			}
		}
		&-date {
			display: flex;
			align-items: center;
			margin: 14px 0 0;
			@media(max-width: 767px) {
				justify-content: flex-start;
				margin: 15px 0 0;
			}
			&-icon {
				width: 70px;
				height: 25px;
				line-height: 25px;
				color: #fff;
				background: #e90000;
				text-align: center;
				font-size: 18px;
				letter-spacing: 0.05em;
				margin: 0 15px 0 0;
				@media(max-width: 767px) {
					font-size: 10px;
					width: 44px;
					height: 16px;
					line-height: 16px;
				}
			}
			&-text {
				font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
				font-size: 18px;
				font-weight: bold;
				@media(max-width: 767px) {
					font-size: 15px;
				}
			}
		}
		&-text {
			font-size: 16px;
			line-height: (30/16);
			margin: 5px 0 0;
			@media(max-width: 767px) {
				font-size: 13px;
				line-height: (36/24);
				display: -webkit-box;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
		&-actions {
			font-size: 16px;
			text-align: right;
			margin: 5px 0 0;
			@media(max-width: 767px) {
				font-size: 13px;
				//margin: 0;
			}
		}
	}
}
.ss-feature {
	margin-top: 80px;
	@media(max-width: 767px) {
		margin-top: 38px;
	}
	&__body {
		padding: 0 20px;
		max-width: 970px;
		margin: 0 auto;
	}
	&__heading {}
	&__title {
		display: flex;
		flex-direction: column;
		align-items: center;
		&:after {
			content: "";
			display: block;
			width: 422px;
			height: 17px;
			background: url('../../img/top/line_title.png') no-repeat center;
			@media (max-width: 767px) {
				width: 349.5px;
				height: 14px;
				background: url('../../img/top/line_title_sp.png') no-repeat center;
				background-size: cover;
			}
		}
		&-text {
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			font-size: 20px;
			font-weight: normal;
			font-feature-settings: "palt";
			display: flex;
			align-items: center;
			justify-content: center;
			&:before,
			&:after {
				content: "";
				display: block;
				width: 25px;
				height: 25px;
				background: url('../../img/top/ico_title_feature.png') no-repeat center;
				margin: 0 24px;
				@media(max-width: 767px) {
					width: 22px;
					height: 22px;
					background-size: cover;
					margin: 0 19px;
				}
			}
		}
		&-specer {
			width: 0.5em;
		}
	}
	&__contents {
		padding: 32px 0 21px;
		@media(max-width: 767px) {
			padding: 14px 0 21px;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;
		@media(max-width: 767px) {
			flex-direction: column;
			margin: 0;
		}
		&-item {
			width: 50%;
			padding: 0 12px 12px;
			box-sizing: border-box;
			@media(max-width: 767px) {
				width: auto;
				padding: 0 5px 20px;
			}
			&:nth-child(n + 5) {
				display: none;
				.is-active & {
					display: block;
				}
			}
			@media(max-width: 767px) {
				&:nth-child(n + 3) {
					display: none;
					.is-active & {
						display: block;
					}
				}
			}
		}
	}
	&__banner {
		text-align: center;
	}
	&__actions {
		margin: 26px 0 0;
		@media(max-width: 767px) {
			margin: 4px 0 0;
		}
		&-button {
			width: 100%;
			max-width: 400px;
		    margin: 0 auto;
		    display: block;
		    border: #b4b4b3 solid 3px;
		    border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px 10px;
			position: relative;
			cursor: pointer;
			background: #fff;
			box-sizing: border-box;
			@media (max-width: 767px) {
				border: #b4b4b3 solid 2px;
				border-radius: 5px;
			}
			&-text {
				//font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
				font-size: 20px;
				letter-spacing: 0.02em;
				//font-weight: bold;
				font-feature-settings: "palt";
				&.is-open {
					display: block;
					.is-active & {
						display: none;
					}
				}
				&.is-close {
					display: none;
					.is-active & {
						display: block;
					}
				}
				@media (max-width: 767px) {
					font-size: 17px;
					letter-spacing: 0.05em;
				}
			}
			&-icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%);
			    margin: 0 0 0 141px;
			    display: block;
			    line-height: 1;
				@media (max-width: 767px) {
					width: 14.5px;
					margin: 0 0 0 118px;
				}
				.is-active & {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}
}
.ss-ranking {
	#main & {
		margin-top: 79px;
		@media (max-width: 767px) {
			margin-top: 39px;
			margin-bottom: 45px;
		}
	}
	&__title {
		font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
		font-size: 20px;
		letter-spacing: 0.15em;
		font-weight: bold;
		font-feature-settings: "palt";
		display: flex;
		flex-direction: column;
		align-items: center;
		.comProduct & {
			border: none;
		}
		.comProduct.numProduct & {
			padding: 0;
		}
		&:after {
			content: "";
			display: block;
			width: 422px;
			height: 17px;
			background: url('../../img/top/line_title.png') no-repeat center;
			@media (max-width: 767px) {
				width: 349.5px;
				height: 14px;
				background: url('../../img/top/line_title_sp.png') no-repeat center;
				background-size: cover;
			}
		}
		&-text {
			flex-direction: column;
			align-items: center;
			margin: 0;
			.comProduct.numProduct h2 & {
				display: flex;
				padding: 0;
				background: none;
			}
			@media (max-width: 767px) {
				flex-direction: row;
			}
			&:before {
				content: "";
				display: block;
				width: 32px;
				height: 22px;
				background: url('../../img/top/ico_title_ranking.png') no-repeat center;
				margin: 0 0 8px;
				@media (max-width: 767px) {
					width: 27px;
					height: 19px;
					background-size: cover;
					margin: 0 10px 0 0;
				}
			}
		}
	}
	&__actions {
		margin: 34px 0 0;
		@media (max-width: 767px) {
			margin: 9px 0 0;
		}
		&-button {
			display: block;
			font-size: 20px;
			line-height: 54px;
			width: 100%;
			max-width: 400px;
			height: 54px;
			margin: 0 auto;
			color: #000000;
			background: #eee3d6;
			text-align: center;
			border-radius: 27px;
			padding: 0 64px;
			box-sizing: border-box;
			position: relative;
			@media(max-width: 767px) {
				font-size: 17px;
				height: 45px;
				line-height: 45px;
				max-width: 275px;
				padding: 0 36px 0 36px;
			}
			&:visited,
			&:hover,
			&:link {
				color: #000000;
			}
			&:after {
				position: absolute;
				top: 50%;
				right: 44px;
				transform: translateY(-50%);
				content: "";
				display: block;
				width: 10px;
				height: 17px;
				background: url('../../img/top/arrow-column-b.png') no-repeat center;
				@media(max-width: 767px) {
					width: 8px;
					height: 14px;
					right: 23px;
					background-size: cover;
				}
			}
		}
	}
}
.ss-checked-items {
	margin-top: 97px;
	margin-bottom: 75px;
	@media (max-width: 767px) {
		margin-top: 45px;
		margin-bottom: 33px;
	}
	&__title {
		font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
		letter-spacing: 0.175em;
		font-weight: bold;
		font-feature-settings: "palt";
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (max-width: 767px) {
			letter-spacing: 0.04em;
		}
		.comProduct & {
			padding-bottom: 9px;
			margin-bottom: 32px;
			@media (max-width: 767px) {
				border: none;
				padding-bottom: 0;
				margin-bottom: 20px;
			}
		}
		@media (max-width: 767px) {
			&:after {
				content: "";
				display: block;
				width: 349.5px;
				height: 14px;
				background: url('../../img/top/line_title_sp.png') no-repeat center;
				background-size: cover;
			}
		}
		&-text {
			flex-direction: column;
			align-items: center;
			margin: 0;
			.comProduct h2 & {
				display: flex;
				padding: 0;
				background: none;
			}
			@media (max-width: 767px) {
				flex-direction: row;
				font-size: 20px;
			}
			&:before {
				content: "";
				display: block;
				width: 24px;
				height: 20px;
				background: url('../../img/top/ico_title_checked.png') no-repeat center;
				margin: 0 0 8px;
				@media (max-width: 767px) {
					width: 19px;
					height: 15.5px;
					background-size: cover;
					margin: 0 10px 0 0;
				}
			}
		}
	}
}
.ss-event {
	margin-top: 75px;
	@media (max-width: 767px) {
		margin-top: 33px;
	}
	&__body {
		padding: 0 20px;
		max-width: 970px;
		margin: 0 auto;
		@media (max-width: 767px) {
			padding: 0;
			max-width: 375px;
		}
	}
	&__contents {
		min-height: 788px;
		background: url('../../img/top/bg_event.jpg') no-repeat top center;
		background-size: cover;
		box-sizing: border-box;
		padding: 175px 0 0;
		@media (max-width: 767px) {
			min-height: 574px;
			background: url('../../img/top/bg_event_sp.jpg') no-repeat top center;
			background-size: cover;
			padding: 120px 0 0;
		}
	}
	&__text {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
		font-weight: bold;
		&--main {
			font-size: 20px;
			@media (max-width: 767px) {
				font-size: 19px;
			}
		}
		&--sub {
			font-size: 16px;
			margin: 5px 0 0;
			@media (max-width: 767px) {
				font-size: 15px;
				margin: 0;
			}
		}
	}
	&__actions {
		margin: 460px 0 0;
		@media (max-width: 767px) {
			margin: 334px 0 0;
		}
		&-button {
			display: block;
			font-size: 20px;
			line-height: 54px;
			width: 100%;
			max-width: 400px;
			height: 54px;
			margin: 0 auto;
			color: #fff;
			background: #56b285;
			text-align: center;
			border-radius: 27px;
			padding: 0 64px;
			box-sizing: border-box;
			position: relative;
			@media(max-width: 767px) {
				font-size: 17px;
				height: 45px;
				line-height: 45px;
				max-width: 275px;
				padding: 0 36px 0 36px;
			}
			&:visited,
			&:hover,
			&:link {
				color: #fff;
			}
			&:after {
				position: absolute;
				top: 50%;
				right: 44px;
				transform: translateY(-50%);
				content: "";
				display: block;
				width: 10px;
				height: 17px;
				background: url('../../img/top/arrow-column.png') no-repeat center;
				@media(max-width: 767px) {
					width: 8px;
					height: 14px;
					right: 23px;
					background-size: cover;
				}
			}
		}
	}
}
.ss-new {
	margin-top: 74px;
	@media (max-width: 767px) {
		margin-top: 39px;
	}
	&__body {
		padding: 0 20px;
		max-width: 970px;
		margin: 0 auto;
	}
	&__heading {}
	&__title {
		display: flex;
		flex-direction: column;
		align-items: center;
		&:after {
			content: "";
			display: block;
			width: 422px;
			height: 17px;
			background: url('../../img/top/line_title.png') no-repeat center;
			@media (max-width: 767px) {
				width: 349.5px;
				height: 14px;
				background: url('../../img/top/line_title_sp.png') no-repeat center;
				background-size: cover;
			}
		}
		&-text {
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			font-size: 20px;
			letter-spacing: 0.05em;
			font-weight: bold;
			font-feature-settings: "palt";
			display: flex;
			flex-direction: column;
			align-items: center;
			@media (max-width: 767px) {
				flex-direction: row;
				font-size: 20px;
			}
			&:before {
				content: "";
				display: block;
				width: 23px;
				height: 32px;
				background: url('../../img/top/ico_title_new.png') no-repeat center;
				margin: 0 0 2px;
				@media (max-width: 767px) {
					width: 18.5px;
					height: 26.5px;
					background-size: cover;
					margin: 0 10px 0 0;
				}
			}
		}
	}
	&__contents {
		padding: 44px 0 41px;
		@media(max-width: 767px) {
			padding: 12px 0 0;
		}
	}
	&__list {
		display: flex;
		margin: 0 -10px;
		@media (max-width: 767px) {
			flex-direction: column;
		}
		&-item {
			width: 50%;
			padding: 0 10px;
			box-sizing: border-box;
			@media(max-width: 767px) {
				width: auto;
				margin-bottom: 20px;
			}
		}
	}
	&__banner {
		text-align: center;
	}
}

//KAMO_RENEW3-294 カテゴリ・商品一覧・商品詳細・商品管理の改修
.ss-category {
	font-family: 'Hiragino Kaku Gothic ProN';
	width: 100%;
	max-width: 780px;
	margin: auto;
	box-sizing: border-box;
	padding: 0 15px;
	@media (max-width: 767px) {
		padding: 0 24px;
	}
	&__heading {
		padding-top: 15px;
		@media (max-width: 767px) {
			padding-top: 2px;
		}
	}
	&__title {
		line-height: 1.2;
		font-size: 20px;
		font-weight: bold;
	}
	&__contents {}
	&__popular,
	&__anchor {
		max-width: 700px;
		margin: 56px auto 0;
		@media (max-width: 767px) {
			margin-top: 33px;
		}
		&-title {
			#main & {
				font-size: 20px;
				line-height: 1.2;
				font-weight: bold;
				box-sizing: border-box;
				padding: 0 0 13px;
				border-bottom: solid 1px #000;
				@media (max-width: 767px) {
					display: block;
					font-size: 15px;
					padding-bottom: 7px;
				}
			}
		}
	}
	&__popular {
		&-list {
			display: flex;
			margin: 23px 0 0;
			padding: 0 0 13px;
			@media (max-width: 767px) {
				margin-top: 13px;
				padding-bottom: 17px;
			}
			&-item {
				width: 50%;
				box-sizing: border-box;
				&:nth-child(1) {
					padding: 0px 10px 0 70px;
					@media (max-width: 767px) {
						padding: 0 13.5px 0 0;
					}
				}
				&:nth-child(2) {
					padding: 0px 70px 0 10px;
					@media (max-width: 767px) {
						padding: 0 0 0 13.5px;
					}
				}
			}
		}
		&-product {
			max-width: 230px;
			margin: auto;
			&-link {
				display: block;
				&:hover {
					opacity: 0.8;
				}
			}
			&-title {
				font-size: 16px;
				line-height: 1.4;
				font-weight: normal;
				display: flex;
				align-items: center;
				&-number {
					flex-shrink: 0;
					width: 55px;
					height: 47px;
					background-image: url('../../img/category/ico-crown.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					font-size: 19px;
					font-weight: normal;
					color: #fff;
					line-height: 1.2;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					padding: 12px 0 0;
					@media (max-width: 767px) {
						width: 38px;
						height: 32.5px;
						font-size: 13.25px;
					}
				}
				&-text {
					font-size: inherit;
					line-height: inherit;
					font-weight: inherit;
					margin: 0 0 0 7px;
					padding: 8px 0 0;
					@media (max-width: 767px) {
						display: none;
					}
				}
			}
			&-image {
				margin: 13px 0 0;
				@media (max-width: 767px) {
					margin-top: 6px;
				}
			}
			&-text {
				display: none;
				@media (max-width: 767px) {
					display: block;
					font-size: 14px;
					line-height: 1.4;
					margin: 10px 0 0;
				}
			}
		}
	}
	&__anchor {
		&-contents {
			padding: 0 0 50px;
			@media (max-width: 767px) {
				padding: 0px 0 34px;
			}
		}
		&-layout {
			display: flex;
			border-bottom: solid 1px #dddddd;
			&-item {
				width: 50%;
				box-sizing: border-box;
				&:nth-child(1) {
					padding: 0 0 0 153px;
					@media (max-width: 767px) {
						padding: 0 0 0 20px;
					}
				}
				&:nth-child(2) {
					padding: 0 0 0 36px;
					@media (max-width: 767px) {
						padding: 0;
					}
				}
			}
		}
		&-link {
			display: inline-flex;
			align-items: center;
			font-size: 16px;
			padding: 4px 0;
			@media (max-width: 767px) {
				font-size: 14px;
				display: flex;
				padding: 9px 0;
			}
			&:before {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 5px 0 5px;
				margin: 0 9px 0 0;
			}
			&:hover {
				opacity: 0.8;
			}
			&.is-anchor-01 {
				&:before {
					border-color: #c89b9b transparent transparent transparent;
				}
			}
			&.is-anchor-02 {
				&:before {
					border-color: #8ea0bf transparent transparent transparent;
				}
			}
			&.is-anchor-03 {
				&:before {
					border-color: #c9a977 transparent transparent transparent;
				}
			}
			&.is-anchor-04 {
				&:before {
					border-color: #c9c379 transparent transparent transparent;
				}
			}
			&.is-anchor-05 {
				&:before {
					border-color: #95c68d transparent transparent transparent;
				}
			}
			&.is-anchor-06 {
				&:before {
					border-color: #ae97c7 transparent transparent transparent;
				}
			}
			&.is-anchor-07 {
				&:before {
					border-color: #91c1bf transparent transparent transparent;
				}
			}

		}
	}
	&__division {
		padding-top: 101px;
		margin-top: -101px;
		margin-bottom: 21px;
		@media (max-width: 767px) {
			padding-top: 52px;
			margin-top: -52px;
			margin-bottom: 4px;
		}
		&-title {
			#main & {
				font-size: 20px;
				line-height: 1.2;
				font-weight: bold;
				padding: 15px 0 0;
				text-align: center;
				@media (max-width: 767px) {
					display: block;
					font-size: 18px;
				}
			}
			#anchor-01 & {
				color: #c89b9b;
			}
			#anchor-02 & {
				color: #8ea0bf;
			}
			#anchor-03 & {
				color: #c9a977;
			}
			#anchor-04 & {
				color: #c9c379;
			}
			#anchor-05 & {
				color: #95c68d;
			}
			#anchor-06 & {
				color: #ae97c7;
			}
			#anchor-07 & {
				color: #91c1bf;
			}
		}
	}
	&__subdivision {
		margin: 15px 0 0;
		@media (max-width: 767px) {
			    margin: 10px 0 0;
		}
		&-title {
			font-size: 20px;
			font-weight: normal;
		}
		&-link {
			display: block;
			font-size: inherit;
			font-weight: inherit;
			line-height: 44px;
			position: relative;
			padding: 0 0 0 26px;
			&:hover {
				opacity: 0.8;
			}
			@media (max-width: 767px) {
				font-size: 17px;
				margin: 0 -24px;
				line-height: 50px;
				padding: 0 0 0 24px;
			}
			&:visited,
			&:link {
				color: #fff;
			}
			&:before,
			&:after {
				content: "";
				display: block;
				width: 13px;
				height: 2px;
				background: #fff;
				position: absolute;
				top: 50%;
				right: 27px;
				z-index: 1;
				transform-origin: (12 / 13) * 100% 50%;
				@media (max-width: 767px) {
					width: 10px;
					height: 1px;
					transform-origin: (9.5 / 10) * 100% 50%;
				}
			}
			&:before {
				transform: translateY(-50%) rotate(45deg);
			}
			&:after {
				transform: translateY(-50%) rotate(-45deg);
			}
			#anchor-01 & {
				background-color: #c89b9b;
			}
			#anchor-02 & {
				background-color: #8ea0bf;
			}
			#anchor-03 & {
				background-color: #c9a977;
			}
			#anchor-04 & {
				background-color: #c9c379;
			}
			#anchor-05 & {
				background-color: #95c68d;
			}
			#anchor-06 & {
				background-color: #ae97c7;
			}
			#anchor-07 & {
				background-color: #91c1bf;
			}
		}
		&-contents {
			overflow: hidden;
			margin: 12px 0 0;
			padding: 0 0 10px;
			@media (max-width: 767px) {
				padding: 0 0 17px;
			}
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;
			@media (max-width: 767px) {
				margin: 0 -13.5px;
			}
			&-item {
				width: 25%;
				padding: 15px;
				box-sizing: border-box;
				display: flex;
				@media (max-width: 767px) {
					width: 50%;
					padding: 13.5px;
				}
			}
		}
		&-item {
			flex-grow: 1;
			display: flex;
			&-link {
				flex-grow: 1;
				border: #f2f2f2 solid 2px;
				border-radius: 10px;
				box-sizing: border-box;
				padding: 3px;
				position: relative;
				&:hover {
					opacity: 0.8;
				}
				@media (max-width: 767px) {
					border-width: 1px;
					border-radius: 5px;
				}
				&:before,
				&:after {
					content: "";
					display: block;
					width: 19px;
					height: 2px;
					background: #f2f2f2;
					position: absolute;
					top: 50%;
					right: 10px;
					z-index: 1;
					transform-origin: (18 / 19) * 100% 50%;
					@media (max-width: 767px) {
						width: 17px;
						height: 1px;
						transform-origin: (16.5 / 17) * 100% 50%;
					}
				}
				&:before {
					transform: translateY(-50%) rotate(45deg);
				}
				&:after {
					transform: translateY(-50%) rotate(-45deg);
				}
				#anchor-01 & {
					border-color: #c89b9b;
					&:before,
					&:after {
						background: #c89b9b;
					}
				}
				#anchor-02 & {
					border-color: #8ea0bf;
					&:before,
					&:after {
						background: #8ea0bf;
					}
				}
				#anchor-03 & {
					border-color: #c9a977;
					&:before,
					&:after {
						background: #c9a977;
					}
				}
				#anchor-04 & {
					border-color: #c9c379;
					&:before,
					&:after {
						background: #c9c379;
					}
				}
				#anchor-05 & {
					border-color: #95c68d;
					&:before,
					&:after {
						background: #95c68d;
					}
				}
				#anchor-06 & {
					border-color: #ae97c7;
					&:before,
					&:after {
						background: #ae97c7;
					}
				}
				#anchor-07 & {
					border-color: #91c1bf;
					&:before,
					&:after {
						background: #91c1bf;
					}
				}

			}
			&-image {
				margin: 6px auto 0;
				text-align: center;
				max-width: 108px;
				@media (max-width: 767px) {
					margin: 10px auto 0;
					max-width: 94px;
				}
			}
			&-title {
				font-size: 16px;
				line-height: (18 / 16);
				font-weight: normal;
				text-align: center;
				min-height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 0 7px;
				@media (max-width: 767px) {
					font-size: 14px;
					line-height: (34 / 28);
					min-height: 34px;
					margin: 0 0 6px;
				}
			}
		}
	}
}

.ss-slogan {
	font-size: 20px;
	line-height: (28/20);
	margin: 13px 0 12px;
	@media (max-width: 767px) {
		font-size: 17px;
		line-height: (46/34);
		margin: 7px 0 4px;
	}
}
.ss-detail-tab {
	width: 100%;
	max-width: 782px;
    margin: 0 auto;
	padding: 0 16px;
	box-sizing: border-box;
	@media (max-width: 767px) {
		padding: 0;
		margin-top: 38px;
	}
	&__info {
		display: flex;
		margin: 0 0 11px;
		@media (max-width: 767px) {
			margin: 0 0 8px;
		}
		&-item {
			width: 50%;
			text-align: center;
			font-size: 18px;
			line-height: (40 / 28);
			white-space: nowrap;
			@media (max-width: 767px) {
				font-size: 14px;
			}
			&-text {
				font-size: inherit;
				line-height: inherit;
				display: block;
				.is-non-active & {
					display: none;
				}
				&-break {
					display: none;
					@media (max-width: 767px) {
						display: inline;
					}
				}
			}
		}
	}
	&__body {
		border: #b2b2b2 solid 2px;
		@media (max-width: 767px) {
			border-width: 1px;
		}
	}
	&__label {
		display: flex;
		border-bottom: #b2b2b2 solid 2px;
		@media (max-width: 767px) {
			border-width: 1px;
		}
		&-item {
			width: 50%;
			box-sizing: border-box;
			text-align: center;
			font-size: 16px;
			background: #ededed;
			padding: 9px;
			cursor: pointer;
			@media (max-width: 767px) {
				font-size: 14px;
			}
			&:nth-child(1) {
				border-right: #b2b2b2 solid 1px;
				@media (max-width: 767px) {
					border-width: 1px;
				}
			}
			&:nth-child(2) {
				border-left: #b2b2b2 solid 1px;
				@media (max-width: 767px) {
					border-right: none;
				}
			}
			&.is-active {
				background: #fff;
			}
		}
	}
	&__panel {
		&-item {
			display: none;
			&.is-active {
				display: block;
			}
			.topBox {
				width: auto!important;
				margin: 0!important;
			}
			.rBox {
				float: none!important;
				width: auto!important;
				padding-right: 0!important;
			}
			.sizeTable {
				margin-right: 0!important;
			}
			.rLink {
				padding: 0!important;
				border: none!important;
				margin-bottom: 30px!important;
			}
		}
	}
	&__contents {
		max-width: 682px;
		margin: auto;
		padding: 0 16px;
		box-sizing: border-box;
		&-title {
			font-size: 16px;
			background: #ededed;
			margin: 30px 0 21px;
			padding: 2px 16px;
			@media (max-width: 767px) {
				font-size: 14px;
				padding: 3px 8px;
				margin: 20px 0 15px;
			}
		}
		&-text {
			font-size: 16px;
			line-height: (24 / 16);
			margin: 10px 0;
			@media (max-width: 767px) {
				font-size: 14px;
				line-height: (40 / 28);
			}
		}
	}
	&__def {
		padding: 40px 0 44px;
		@media (max-width: 767px) {
			padding: 24px 0 29px;
		}
		&-term {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.4;
			margin: 0 0 23px;
			@media (max-width: 767px) {
				font-size: 17px;
				margin: 0 0 8px;
			}
		}
		&-desc {
			font-size: 16px;
			line-height: (24/16);
			@media (max-width: 767px) {
				font-size: 14px;
				line-height: (40/28);
			}
		}
	}
}
.ss-related-product {
	&__title {
		font-family: 'Hiragino Kaku Gothic ProN';
		font-size: 20px;
		line-height: 1.2;
		background: #eee3d6;
		max-width: 750px;
		box-sizing: border-box;
		padding: 8px 50px;
		margin: 58px auto 0;
		@media (max-width: 767px) {
			margin: 44px -25px 0;
			font-size: 17px;
			font-weight: bold;
			padding: 15px 25px;
		}
	}
	&__actions {
		max-width: 460px;
		margin: auto;
	}
	&__link {
	    display: block;
		font-family: 'Hiragino Kaku Gothic ProN';
		padding: 14px 50px;
	    font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
	    text-align: center;
	    border: 4px solid #FF8B07;
		border-radius: 6px;
	    position: relative;
		background: #fff;
		@media (max-width: 767px) {
			font-size: 15px;
			border-radius: 2px;
			border-width: 2px;
		}
		&:visited,
		&:link {
			color: #ff8b06;
		}
		&:hover {
			opacity: 0.7;
		}
		&:after {
			content: "";
			display: block;
			width: 11px;
			height: 18px;
			background: url('../../img/detail/ico-arrow-orange.png') no-repeat center;
			position: absolute;
			top: 50%;
			right: 21px;
			transform: translateY(-50%);
			@media (max-width: 767px) {
				background: url('../../img/detail/ico-arrow-orange-sp.png') no-repeat center;
				background-size: contain;
				width: 8.5px;
				height: 15px;
			}
		}
	}
	.comProduct {
		margin-top: 36px;
		margin-bottom: 43px;
		@media (max-width: 767px) {
			margin-top: 30px;
			margin-bottom: 24px;
		}
		h2 {
			font-family: 'Hiragino Kaku Gothic ProN';
			font-weight: bold;
			padding-bottom: 10px;
			margin-bottom: 32px;
			font-size: 18px;
			@media (max-width: 767px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				border: none;
				border: none;
				padding-bottom: 0px;
				margin-bottom: 18px;
				font-size: 17px;
				&:after {
					content: "";
					display: block;
					width: 330px;
					height: 14px;
					background: url('../../img/top/line_title_sp.png') no-repeat center;
					background-size: contain;
				}
			}
		}
	}
}
.ss-about-product {
	&__title {
		font-family: 'Hiragino Kaku Gothic ProN';
		font-size: 20px;
		line-height: 1.2;
		background: #eee3d6;
		max-width: 750px;
		box-sizing: border-box;
		padding: 8px 50px;
		margin: 58px auto 0;
		@media (max-width: 767px) {
			margin: 44px -25px 0;
			font-size: 17px;
			font-weight: bold;
			padding: 15px 25px;
		}
	}
	&__contents {}
	&__movie {
		max-width: 750px;
		box-sizing: border-box;
		margin: 0 auto 0;
		&-description {
			font-family: 'Hiragino Kaku Gothic ProN';
			font-size: 18px;
			text-align: center;
			margin: 33px 0 0;
			@media (max-width: 767px) {
				font-size: 17px;
				font-weight: bold;
			}
		}
		&-contents {
			max-width: 540px;
			margin: 15px auto 0;
			@media (max-width: 767px) {
				margin-top: 11px;
			}
			&-frame {
				position: relative;
				width: 100%;
				padding-top: 56.25%;
				iframe {
					position: absolute;
					top: 0;
					right: 0;
					width: 100% !important;
					height: 100% !important;
				}
			}
		}
	}
	&__accordion {
		&-actions {
			.ss-about-product__accordion.comAccordionJs & {
				width: 100%;
				max-width: 460px;
				margin: 0 auto;
			}
		}
		&-link {
			.ss-about-product__accordion .ss-about-product__accordion-actions & {
				display: block;
				font-family: 'Hiragino Kaku Gothic ProN';
				padding: 14px 50px;
			    font-size: 20px;
				font-weight: bold;
				line-height: 1.2;
			    text-align: center;
			    border: 4px solid #FF8B07;
				border-radius: 6px;
				position: relative;
				background: #fff;
				@media (max-width: 767px) {
					font-size: 15px;
					border-radius: 2px;
					border-width: 2px;
				}
				&:visited,
				&:link {
					color: #ff8b06;
				}
				&:hover {
					opacity: 0.7;
				}
				&:after {
					content: "";
					display: block;
					width: 11px;
					height: 18px;
					background: url('../../img/detail/ico-arrow-orange.png') no-repeat center;
					position: absolute;
					top: 50%;
					right: 21px;
					transform: translateY(-50%) rotate(90deg);
					@media (max-width: 767px) {
						background: url('../../img/detail/ico-arrow-orange-sp.png') no-repeat center;
						background-size: contain;
						width: 8.5px;
						height: 15px;
					}
				}
				&.on {
					background: #fff;
					&:after {
						transform: translateY(-50%) rotate(-90deg);
					}
				}
			}
		}
	}
}
.ss-product-detail__cv {
	&-actions {
		margin: 30px 0 0;
		&-item {
			margin: 0 auto;
			width: 100%;
			max-width: 460px;
		}
	}
	&-link {
		font-family: 'Hiragino Kaku Gothic ProN';
		padding: 10px 50px;
		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
		text-align: center;
		border: 4px solid #FF8B07;
		border-radius: 6px;
		position: relative;
		background: #FF8B07;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 767px) {
			font-size: 15px;
			border-radius: 2px;
			border-width: 2px;
		}
		&:visited,
		&:link {
			color: #fff;
		}
		&:hover {
			opacity: 0.7;
		}
		&:before {
			content: "";
			display: block;
			width: 31px;
			height: 32px;
			background: url('../../img/detail/ico-cart.png') no-repeat center;
			margin: 0 20px 0 -30px;
			@media (max-width: 767px) {
				background-size: contain;
				width: 24.5px;
				height: 25.5px;
				margin: 0 10px 0 -15px
			}
		}
		&:after {
			content: "";
			display: block;
			width: 19px;
			height: 12px;
			background: url('../../img/detail/ico-arrow-white.png') no-repeat center;
			position: absolute;
			top: 50%;
			right: 21px;
			transform: translateY(-50%);
			@media (max-width: 767px) {
				background: url('../../img/detail/ico-arrow-white-sp.png') no-repeat center;
				background-size: contain;
				width: 15px;
				height: 8.5px;
			}
		}
	}
}
.ss-recommend-product {
	.comProduct {
		margin-top: 92px;
		margin-bottom: 88px;
		@media (max-width: 767px) {
			margin-top: 56px;
			margin-bottom: 65px;
		}
		h2 {
			font-family: 'Hiragino Kaku Gothic ProN';
			font-weight: bold;
			padding-bottom: 8px;
			margin-bottom: 27px;
			font-size: 18px;
			@media (max-width: 767px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				border: none;
				border: none;
				padding-bottom: 0px;
				margin-bottom: 18px;
				font-size: 17px;
				&:after {
					content: "";
					display: block;
					width: 330px;
					height: 14px;
					background: url('../../img/top/line_title_sp.png') no-repeat center;
					background-size: contain;
				}
			}
		}
	}
}
.ss-product-slide {
	&__caption {
		font-family: 'Hiragino Kaku Gothic ProN';
		font-weight: bold;
		font-size: 16px;
		line-height: 1.4;
		text-align: center;
		@media (max-width: 767px) {
			font-size: 14px;
			#main .topBox .lBig & {
				margin-top: 10px;
			}
		}
	}
	&__counter {
		text-align: center;
		font-size: 16px;
		line-height: 1.2;
		margin-top: 6px;
		@media (max-width: 767px) {
			font-size: 14px;
			margin-top: 10px;
		}
	}
	#main .topBox & {
		.slick-dots {
			@media (max-width: 767px) {
				margin-top: 9px;
			}
		}
	}
}
.ss-line {
	border-top: 1px solid #cbcbcb;
	margin: 12px 0 24px;
}

//KAMO_RENEW3-313 カテゴリページごとに見出しを表示する

.ss-product-list {
	&__title {
		width: 100%;
		max-width: 960px;
		margin: 39px auto;
		padding: 0 20px;
		box-sizing: border-box;
		text-align: center;
		font-size: 2rem;
		line-height: 1.4;
		font-weight: bold;
		font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
		display: flex;
		flex-direction: column;
		align-items: center;
		&:after {
		    content: "";
		    display: block;
		    width: 422px;
		    height: 17px;
		    background: url(../../img/top/line_title.png) no-repeat center;
			@media (max-width: 767px) {
				width: 349.5px;
			    height: 14px;
			    background: url(../../img/top/line_title_sp.png) no-repeat center;
			    background-size: cover;
			}
		}
		@media (max-width: 767px) {
			font-size: 1.8rem;
			margin: 20px auto;
		}
	}
	&__product {
		&-photo-link {
			&:hover {
				opacity: 0.7;
			}
		}
		&-title-link {
			&:link:hover,
			&[href]:hover,
			&:hover {
				color: #000;
				opacity: 0.7;
			}
		}
	}
	&__actions {
		margin: 75px 0;
		@media (max-width: 767px) {
			margin: 40px 0;
		}
		&-button {
			display: block;
			font-size: 20px;
			line-height: 54px;
			width: 100%;
			max-width: 400px;
			height: 54px;
			margin: 0 auto;
			color: #000000;
			background: #eee3d6;
			text-align: center;
			border-radius: 27px;
			padding: 0 64px;
			box-sizing: border-box;
			position: relative;
			@media(max-width: 767px) {
				font-size: 17px;
				height: 45px;
				line-height: 45px;
				max-width: 275px;
				padding: 0 36px 0 36px;
			}
			&:visited,
			&:hover,
			&:link {
				color: #000000;
			}
			&:before {
				position: absolute;
				top: 50%;
				left: 44px;
				transform: scale(-1, 1) translateY(-50%);
				content: "";
				display: block;
				width: 10px;
				height: 17px;
				background: url('../../img/top/arrow-column-b.png') no-repeat center;
				@media(max-width: 767px) {
					width: 8px;
					height: 14px;
					left: 23px;
					background-size: cover;
				}
			}
		}
	}
}

//KAMO_RENEW3-332 KMS-649 【動線改善】検索機能修正と"最近チェックした商品"追加の実装
.list-search-zero {
	#main .comPhotoUl & {
		float: none;
		margin: 109px 0 0;
		width: auto;
		@media(max-width: 767px) {
			margin: 0;
		}
	}
}

.ss-onayami {
	#main & {
		margin-top: 55px;
		margin-bottom: 52px;
		@media(max-width: 767px) {
			margin-top: 42px;
		}
	}
	&__title {
		display: flex;
		flex-direction: column;
		align-items: center;
		#main & {
			border: none;
			padding: 0;
			margin-bottom: 17px;
			@media (max-width: 767px) {
				margin-bottom: 13px;
			}

		}
		&:after {
			content: "";
			display: block;
			width: 422px;
			height: 17px;
			background: url('../../img/top/line_title.png') no-repeat center;
			@media (max-width: 767px) {
				width: 349.5px;
				height: 14px;
				background: url('../../img/top/line_title_sp.png') no-repeat center;
				background-size: cover;
			}
		}
		&-text {
			font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0;
			@media (max-width: 767px) {
				flex-direction: row;
				letter-spacing: 0.04em;
				font-size: 20px;
				margin: 0 0 0 -15px;
			}
			&:before {
				content: "";
				display: block;
				width: 34px;
				height: 37px;
				background: url('../../img/top/ico_title_onayami_pc.png') no-repeat center;
				margin: 0 0 3px;
				transform: translateX(5px);
				@media (max-width: 767px) {
					width: 29.5px;
					height: 32px;
					margin: 0 11px 0 0;
					background: url('../../img/top/ico_title_onayami_sp.png') no-repeat center;
					background-size: cover;
					transform: translate(0px, -4px);
				}
			}
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		@media(max-width: 767px) {
			margin: 0 -4.25px;
		}
		&-item {
			width: calc(100% / 3);
			box-sizing: border-box;
			padding: 15px;
			@media(max-width: 767px) {
				width: calc(100% / 2);
				padding: 7.5px 4.25px;
			}
		}
	}
	&__link {
		display: flex;
		justify-content: center;
		background: #eee3d6;
		line-height: 50px;
		box-sizing: border-box;
		border-radius: 25px;
		font-size: 18px;
		position: relative;
		font-weight: bold;
		@media(max-width: 767px) {
			line-height: 40px;
			border-radius: 20px;
			font-size: 14px;
		}
		&:after {
			content: "";
			width: 8px;
			height: 8px;
			position: absolute;
			top: 50%;
			right: 23px;
			transform: translateY(-50%) rotate(-45deg);
			border-right: solid 2px #000;
			border-bottom: solid 2px #000;
			@media(max-width: 767px) {
				width: 6px;
				height: 6px;
				border-width: 1px;
				right: 12px;
			}
		}
		&:hover {
			opacity: 0.7;
		}
	}
}
*::-ms-backdrop, .ss-onayami__list-item {
	width: 33.333%;
	@media(max-width: 767px) {
		width: 50%;
	}
}
.ss-checked-items {
	&.is-cart {
		margin-top: -100px;
		@media (max-width: 767px) {
			margin-top: -20px;
		}
	}
}

//KAMO_RENEW3-342 [KMS-686] コーポレートサイトのリニューアルのリンク追加
.ss-footer {
	&__logo-wrap {
		position: relative;
	}
	&__privacy-mark {
		#gFooter .fBox & {
			left: auto;
		    right: 50%;
		    bottom: -22px;
		    margin-right: 219px;
			@media(max-width: 767px) {
				width: 68px;
			    bottom: -17px;
			    right: 50%;
			    left: auto;
			    margin-right: 89px;
			}
		}
	}
	&__corporate {
		&-logo {
			width: 210px;
		    position: absolute;
		    bottom: 0;
		    left: 50%;
		    margin-left: 165px;
			@media(max-width: 767px) {
				width: 50px;
				margin-left: 95px;
			}
		}
		&-link {
			display: block;
		}
	}
}

//KAMO_RENEW3-348 KMS-730 お悩み検索のお悩みのワードの文字を大きくしてください
.ss-onayami {
	&__link {
		line-height: 1;
		background: transparent;
		@media(max-width: 767px) {
			line-height: 1;
		}
		&:after {
			display: none;
		}
	}
}

#gFooter.ss-footer .ss-footer__page-top {
	bottom: 90px;
	@media(max-width: 767px) {
		bottom: auto;
	}
}

//KAMO_RENEW3-360 KMS-770 【商品検索改修】お悩みから探すを設置
.ss-search-products {
	&__actions {
		margin-left: 153px;
		@media(max-width: 767px) {
			margin-left: 0;
		}
	}
	&__search-word {
		&-list {
			margin: 29px 0 0;
			@media(max-width: 767px) {
				margin: 25px 0 0;
			}
			&-item {
				overflow: hidden;
			}
		}
		&-label {
			#gHeader .searchBox li & {
				vertical-align: top;
				font-size: 1.4rem;
				padding: 5px 0 0;
				@media(max-width: 767px) {
					padding: 0;
					margin: 0 0 9px;
				}
			}
		}
		&-actions {
			#gHeader .searchBox li & {
				display: flex;
				flex-wrap: wrap;
				margin: -3.25px;
				@media(max-width: 767px) {
					margin: -6.25px -3.25px;
				}
			}
			&-button {
				font-size: 1.4rem;
				margin: 3.25px;
				height: 30px;
				padding: 0 12px;
				line-height: 28px;
				background: #ededeb;
				border: solid 1px #dcdcdc;
				box-sizing: border-box;
				border-radius: 15px;
				cursor: pointer;
				@media(max-width: 767px) {
					font-size: 1.8rem;
					margin: 6.25px 3.25px;
					height: 36px;
					line-height: 34px;
					border-radius: 18px;
				}
				&:hover {
					color: #fff;
					border: solid 1px #595455;
					background: #595455;
				}
			}
		}
	}
}

//KAMO_RENEW3-364 KMS-794 【商品ページ】修正相談
.ss-about-product {
	&__accordion {
		&-actions {
			.ss-about-product__accordion.comAccordionJs & {
				max-width: 650px;
			}
		}
		&-link {
			.ss-about-product__accordion .ss-about-product__accordion-actions & {
				&:visited,
				&:link {
					color: #000;
				}
				&:after {
					right: 74px;
					background-image: url('../../img/detail/ico-arrow-black.png');
					@media (max-width: 767px) {
						right: 21px;
						background-image: url('../../img/detail/ico-arrow-black-sp.png');
					}
				}
			}
		}
	}
}
.ss-product-detail {
	&__cv {
		&-actions {
			margin: 0 0 30px;
			&-item {
				max-width: 650px;
			}
		}
		&-link {
			&:after {
				right: 70px;
				@media (max-width: 767px) {
					right: 18px;
				}
			}
		}
	}
}

//KAMO_RENEW3-370 KMS-810 商品カテゴリページの見直しについて
.ss-category {
	max-width: none;
	margin-bottom: 45px;
	&__title {
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 767px) {
			margin-top: 14px;
		}
		&-text {
			line-height: 1.2;
			font-size: 20px;
		}
		&-icon {
			width: 28px;
			margin-left: 10px;
			&-pic {
				transform: translateY(-1px);
			}
		}
	}
	&__contents {
		&-inner {
			max-width: 780px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__tab {
		margin: 50px -15px 0;
		padding-left: 15px;
		padding-right: 15px;
		position: relative;
		@media (max-width: 767px) {
			margin: 25px -24px 0;
			padding-left: 4px;
			padding-right: 4px;
		}
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			border-bottom: solid 1px #c1a35e;
			position: absolute;
			left: 0;
			z-index: -1;
			.ss-category__heading & {
				bottom: 0;
			}
			.ss-category__footer & {
				top: -1px;
			}
		}
		&-contents {
			max-width: 960px;
			margin-left: auto;
			margin-right: auto;
			overflow: hidden;
		}
		&-list {
			display: flex;
			align-items: flex-end;
			margin: 0 -5px;
			@media (max-width: 767px) {
				margin: 0 -1px;
			}
			.ss-category__heading & {
				align-items: flex-end;
			}
			.ss-category__footer & {
				align-items: flex-start;
			}
			&-item {
				width: calc(100% / 3);
				padding: 0 5px;
				box-sizing: border-box;
				@media (max-width: 767px) {
					width: 30%;
					padding: 0 1px;
				}
				&.is-active {
					@media (max-width: 767px) {
						width: 40%;
					}
				}
			}
		}
		&-link {
			display: block;
			border: solid 1px #c1a35e;
			background: #fff;
			padding: 10px 5px;
			@media (max-width: 767px) {
				.ss-category__heading & {
					border-radius: 6px 6px 0 0;
				}
				.ss-category__footer & {
					border-radius: 0 0 6px 6px;
				}
			}
			&:link,
			&[href] {
				color: #92672e;
				&:hover {
					color: #000;
					background: #EEE3D6;
				}
				@media (max-width: 767px) {
					color: #92672e;
					background: #EEE3D6;
				}
			}
			.is-active & {
				background: #EEE3D6;
				cursor: default;
				pointer-events: none;
				@media (max-width: 767px) {
					padding: 14px 5px;
					background: #fff;
				}
				&:link,
				&[href] {
					color: #000;
				}
			}

		}
		&-label {
			display: flex;
			flex-direction: column;
			align-items: center;
			line-height: 1.3;
			font-weight: bold;
			&--main {
				font-size: 14px;
				white-space: nowrap;
				@media (max-width: 767px) {
					font-size: 12px;
				}
			}
			&--sub {
				font-size: 12px;
				white-space: nowrap;
				@media (max-width: 767px) {
					font-size: 10px;
				}
			}
			.ss-category__heading & {
				&::after {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 5px 0 5px;
					border-color: #92672e transparent transparent transparent;
					margin-top: 5px;
					@media (max-width: 767px) {
						display: none;
					}
				}
			}
			.ss-category__heading .is-active &,
			.ss-category__heading .ss-category__tab-link:hover & {
				&::after {
					border-color: #000 transparent transparent transparent;
				}
			}
			.ss-category__footer & {
				&::before {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 5px 8px 5px;
					border-color: transparent transparent #92672e transparent;
					margin-bottom: 5px;
					@media (max-width: 767px) {
						display: none;
					}
				}
			}
			.ss-category__footer .is-active &,
			.ss-category__footer .ss-category__tab-link:hover & {
				&::before {
					border-color: transparent transparent #000000 transparent;
				}
			}
		}
	}
	&__footer {
		display: none;
		@media (max-width: 767px) {
			display: block;
		}
	}
	&__search {
		max-width: 960px;
		margin: auto;
		&-description {
			text-align: center;
			font-size: 20px;
			margin: 40px 0;
			@media (max-width: 767px) {
				font-size: 18px;
				margin: 20px 0;
			}
			br {
				display: none;
				@media (max-width: 767px) {
					display: inline;
				}
			}
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@media (max-width: 767px) {
				display: block;
			}
			&-item {
				flex-shrink: 0;
				display: flex;
				width: (470 / 960) * 100%;
				margin: 10px 0;
				@media (max-width: 767px) {
					width: auto;
					margin: 10px -24px;
					box-sizing: border-box;
				}
			}
		}
		&-product {
			flex-grow: 1;
			background: #EEE3D6;
			position: relative;
			z-index: 1;
			&::before {
				content: "";
				display: block;
				width: 12px;
				height: 12px;
				border-right: solid 1px #000;
				border-bottom: solid 1px #000;
				position: absolute;
				top: 50%;
				right: 16px;
				z-index: 2;
				transform: translateY(-50%) rotate(-45deg);
			}
			&-link {
				display: block;
				padding: 14px 24px 24px;
			}
			&-title {
				#main & {
					display: flex;
					align-items: baseline;
					justify-content: center;
					padding: 0;
				}
				&--main {
					font-size: 20px;
					font-weight: bold;
					@media (max-width: 767px) {
						font-size: 18px;
					}
				}
				&--sub {
					font-size: 18px;
					font-weight: normal;
					@media (max-width: 767px) {
						font-size: 16px;
					}
				}
			}
			&-contents {
				display: flex;
				align-items: center;
				margin: 14px auto 0;
				@media (max-width: 767px) {
					max-width: 327px;
				}
			}
			&-image {
				width: 120px;
				flex-shrink: 0;
			}
			&-detail {
				flex-grow: 1;
				padding: 0 14px;
				box-sizing: border-box;
			}
			&-list {
				&-item {
					font-size: 16px;
					line-height: 1.5;
					padding: 2px 0;
					display: flex;
					align-items: flex-start;
					@media (max-width: 767px) {
						font-size: 14px;
					}
					&::before {
						content: "・";
					}
				}
			}
		}
	}

	.ss-product-list__title {
		#main & {
			margin-bottom: 39px;
			@media (max-width: 767px) {
				font-size: 1.8rem;
				margin: 40px auto;
				padding: 0;
			}
		}
	}
}

//KAMO_RENEW3-380 KMS-810 【追加要望】商品カテゴリページの見直しについて
.ss-category {
	&__tab {
		&-list {
			&-item {
				@media (max-width: 767px) {
					width: 32%;
				}
				&.is-active {
					@media (max-width: 767px) {
						width: 36%;
					}
				}
			}
		}
		&-label {
			line-height: 1.4;
			&--main {
				font-size: 15px;
				@media (max-width: 767px) {
					font-size: 14px;
				}
			}
			&--sub {
				font-size: 15px;
				@media (max-width: 767px) {
					font-size: 14px;
				}
			}
		}
	}
	&__search {
		&-product {
			&-title {
				&--main {
					@media (max-width: 767px) {
						font-size: 18px;
					}
				}
				&--sub {
					@media (max-width: 767px) {
						font-size: 18px;
					}
				}
			}
			&-list {
				&-item {
					@media (max-width: 767px) {
						font-size: 18px;
					}
				}
			}
		}
	}
}

//KAMO_RENEW3-372 KMS-817 カート改善のご相談
.ss-shopping-login {
	&__heading {
		margin: 0 auto 20px;
    width: 900px;
		overflow: hidden;
		&-item {
			text-align: center;
			&:first-child {
				float: left;
				width: 444px;
			}
			&:last-child {
				float: right;
				width: 444px;
			}
		}
		&-text {
			font-weight: bold;
			font-size: 18px;
		}
	}
	&__scroll {
		margin-top: 8px;
		&-body {
			#main .comScroll & {
				height: 140px;
				padding: 16px;
				margin: 0 0 8px;
				@media (max-width: 767px) {
					height: 300px;
				}
			}
		}
		&-title {
			#main .comLoginBox .rBox & {
				font-size: 1.4rem;
				text-align: center;
				border: none;
			}
		}
	}
}
.ss-form__title {
	&-elective {
		align-self: flex-start;
		padding: 0 11px 1px;
		float: right;
		font-size: 1.2rem;
		font-weight: normal;
		background-color: #ddd;
		line-height: 28px;
    margin: 0 10px 0 0;
		display: inline-block;
		white-space: nowrap;
		@media (max-width: 767px) {
			padding: 0 11px;
		}
	}
}
.ss-shopping-deliv {
	margin-bottom: 66px;
	&__title {
		#main .step2 & {
			margin-bottom: 43px;
			padding: 18px 0;
			font-size: 2.5rem;
			text-align: center;
			font-weight: normal;
			background-color: #F4EFEB;
			@media (max-width: 767px) {
				padding: 15px 0 16px;
				font-size: 2.0rem;
			}
		}
		position: relative;
		&::after {
			content: "";
			display: block;
			width: 100vw;
			height: 100%;
			background: #F4EFEB;
			position: absolute;
			top: 0;
			left: 50%;
			z-index: -1;
			transform: translateX(-50%);
		}
		&-text {
			display: inline-block;
			position: relative;
		}
	}
	&__address {
		@media (max-width: 767px) {
			margin: 0 0px 15px;
		}
	}
	&__notes {
		font-size: 16px;
    line-height: 1.84;
		margin-bottom: 8px;
		@media (max-width: 767px) {
			text-align: center;
		}
	}
	&__add {
		@media (max-width: 767px) {
			text-align: center;
			font-size: 16px;
		}
		&-link {
			padding: 0 0 0 24px;
			display: inline-block;
			background: url(../../img/common/icon14.png) no-repeat left top 4px;
			background-size: 16px 16px;
		}
	}
	&__accordion {
		display: none;
		margin-top: 20px;
		#main .step2 & {
			.ss-shopping-payment__title {
				margin-bottom: 16px;
			}
		}
	}
	&__actions {
		margin-top: 40px;
		#main .step2 & {
			margin-bottom: 60px;
		}
	}
}
.ss-shopping-complete {
	&__navi {
		overflow: hidden;
		margin: 40px 0 60px;
		padding: 0 20px;
		@media (max-width: 767px) {
			padding: 0 16px;
		}
		&-list {
			display: flex;
			margin: -10px;
			justify-content: center;
			flex-wrap: wrap;
			@media (max-width: 767px) {
				margin: -5px;
			}
			&-item {
				margin: 10px;
				width: 100%;
				max-width: 220px;
				@media (max-width: 767px) {
					margin: 5px;
					width: auto;
					max-width: none;
				}
			}
		}
		&-link {
			display: block;
			border: solid 1px #f28e1e;
			line-height: 48px;
			border-radius: 24px;
			box-sizing: border-box;
			font-weight: bold;
			padding: 0 24px;
			position: relative;
			text-align: center;
			background-color: #fff;
			@media (max-width: 767px) {
				line-height: 40px;
				border-radius: 20px;
			}
			&:link {
				color: #f28e1e;
			}
			&:hover {
				background-color: #f7f7f7;
			}
			&::after {
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				border: 1px solid;
				border-color: #f28e1e #f28e1e transparent transparent;
				position: absolute;
				top: 50%;
				right: 16px;
				transform: translateY(-50%) rotate(45deg);
				@media (max-width: 767px) {
					right: 13px;
				}
			}
			&-text {
				color: #f28e1e;
				display: block;
			}
		}
	}
}
.ss-amazon-pay {
	&__info {
		padding: 0 0 20px;
		&--main {
			font-size: 18px;
			color: #f5000d;
		}
		&--sub {
			margin: 10px 0 0;
		}
	}
}
.ss-payment {
	&__info {
		padding: 0 0 20px;
		@media (max-width: 767px) {
			padding: 0 20px 20px;
		}
		&--main {
			font-size: 18px;
			color: #f5000d;
		}
		&--sub {
			margin: 10px 0 0;
		}
	}
}

//KAMO_RENEW3-411 KMS-917 No22.お支払方法選択画面のラジオボタン変更
#main .step2 .tableBox th input[type="radio"] + label {
	background-image: url("../../img/common/radio_img03.png?20211129");
}

#main .step2 .tableBox th input[type="radio"]:checked + label {
	background-image: url("../../img/common/radio_img05.png?20211129");
}

//KAMO_RENEW3-425 【要件変更】パスワードの入力方法について
.ss-form {
	&__password {
		position: relative;
    max-width: 388px;
		&-input {}
		&-actions {
			position: absolute;
			top: 9px;
			right: 7px;
			.is-present & {
				top: 5px;
			}
			@media (max-width: 767px) {
				top: 15px;
				.is-present & {
					top: 12px;
				}
			}
			cursor: pointer;
			&-item {
				&--on {
					display: none;
					.is-hidden & {
						display: block;
					}
				}
				&--off {
					display: block;
					.is-hidden & {
						display: none;
					}
				}
			}
			svg {
				vertical-align: top;
			}
		}
	}
}

//KAMO_RENEW3-434 【カート改善】先方指摘
.ss-shopping-payment__method {
	&-text {
		&-break {
			&--pc {
				display: inline;
				@media (max-width: 767px) {
					display: none;
				}
			}
		}
	}
}
.ss-shopping__confirm-summary {
	#main .step3 .topBox & {
		width: 240px;
		@media (max-width: 767px) {
			width: 100%;
		}
	}
	&-num {
		#main .step3 .topBox & {
			white-space: nowrap;
		}
	}
}
span.ss-shopping__confirm-summary {
	&-num {
		#main .step3 .topBox .ss-shopping__confirm-summary & {
			width: 130px;
			@media (max-width: 767px) {
				width: 120px;
			}
		}
	}
}
.ss-shopping-complete {
	&__title {
		white-space: nowrap;
		@media (max-width: 374px) {
			font-size: 16px;
		}
	}
}
.ss-shopping-payment__customer-regist-link {
	&:link {
		text-decoration: underline;
	}
	&:hover {
		text-decoration: none;
	}
}
.ss-amazon-pay__form-list {
	display: flex;
	align-items: center;
	@media (max-width: 767px) {
		margin: 0 -5px;
	}
	&-item {
		margin-right: 27px;
    display: inline-block;
		@media (max-width: 767px) {
			display: flex;
			margin: 0 5px;
		}
		span {
			min-width: 44px;
			display: inline-block;
			@media (max-width: 767px) {
				font-size: 1.5rem;
				width: auto;
				min-width: 44px;
				padding-top: 12px;
			}
		}
		input[type="text"] {
			width: 100%;
			max-width: 147px;
			padding: 2px 8px;
			height: 35px;
			font-size: 1.4rem;
			box-sizing: border-box;
			appearance: none;
			-webkit-appearance: none;
			border: 1px solid #C4C4C3;
			border-radius: 0;
			@media (max-width: 767px) {
				width: 85%;
				padding: 2px 12px;
				height: 46px;
				font-size: 1.25rem;
			}
		}
	}
}
.ss-amazon-pay__form-zip {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: -5px;
	&.mb-10 {
		margin-bottom: 10px;
	}
	&-item {
		padding: 5px;
		box-sizing: border-box;
		input[type="text"] {
			width: 100%;
			max-width: 60px;
			padding: 2px 8px;
			height: 35px;
			font-size: 1.4rem;
			box-sizing: border-box;
			appearance: none;
			-webkit-appearance: none;
			border: 1px solid #C4C4C3;
			border-radius: 0;
			@media (max-width: 767px) {
				padding: 2px 12px;
				height: 46px;
				font-size: 1.25rem;
			}
		}
	}
}
.ss-amazon-pay__form-address {
	margin: -5px 0;
	&-item {
		padding: 5px 0;
		select {
			padding-left: 8px;
			width: 150px;
			height: 35px;
			box-sizing: border-box;
			font-size: 1.4rem;
			border: 1px solid #C4C4C3;
			border-radius: 5px;
			@media (max-width: 767px) {
				height: 46px;
			}
		}
		input[type="text"] {
			width: 100%;
			max-width: 320px;
			padding: 2px 8px;
			height: 35px;
			font-size: 1.4rem;
			box-sizing: border-box;
			appearance: none;
			-webkit-appearance: none;
			border: 1px solid #C4C4C3;
			border-radius: 0;
			@media (max-width: 767px) {
				padding: 2px 12px;
				height: 46px;
				font-size: 1.25rem;
			}
		}
	}
}
.ss-amazon-pay__form-tel {
	display: flex;
	align-items: center;
	&-item {
		input[type="text"] {
			width: 100%;
			max-width: 100%;
			padding: 2px 8px;
			height: 35px;
			font-size: 1.4rem;
			box-sizing: border-box;
			appearance: none;
			-webkit-appearance: none;
			border: 1px solid #C4C4C3;
			border-radius: 0;
			@media (max-width: 767px) {
				padding: 2px 12px;
				height: 46px;
				font-size: 1.25rem;
			}
		}
	}
}//KAMO_RENEW3-409 KMS-916 【11月月次】カート内離脱防止ポップアップ表示
.ss-withdrawal {
	&__modal {
		color: #4d4d4d;
		font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
		.modaal-inner-wrapper {
			padding-left: 17px;
			padding-right: 17px;
		}
		.modaal-container {
			color: inherit;
		}
		.modaal-close {
			position: absolute;
			top: -30px;
			right: 0;
			width: 30px;
			height: 30px;
			background: #000;
			border-radius: 0;
			@media (max-width: 767px) {
				top: -35px;
				width: 35px;
				height: 35px;
			}
			&::before,
			&::after {
				width: 1px;
				height: 20px;
				border-radius: 0;
				top: 5px;
				left: 15px;
				background: rgba(#fff, 1.0);
				@media (max-width: 767px) {
					height: 24px;
					top: 6px;
					left: 17px;
				}
			}
			&:hover {
				&::before,
				&::after {
					background: rgba(#fff, 0.8);
				}
			}
		}
		.modaal-content-container {
			padding: 40px;
			@media (max-width: 767px) {
				padding: 30px;
			}
			@media (max-width: 374px) {
				padding: 20px;
			}
		}
		&-body {
			@media (max-width: 767px) {
				width: 100%;
				max-width: 280px;
				margin: auto;
			}
		}
	}
	&__contents {
		&-item {
			border: solid 1px #53b06d;
			padding: 35px 25px 33px;
			margin: 0 0 20px;
			@media (max-width: 767px) {
				padding: 22px 29px 24px;
				margin: 0 0 10px;
			}
			@media (max-width: 374px) {
				padding: 22px 10px 24px;
			}
		}
	}
	&__def {
		display: flex;
    flex-direction: column;
    align-items: center;
		&-term {
			font-size: 26px;
			font-weight: bold;
			line-height: (54/44);
			letter-spacing: 0.04em;
			text-align: center;
			@media (max-width: 767px) {
				font-size: 22px;
				font-feature-settings: 'palt';
				letter-spacing: 0;
			}
		}
		&-desc {
			margin: 16px 0 0;
		}
	}
	&__break {
		&--sp {
			display: none;
			@media (max-width: 767px) {
				display: inline;
			}
		}
		&--pc {
			display: inline;
			@media (max-width: 767px) {
				display: none;
			}
		}
	}
	&__list {
		font-size: 16px;
		@media (max-width: 767px) {
			font-size: 14px;
		}
		&-item {
			display: flex;
			font-size: inherit;
			line-height: (30/16);
			@media (max-width: 767px) {
				line-height: (44/28);
			}
			&::before {
				content: "※";
			}
		}
	}
	&__text {
		font-size: inherit;
		line-height: inherit;
		font-feature-settings: 'palt';
		letter-spacing: 0.075em;
		@media (max-width: 767px) {
			letter-spacing: 0;
		}
		&--em {
			font-style: normal;
			font-weight: bold;
		}
		&-link {
			color: #4d4d4d;
			&:-webkit-any-link {
				color: #4d4d4d;
			}
			&:link {
				color: #4d4d4d;
			}
			&:link {
				text-decoration: underline;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
	&__info {
		margin: 20px -10px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 767px) {
			flex-wrap: wrap;
		}
		&-image {
			flex-shrink: 0;
		}
		&-text {
			margin: 0 0 0 5px;
			font-size: 16px;
			letter-spacing: 0.04em;
			font-feature-settings: 'palt';
			@media (max-width: 767px) {
				font-size: 14px;
				letter-spacing: 0;
				margin: 5px 0 0;
			}
		}
	}
	&__actions {
		&-item {
			margin: 40px 0 0;
			text-align: center;
			@media (max-width: 767px) {
				margin: 35px 0 0;
			}
		}
		&-button {
			width: 100%;
			max-width: 400px;
			height: 60px;
			margin: 0 auto;
			border: none;
			border-radius: 30px;
			background: #53b06d;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 30px;
			font-family: inherit;
			cursor: pointer;
			@media (max-width: 767px) {
				max-width: 280px;
				height: 50px;
				border-radius: 25px;
				padding: 0 15px;
			}
			&-text {
				color: #fff;
				font-size: 20px;
				font-weight: bold;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				font-feature-settings: 'palt';
				letter-spacing: 0.05em;
				@media (max-width: 767px) {
					font-size: 16px;
					letter-spacing: 0.03em;
				}
			}
		}
		&-link {
			color: #4d4d4d;
			font-size: 16px;
			font-weight: bold;
			@media (max-width: 767px) {
				font-size: 14px;
			}
			&:-webkit-any-link {
				color: #4d4d4d;
			}
			&:link {
				color: #4d4d4d;
			}
			&:link {
				text-decoration: underline;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
}

//KAMO_RENEW3-428 KMS-943 【12月月次】再購入施策

.ss-repeat__modal{
	.modaal-close {
		position: absolute;
		top: -30px;
		right: 0;
		width: 30px;
		height: 30px;
		background: #000;
		border-radius: 0;
		@media (max-width: 767px) {
			top: -35px;
			width: 35px;
			height: 35px;
		}
		&::before,
		&::after {
			width: 1px;
			height: 20px;
			border-radius: 0;
			top: 5px;
			left: 15px;
			background: rgba(#fff, 1.0);
			@media (max-width: 767px) {
				height: 24px;
				top: 6px;
				left: 17px;
			}
		}
		&:hover {
			&::before,
			&::after {
				background: rgba(#fff, 0.8);
			}
		}
	}
	.modaal-content-container{
		padding: 0;
	}
}

.ss-repeat{
	&__break{
		&--sp{
			display: none;
			@media (max-width: 767px) {
			display: block;
			}
		}
	}
	&__titlebox{
		background-color: #14a83c;
		color: #fff;
		padding: 5px 0;
		text-align: center;
		font-size: 18px;
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
	&__contents{
		 padding: 20px;
		 margin: 20px;
		 border: 2px solid #C8C8C7;
		 @media (max-width: 767px) {
			padding: 15px;
			margin: 15px;
		}
		&-item{
			display: flex;
			justify-content: space-between;margin-bottom: 15px;
			&_img{
				width: 32.5%;
			}
			&_textbox{
				width: 64%;
			}
			&_name{
				margin-bottom: 5px;
				display: block;
				font-size: 1.8rem;
				font-weight: bold;
				color: #040000;
				font-family: 'Hiragino Kaku Gothic ProN';
				@media (max-width: 767px) {
					font-size: 1.4rem;
				}
			}
			&_pricetext , &_priceinctaxtext{
				font-size: 1.4rem;
				display: flex;
				align-items: flex-end;
				@media (max-width: 767px) {
					font-size: 1.2rem;
				}
			}
			&_price , &_priceinctax{
				font-size: 1.8rem;
				font-weight: bold;
				color: #040000;
				padding:  0 5px;
				@media (max-width: 767px) {
					font-size: 1.4rem;
				}
			}


		}
		&-select{
			margin-bottom: 20px;
			@media (max-width: 767px) {
				margin-bottom: 10px;
			}
			&_text{
				padding-bottom: 10px;
				text-align: left;
				vertical-align: middle;
				font-size: 1.6rem;
				display: block;
				@media (max-width: 767px) {
					font-size: 1.3rem;
				}
			}
			&_box{
				width: 175px;
				height: 45px;
				padding-left: 10px;
				border: 1px solid #B9B9B9;
				border-radius: 5px;
				font-size: 1.6rem;
				font-weight: bold;
				box-sizing: border-box;
				margin-left: 20px;
				@media (max-width: 767px) {
					margin-left: 15px;
					width: calc(100% - 58px);
				}
				&.ml{
					margin-left: 36px;
					@media (max-width: 767px) {
						margin-left: 28px;
					}
				}
			}
		}
	}
	&__actions{
		@media (max-width: 767px) {
			padding-bottom: 10px;
		}
		&-button{
			background: none;
			border: none;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			width: 259px;
			padding: 15px 5px;
			display: block;
			color: #fff;
			font-size: 1.8rem;
			font-weight: bold;
			border-radius: 5px;
			background-color: #dc143c;
			box-sizing: border-box;
			margin: 0 auto;
			cursor: pointer;
			@media (max-width: 767px) {
				font-size: 1.4rem;
				padding: 10px 5px;
			}
		}

		&-item{
			text-align: center;
			padding-bottom: 10px;
			@media (max-width: 767px) {
				padding-bottom: 0px;
			}
		}
		&-link {
			text-decoration: underline !important;
		}
	}
}

.ss-mypage{
	&__repurchase{
		&-btn{
			display: flex;
			max-width: 110px;
			margin-top: 8px;
			@media (max-width: 767px) {
				justify-content: center;
				margin: 5px auto 0;
				max-width: 100%;
			}
			&_link{
				background-color: #FF8B07;
				color: #fff !important;
				line-height: 1;
				padding: 10px 10px;
				display: block;
				width: 100%;
				text-align: center;
				&:hover{
					opacity: .8;
				}
				@media (max-width: 767px) {
					padding: 8px 10px;
					&:hover{
						opacity: 1;
					}
				}
			}
		}
		&-name{
			&_link{
				color: #221714;
				margin-bottom: 4px;
				display: block;

			}
		}
	}
}

//KAMO_RENEW3-428 KMS-942 【12月月次】クレジットカード利用 不安払拭施策
.ss-credit {
	&__info {
		&-text {
			&--security {
				@extend .ss-credit__info-text;
				margin: 16px 0;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex-wrap: wrap;
			}
			&-link {
				&:link {
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}
.ss-withdrawal {
	&__def {
		&-term {
			line-height: (39.06/26);
			@media (max-width: 767px) {
				line-height: (54/44);
			}
		}
	}
	&__description {
		font-size: 16px;
		line-height: (30/16);
		margin: 4px auto 0;
    max-width: 430px;
		@media (max-width: 767px) {
			font-size: 14px;
			line-height: (44/28);
		}
		.ss-withdrawal__text {
			&:first-child {
				margin-bottom: (30/16) + em;
				@media (max-width: 767px) {
					margin-bottom: (44/28) + em;
				}
			}
		}
	}
}

//KAMO_RENEW3-423 KMS-933 No17.ラッピング方法の確認
.ss-shopping-payment__shipping {
	margin-top: 68px;
	@media (max-width: 767px) {
		margin-top: 32px;
	}
	&-select {
		#main .step2 .sec02 .selectUl & {
			@media (max-width: 767px) {
				width: auto;
			}
		}
	}
}
.ss-shopping-payment__wrapping {
	overflow: hidden;
	margin: 68px 0;
	@media (max-width: 767px) {
		margin: 32px 0;
	}
	&-radio {
		display: flex;
		flex-wrap: wrap;
		margin: 10px -15px 0;
		&-item {
			padding: 10px 15px;
			&-input {
				display: none;
			}
			&-label {
				display: flex;
				align-items: center;
				font-size: 16px;
				&::before {
					content: "";
					width: 20px;
					height: 20px;
					background: url('../../img/common/radio_img03.png') no-repeat left center;
					background-size: 20px;
					margin-right: 6px;
					flex-shrink: 0;
					input[type="radio"]:checked + & {
						background-image: url("../../img/common/radio_img05.png");
					}
				}
			}
		}
	}
}

//KAMO_RENEW3-366 AmazonPayV2対応
.ss-amazon-pay {
	&__cart-button {
		display: block !important;
		width: 100% !important;
		max-width: 242px;
		#main .comSubmit & {
			margin: 16px auto;
		}
		#AmazonPayButton1 {
			height: 55px;
		}
	}
}

//KAMO_RENEW3-423 KMS-933 No17.ラッピング方法の確認
.ss-shopping-payment {
	&__wrapping {
		&-description {
			margin: 16px 0 0;
			@media (max-width: 767px) {
				font-size: 14px;
				line-height: 1.71;
			}
		}
		&-notes,
		&-case {
			&-list {
				@media (max-width: 767px) {
					font-size: 14px;
					line-height: 1.71;
				}
				&-item {
					display: flex;
				}
			}
			&-label {
				flex-shrink: 0;
			}
			&-text {
				margin-left: 4px;
			}
		}
		&-def {
			&-term,
			&-desc {
				display: flex;
				&::before {
					width: 1em;
					flex-shrink: 0;
				}
			}
			&-term {
				margin: 16px 0 0;
				@media (max-width: 767px) {
					font-size: 14px;
					line-height: 1.71;
				}
				&::before {
					content: "※";
				}
			}
			&-desc {
				&::before {
					content: "";
				}
			}
		}
	}
}

//KAMO_RENEW3-444 KMS-1016 カートフローのテキスト改善
.ss-shopping-payment {
	&__notes {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 30px auto;
		width: 100%;
		max-width: 400px;
		&-text {
			font-size: 16px;
			text-align: center;
			@media (max-width: 767px) {
				text-align: left;
				&-break {
					display: none;
				}
			}
		}
		&-tel {
			font-size: 22px;
			font-weight: bold;
		}
	}
}

//KAMO_RENEW3-445 KMS-1019 【ネコポス】注文時にポップアップ表示
.ss-withdrawal {
	&__contents-item {
		&.is-submit {
			padding-left: 15px;
			padding-right: 15px;
			@media (max-width: 767px) {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
	&__submit {
		&-title {
			font-size: (25/26) + em;
			@media (max-width: 767px) {
				font-size: (13/22) + em;
			}
		}
		&-list {
			&-item {
				font-size: 23px;
				line-height: (39.09/23);
				font-weight: bold;
				text-align: center;
				@media (max-width: 767px) {
					font-size: 13px;
					line-height: (32/26);
				}
			}
		}
		&-notes {
			font-size: 18px;
			text-align: center;
			margin: 18px 0 0;
			@media (max-width: 767px) {
				font-size: 12.43px;
				line-height: (33.15/24.86);
			}
			&-break {
				display: none;
				@media (max-width: 767px) {
					display: inline;
				}
			}
		}
	}
}

//KAMO_RENEW3-449 KMS-1004 【２月月次】カート改修箇所の分析
.ss-shopping-confirm__actions {
	margin-bottom: 79px;
}

//KAMO_RENEW3-455 KMS-1080 U-komi導入

.ss-ukomi__inr{
	padding: 0px 0px 0;
	width: 1112px;
	margin: 0 auto;
	@media (max-width: 767px){
			padding: 20px 0px 0;
			width: 100%;
			margin: 0 auto;
	}
}

//KAMO_RENEW3-459 KMS-1084 【ご相談】プレゼント包装に関して
.ss-wrapping {
	display: flex;
	justify-content: flex-end;
	@media (max-width: 767px) {
		justify-content: flex-start;
	}
	&__body {
		margin-bottom: 60px;
		@media (max-width: 767px) {
			flex-grow: 1;
		}
	}
	&__item {
		padding: 10px 0;
	}
	&__input {
		display: none;
	}
	&__contents {}
	&__label {
		display: flex;
		align-items: center;
		&.is-error {
			background-color: #e200121a;
		}
		&-image {
			width: 20px;
			height: 20px;
			background: url("../../img/common/radio_img03.png?20211129") no-repeat left center;
			background-size: 20px;
			.ss-wrapping__input:checked + .ss-wrapping__contents & {
				background-image: url("../../img/common/radio_img05.png?20211129");
			}
		}
		&-text {
			font-size: 16px;
			margin: 0 0 0 4px;
		}
	}
	&__detail {
		display: none;
		align-items: center;
		padding: 10px 0 0;
		.ss-wrapping__input:checked + .ss-wrapping__contents & {
			display: flex;
		}
		&-image {
			flex-shrink: 0;
			width: 95px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&-contents {
			flex-grow: 1;
			padding-left: 16px;
		}
		&-item {}
		&-input {
			display: none;
		}
		&-label {
			display: flex;
			align-items: center;
			padding: 6px 0;
			&-image {
				width: 20px;
				height: 20px;
				background: url("../../img/common/radio_img03.png?20211129") no-repeat left center;
				background-size: 20px;
				.ss-wrapping__detail-input:checked + .ss-wrapping__detail-label & {
					background-image: url("../../img/common/radio_img05.png?20211129");
				}
			}
			&-text {
				font-size: 14px;
				margin: 0 0 0 4px;
			}
		}
	}
}

//KAMO_RENEW3-474 [KMS-1215] 【9月月次】カート改善
.ss-form__submit-button {
	&.is-red {
		#main .comSubmit li & {
			background-color: #dc143c;
			border-color: #dc143c;
		}
	}
}

//KAMO_RENEW3-481 KMS-1234 カート部分改修
.ss-withdrawal {
	&__submit {
		&-text {
			font-size: 23px;
			line-height: (39.09/23);
			font-weight: bold;
			text-align: center;
			color: #dc143c;
			@media (max-width: 767px) {
				font-size: 13px;
				line-height: (32/26);
			}
		}
	}
}

//KAMO_RENEW3-485 [KMS-1253] マイページの改修
.ss-refusal {
	&__notes {
		#main & {
			&.topTxt {
				@media (max-width: 767px) {
					margin-top: 30px;
				}
			}
		}
	}
	&__message {
		max-width: 600px;
		margin: 40px auto 60px;
		@media (max-width: 767px) {
			margin-bottom: 20px;
		}
		&-term {
			font-weight: bold;
			font-size: 18px;
		}
		&-desc {
			font-size: 16px;
			margin: 20px 0 0;
		}
	}
	&__shop {
		max-width: 600px;
		margin: 0 auto;
	}
}
.ss-refusal-check {
	display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
	margin-top: 40px;
	&__label {
		display: flex;
		align-items: center;
	}
	&__input {}
	&__text {
		font-size: 16px;
		line-height: 1.2;
		margin-left: 4px;
	}
}

//KAMO_RENEW3-498 KMS-1283 セキュリティ対策に関して

div#zeus_token_card_info_area {
	padding: 13px 0 0;
	label {
		margin: 20px 0 10px;
		font-size: 14px;
		font-weight: bold;
	}
	input[type='tel'] {
		appearance: none;
		padding: 2px 8px;
		height: 35px;
		font-size: 1.4rem;
		box-sizing: border-box;
		border: 1px solid #C4C4C3;
		border-radius: 0;
		@media (max-width: 767px) {
			padding: 2px 12px;
			width: 100%;
			height: 46px;
			font-size: 1.25rem;
		}
		&#zeus_token_card_cvv {
			width: auto;
		}
	}
	input[type='radio'] {
		margin: 0px 8px 0 0;
	}
	span {
		display: inline-block;
	}
}
div#zeus_registerd_card_area {
	margin: 20px 0 24px;
    padding: 0 0 24px;
    border-bottom: solid 1px #C4C4C3;
}
div#zeus_new_card_area {
	margin: 20px 0;
}
#zeus_token_card_expires_note {
	margin-top: 5px;
}
#zeus_token_card_expires_month_suffix,
#zeus_token_card_expires_year_suffix {
	padding: 0 5px;
}
#zeus_token_card_expires_month,
#zeus_token_card_expires_year {
	#main .mailForm .mailTable td & {
		width: auto;
	}
}

//KAMO_RENEW3-506 KMS-1377 【3月月次】カート導線改善
.ss-wrapping__label {
	border: solid 1px #fff;
	padding: 4px;
	&.is-hidden {
		display: none;
	}
	&-image {
		flex-shrink: 0;
	}
	&-text {
		flex-grow: 1;
	}
	.ss-wrapping__input:checked + .ss-wrapping__contents > & {
		background-color: #fff7f7;
		border: solid 1px #C5C5C4;	
	}
}

.ss-wrapping__detail {
	display: flex;
	padding: 0;
	&-image {
		margin-right: 16px;
	}
	&-contents {
		padding-left: 0;
	}
	&-label {
		border: solid 1px #fff;
		padding: 4px;
		&-image {
			flex-shrink: 0;
		}
		&-text {
			flex-grow: 1;
			font-size: 16px;
		}
		.ss-wrapping__detail-input:checked + & {
			background-color: #fff7f7;
			border: solid 1px #C5C5C4;	
		}
	}
}
.ss-shopping-login__notes {
	margin-top: 10px;
	#main .comLoginBox & {
		&.lBox {
			height: auto;
		}
	}
}
#gFooter .copyright {
	margin-top: 42px;
	@media (max-width: 767px) {
		margin-top: 19px;
	}
}
.is-active-color {
	background: #fff7f7;
	border: solid 2px #acacac;
}
.ss-shopping-complete__title {
	white-space: inherit;
	margin: 0 -25px;
	&::before,
	&::after {
		margin: 0 15px;
	}
}
.ss-shopping-payment__method-detail {
	display: none;
}
.ss-shopping-payment__method-detail.is-open {
    display: block;
}
.ss-credit {
	&__cvv-info {
		display: inline-flex;
		margin: 0px 0 0 30px;
		transform: translateY(-12px);
		@media (max-width: 767px) {
			transform: translateY(-15px);
		}
		@media (max-width: 424px) {
			margin: 0px 0 0 2px;
		}
		&-image {
			width: 80px;
			flex-shrink: 0;
			@media (max-width: 767px) {
				width: 100px;
			}
			@media (max-width: 424px) {
				width: 60px;
			}
		}
		&-line {
			width: 30px;
			height: 2px;
			background: #df2d18;
			margin: 19px 0 0 -26px;
			@media (max-width: 767px) {
				margin: 25px 0 0 -32px;
			}
			@media (max-width: 424px) {
				margin: 15px 0 0 -19px;
				height: 1px;
				width: 20px;
			}
		}
		&-text {
			font-size: 11px;
			margin: 12px 0 0 10px;
			@media (max-width: 767px) {
				margin: 18px 0 0 10px;
			}
			@media (max-width: 424px) {
				font-size: 10px;
				margin: 7px 0 0 4px;
			}
		}
	}
}
#main .step2 .sec04 .note {
	color: #000;
}
#gFooter .fBox .fSns {
	display: none;
}
.ss-shopping-payment__notes.is-shopping {
	margin: 15px auto;
}
#container.no-padding-top {
	padding-top: 0px !important;
}

//KAMO_RENEW3-516 KMS-1417 プライバシーポリシー改定に伴う、カート改修
.ss-policy-agreement {
	margin: 30px auto;
	&__check {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		&-input {
			display: none;
		}
		&-label {
			font-weight: bold;
			padding: 4px 0 4px 28px;
			display: inline-block;
			background: url(../../img/common/check_img03.png) no-repeat left center;
			background-size: 23px 23px;
			.ss-policy-agreement__check-input:checked + & {
				background-image: url(../../img/common/check_img04.png);
			}
		}
	}
	&__contents {
		word-break: break-all;
		.comScroll .scroll {
			margin-bottom: 0;
			padding: 13px 26px;
			height: 276px;
		}
		.comScroll .scroll p {
			margin: 0;
		}
		
		.comScroll .scroll .title {
			margin-bottom: 11px;
			font-size: 1.6rem;
			text-align: center;    
		}
		
		.comScroll .scroll .borderTxt {
			border-top: 1px solid #C8C8C7;
			margin-top: 40px;
			padding-top: 50px;
		}
		
		.comScroll .scroll table {
			margin: 21px 0 44px;
		}
		
		.comScroll .scroll th,
		.comScroll .scroll td {
			padding: 11px 18px;
			line-height: 1.4;
		}
		
		.comScroll .scroll th {
			width: 25%;
		}
		
		.comScroll .scroll td ul {
			margin-top: 7px;
		}
		
		.comScroll .scroll td ul li {
			padding-left: 1em;
			text-indent: -1em;
			line-height: 1.55;
		}

		@media all and (max-width: 767px) {
			.comScroll .scroll {
        padding: 8px 16px;
			}
    
			.comScroll .scroll .title {
				margin-bottom: 2px;
				font-size: 1.4rem;
			}
			
			.comScroll .scroll p {
				font-size: 1.25rem;
			}
			
			.comScroll .scroll .borderTxt {
				padding-top: 16px;
			}
			
			.comScroll .scroll th {
				width: 31%;
			}
			
			.comScroll .scroll .table {
				margin: 3px 0 28px;
				border-bottom: 1px solid #C4C4C3; 
			}
			
			.comScroll .scroll th,
			.comScroll .scroll td {
				padding: 8px 13px;
			}
			
			.comScroll .scroll .table th,
			.comScroll .scroll .table td {
				padding: 5px 13px;
				line-height: 1.84;
				font-size: 1.25rem;
				display: block;
				width: 100%;
				box-sizing: border-box;
				border-bottom: none;
			}
			
			.comScroll .scroll .table th {
				font-weight: bold;
			}
			
			.comScroll .scroll td ul {
				margin-top: 23px;
			}
			
			.comScroll .scroll td ul li:not(:last-child) {
				margin-bottom: 24px;
			}
		}
	}
}
.ss-form__submit-button.js-form-submit:disabled {
	background-color: #d4d4d4;
	border-color: #d4d4d4;
	cursor: not-allowed;
	#main .comSubmit li & {
		@extend .ss-form__submit-button.js-form-submit:disabled;
	}
}
