@charset "UTF-8";
/* Scss Document */
#main .mailForm .mailTable td input[type="password"],
input.ss-input--password[type="password"] {
  padding: 2px 8px;
  width: 388px;
  height: 35px;
  font-size: 1.4rem;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #C4C4C3;
  border-radius: 0; }

.errorText {
  margin: 50px 0 50px 6px;
  font-size: 1.8rem;
  line-height: 1.8;
  text-align: center; }

.shopping-payment-reserv {
  margin-top: 20px;
  line-height: 1.67;
  color: #ff0000; }

.shopping-confirm-text {
  margin-top: 30px;
  line-height: 1.67;
  color: #ff0000;
  text-align: center; }
  @media all and (max-width: 767px) {
    .shopping-confirm-text {
      margin-left: 25px;
      margin-right: 25px;
      font-size: 16px;
      text-align: left; } }

.cart-arrival {
  color: #ff0000;
  font-size: 14px; }

.cart-note {
  color: #ff0000;
  margin-bottom: 40px; }
  .cart-note__label {
    font-size: 16px; }
  .cart-note dl {
    margin-top: 5px; }
    .cart-note dl dt {
      font-weight: bold; }
  .cart-note .mini {
    font-size: 12px;
    margin-top: 5px; }

#main .step2 .sec03.u-mb-15 {
  margin-bottom: 15px; }

.p-cart__product-calculator {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center; }
  @media all and (max-width: 767px) {
    .p-cart__product-calculator {
      border-top: #c5c5c4 solid 1px;
      border-bottom: #c5c5c4 solid 1px; } }
  .p-cart__product-calculator-quantity {
    margin: 0 5px; }
    @media all and (max-width: 767px) {
      .p-cart__product-calculator-quantity {
        font-size: 1.5em; } }
  .p-cart__product-calculator-button {
    border: #EFEFEF solid 1px;
    background: rgba(0, 0, 0, 0.02);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    font-size: 15px; }
    @media all and (max-width: 767px) {
      .p-cart__product-calculator-button {
        border-top: none;
        border-right: #c5c5c4 solid 1px;
        border-bottom: none;
        border-left: #c5c5c4 solid 1px; } }
    .p-cart__product-calculator-button:disabled {
      background: rgba(0, 0, 0, 0.12); }

#main .step2 textarea[name^="offer"] {
  height: auto; }

@media all and (max-width: 767px) {
  #main .mailForm .mailTable td input[type="password"],
  input.ss-input--password[type="password"] {
    padding: 2px 12px;
    width: 100%;
    height: 46px;
    font-size: 1.25rem; }
  .comProduct .numUl li .num span {
    padding-top: 0; }
  .present-complete-text {
    margin: 0 15px; }
  #main .step2 .sec05 .checkUl li {
    width: 100%; } }

#main .comPhotoUl li .txtBox .icon-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px; }
  #main .comPhotoUl li .txtBox .icon-box .icon {
    width: auto;
    margin: 2px;
    padding: 5px 6px 2px; }

@media all and (max-width: 767px) {
  #main .comPhotoUl li .txtBox .icon-box .icon {
    font-size: 10px; } }

.ss-container {
  position: relative;
  z-index: 1; }

@media all and (max-width: 767px) {
  #gHeader.ss-header .ss-header__contents .ss-header__logo {
    margin: 0 109px 0 15px;
    text-align: left; } }

.ss-search-products__modal-triger {
  display: block;
  width: 100%;
  height: 46px;
  font-size: 1.65rem;
  border: 1px solid #F1F1F1;
  padding: 2px 10px 3px;
  box-sizing: border-box;
  background-color: #f8f8f8;
  border-radius: 4px;
  line-height: 39px;
  position: relative;
  color: #000; }
  .ss-search-products__modal-triger:link:hover {
    color: #000; }
  .ss-search-products__modal-triger:after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000000 transparent transparent transparent; }

.ss-search-products__radio-field {
  display: none; }
  .ss-search-products__radio-field-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.24);
    position: relative; }
    .ss-search-products__radio-field-item:last-child {
      border-bottom: none; }

.ss-search-products__radio-label {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 20px;
  font-size: 1.6rem; }
  @media all and (max-width: 767px) {
    .ss-search-products__radio-label {
      padding: 10px 15px;
      font-size: 1.4rem; } }

.ss-search-products__radio-input {
  display: none; }

.ss-search-products__radio-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #c2c2c2; }
  .ss-search-products__radio-button-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: #c2c2c2; }
  .ss-search-products__radio-input:checked + .ss-search-products__radio-button {
    border: 3px solid #1a73e8; }
    .ss-search-products__radio-input:checked + .ss-search-products__radio-button .ss-search-products__radio-button-inner {
      background: #1a73e8; }

.ss-modal {
  z-index: 1001; }
  .ss-modal .modaal-container {
    border-radius: 8px; }
  .ss-modal .modaal-content-container {
    padding: 0; }
  .ss-modal .modaal-close:hover {
    background: #000; }
    .ss-modal .modaal-close:hover:after, .ss-modal .modaal-close:hover:before {
      background: #fff; }

#main .ss-aw-recommend__list {
  margin-bottom: 59px; }
  @media all and (max-width: 767px) {
    #main .ss-aw-recommend__list {
      margin-bottom: 30px; } }

.ss-aw-recommend__list-item {
  display: -ms-flexbox;
  display: flex; }

.ss-aw-recommend__link {
  -ms-flex-direction: column;
  flex-direction: column; }
  #main .ss-aw-recommend__list .ss-aw-recommend__list-item .ss-aw-recommend__link {
    display: -ms-flexbox;
    display: flex; }

.ss-aw-recommend__title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }
  .ss-aw-recommend__title-icon {
    line-height: 1; }
    .ss-aw-recommend__title-icon-pic {
      transform: translateY(-2px); }
  .ss-aw-recommend__title-text {
    margin-left: 5px; }

.ss-aw-recommend__price {
  padding-right: 0; }
  .ss-aw-recommend__price--base, .ss-aw-recommend__price--taxin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: baseline;
    align-items: baseline; }
  .ss-aw-recommend__price-text {
    margin-right: 5px;
    white-space: nowrap; }
    @media all and (max-width: 767px) {
      .ss-aw-recommend__price-text {
        margin-right: 2px; } }
  #main .ss-aw-recommend__list .ss-aw-recommend__list-item .ss-aw-recommend__price--base .ss-aw-recommend__price-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.3;
    letter-spacing: 0.025em;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    @media all and (max-width: 767px) {
      #main .ss-aw-recommend__list .ss-aw-recommend__list-item .ss-aw-recommend__price--base .ss-aw-recommend__price-number {
        font-size: 1.75rem;
        margin-right: 2px; } }
  .ss-aw-recommend__price--taxin .ss-aw-recommend__price-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    letter-spacing: 0.025em;
    margin-right: 5px; }
    @media all and (max-width: 767px) {
      .ss-aw-recommend__price--taxin .ss-aw-recommend__price-number {
        font-size: 1.35rem;
        margin-right: 2px; } }

.ss-aw-recommend__actions {
  margin-top: 5px;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }
  .ss-aw-recommend__actions-item {
    font-size: 1.4rem;
    position: relative;
    top: auto;
    right: auto; }

#main .ss-aw-ranking {
  margin-top: 60px; }

.ss-aw-ranking__price {
  padding-right: 0; }
  .ss-aw-ranking__price--base, .ss-aw-ranking__price--taxin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: baseline;
    align-items: baseline; }
  .ss-aw-ranking__price-text {
    font-size: 1.2rem;
    margin-right: 3px;
    white-space: nowrap; }
    @media all and (max-width: 767px) {
      .ss-aw-ranking__price-text {
        font-size: 1.1rem;
        margin-right: 2px; } }
  .ss-aw-ranking__price-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.025em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    .ss-aw-ranking__price--base .ss-aw-ranking__price-number {
      font-size: 2.0rem;
      font-weight: bold; }
      @media all and (max-width: 767px) {
        .ss-aw-ranking__price--base .ss-aw-ranking__price-number {
          font-size: 1.75rem; } }
    .ss-aw-ranking__price--taxin .ss-aw-ranking__price-number {
      font-size: 1.6rem; }
      @media all and (max-width: 767px) {
        .ss-aw-ranking__price--taxin .ss-aw-ranking__price-number {
          font-size: 1.4rem; } }
  .ss-aw-ranking__price-unit {
    margin-left: 3px;
    font-size: 1.2rem; }
    @media all and (max-width: 767px) {
      .ss-aw-ranking__price-unit {
        font-size: 1.1rem;
        margin-left: 2px; } }

@media all and (max-width: 767px) {
  .ss-checked-items {
    margin-top: 30px;
    margin-bottom: 35px; } }

.ss-checked-items__price {
  padding-right: 0; }
  .ss-checked-items__price--base, .ss-checked-items__price--taxin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: baseline;
    align-items: baseline; }
  .ss-checked-items__price-text {
    font-size: 1.2rem;
    margin-right: 3px;
    white-space: nowrap; }
    @media all and (max-width: 767px) {
      .ss-checked-items__price-text {
        font-size: 1.0rem;
        margin-right: 2px; } }
  .ss-checked-items__price-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.025em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    .ss-checked-items__price--base .ss-checked-items__price-number {
      font-size: 2.0rem;
      font-weight: bold; }
      @media all and (max-width: 767px) {
        .ss-checked-items__price--base .ss-checked-items__price-number {
          font-size: 1.4rem; } }
    .ss-checked-items__price--taxin .ss-checked-items__price-number {
      font-size: 1.6rem; }
      @media all and (max-width: 767px) {
        .ss-checked-items__price--taxin .ss-checked-items__price-number {
          font-size: 1.2rem; } }
  .ss-checked-items__price-unit {
    margin-left: 3px;
    font-size: 1.2rem; }
    @media all and (max-width: 767px) {
      .ss-checked-items__price-unit {
        font-size: 1.0rem;
        margin-left: 2px; } }

#main .ss-news-facebook {
  margin-top: 80px;
  margin-bottom: 110px; }
  @media all and (max-width: 767px) {
    #main .ss-news-facebook {
      margin-top: 50px;
      margin-bottom: 90px; } }

@media all and (max-width: 767px) {
  .ss-news-facebook__item {
    width: 100%;
    max-width: 500px;
    margin: auto; } }

#main .ss-news-facebook .ss-news-facebook__item {
  height: auto; }

@media all and (max-width: 767px) {
  .ss-news-facebook .fb-page {
    width: 100%; } }

@media all and (max-width: 767px) {
  .ss-news-facebook .fb-page > span,
  .ss-news-facebook iframe {
    width: 100% !important; } }

.ss-news__container {
  position: relative;
  border: 1px solid #A8A8A7;
  padding: 1px; }

.ss-news__scroll {
  position: relative;
  height: 326px; }
  .ss-news__scroll-contents {
    padding: 30px 55px 15px 40px; }
    @media all and (max-width: 767px) {
      .ss-news__scroll-contents {
        padding: 25px 30px 15px 15px; } }
    .ss-news__scroll-contents dt {
      margin-top: 5px;
      font-size: 1.2rem;
      float: left; }
      @media all and (max-width: 767px) {
        .ss-news__scroll-contents dt {
          margin-top: 5px;
          font-size: 1.1rem; } }
    .ss-news__scroll-contents dd {
      padding: 0 0 20px 7em;
      font-size: 1.3rem;
      line-height: 1.89; }
      @media all and (max-width: 767px) {
        .ss-news__scroll-contents dd {
          padding: 0 0 19px 6.2em;
          font-size: 1.25rem;
          line-height: 1.84; } }
    .ss-news__scroll-contents a {
      text-decoration: underline;
      display: inline-block; }
  .ss-news__scroll .ps__rail-x,
  .ss-news__scroll .ps__rail-y {
    background: #fff !important;
    opacity: 1 !important; }

@media all and (max-width: 767px) {
  #main .ss-column .ss-column__list-item {
    float: none;
    width: auto; } }

.ss-fixed-tel {
  display: none; }
  @media all and (max-width: 767px) {
    .ss-fixed-tel {
      display: block;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 200;
      transition: transform 250ms ease-out;
      transform: translateY(100%); }
      .ss-fixed-tel.is-show {
        transform: translateY(0%); } }
  .ss-fixed-tel__link {
    background: #8cc220;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 9px; }
  .ss-fixed-tel__icon {
    width: 33px;
    margin-right: 10px; }
  .ss-fixed-tel__text {
    font-size: 2.0rem;
    line-height: 1.2;
    color: #fff; }

@media all and (max-width: 767px) {
  .ss-footer {
    position: relative; } }

@media all and (max-width: 767px) {
  #gFooter.ss-footer .ss-footer__page-top {
    position: absolute;
    bottom: auto;
    top: -22px; } }

#main .comPhotoUl li .txtBox .ss-product-list__icon {
  margin-bottom: 7px; }
  @media all and (max-width: 767px) {
    #main .comPhotoUl li .txtBox .ss-product-list__icon {
      margin-bottom: 4px; } }

.ss-product-list__catch {
  color: #ff8a06;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.475; }
  @media all and (max-width: 767px) {
    .ss-product-list__catch {
      font-size: 1.25rem; } }

.ss-product-list__price {
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media all and (max-width: 767px) {
    .ss-product-list__price {
      -ms-flex-direction: column;
      flex-direction: column;
      margin-bottom: 8px;
      line-height: 1.3; } }
  .ss-product-list__price--base, .ss-product-list__price--taxin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: baseline;
    align-items: baseline; }
    @media all and (max-width: 767px) {
      .ss-product-list__price--base, .ss-product-list__price--taxin {
        width: 100%; } }
  .ss-product-list__price--separator {
    margin: 0 5px; }
    @media all and (max-width: 767px) {
      .ss-product-list__price--separator {
        display: none; } }
  .ss-product-list__price-text {
    font-size: 1.6rem;
    margin-right: 6px;
    white-space: nowrap; }
    @media all and (max-width: 767px) {
      .ss-product-list__price-text {
        font-size: 1.1rem;
        margin-right: 4px; } }
  .ss-product-list__price-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.025em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    .ss-product-list__price--base .ss-product-list__price-number {
      font-size: 2.6rem;
      font-weight: bold; }
      @media all and (max-width: 767px) {
        .ss-product-list__price--base .ss-product-list__price-number {
          font-size: 1.9rem; } }
    .ss-product-list__price--taxin .ss-product-list__price-number {
      font-size: 2.2rem; }
      @media all and (max-width: 767px) {
        .ss-product-list__price--taxin .ss-product-list__price-number {
          font-size: 1.6rem; } }
  .ss-product-list__price-unit {
    margin-left: 3px;
    font-size: 1.6rem; }
    @media all and (max-width: 767px) {
      .ss-product-list__price-unit {
        font-size: 1.1rem;
        margin-left: 2px; } }

.ss-product-detail__price {
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media all and (max-width: 767px) {
    .ss-product-detail__price {
      -ms-flex-pack: end;
      justify-content: flex-end; } }
  .ss-product-detail__price--base, .ss-product-detail__price--taxin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: baseline;
    align-items: baseline; }
  .ss-product-detail__price--separator {
    margin: 0 5px; }
  .ss-product-detail__price-text {
    font-size: 1.6rem;
    margin-right: 6px;
    white-space: nowrap; }
    @media all and (max-width: 767px) {
      .ss-product-detail__price-text {
        font-size: 1.4rem;
        margin-right: 4px; } }
  .ss-product-detail__price-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.025em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    .ss-product-detail__price--base .ss-product-detail__price-number {
      font-size: 3.0rem;
      font-weight: bold; }
      @media all and (max-width: 767px) {
        .ss-product-detail__price--base .ss-product-detail__price-number {
          font-size: 2.25rem; } }
    .ss-product-detail__price--taxin .ss-product-detail__price-number {
      font-size: 2.35rem; }
      @media all and (max-width: 767px) {
        .ss-product-detail__price--taxin .ss-product-detail__price-number {
          font-size: 2.0rem; } }
  .ss-product-detail__price-unit {
    margin-left: 3px;
    font-size: 1.6rem; }
    @media all and (max-width: 767px) {
      .ss-product-detail__price-unit {
        font-size: 1.4rem;
        margin-left: 2px; } }

.ss-related__price {
  padding-right: 0; }
  .ss-related__price--base, .ss-related__price--taxin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: baseline;
    align-items: baseline; }
  .ss-related__price-text {
    font-size: 1.2rem;
    margin-right: 3px;
    white-space: nowrap; }
    @media all and (max-width: 767px) {
      .ss-related__price-text {
        font-size: 1.1rem;
        margin-right: 2px; } }
  .ss-related__price-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.025em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    .ss-related__price--base .ss-related__price-number {
      font-size: 2.0rem;
      font-weight: bold; }
      @media all and (max-width: 767px) {
        .ss-related__price--base .ss-related__price-number {
          font-size: 1.4rem; } }
    .ss-related__price--taxin .ss-related__price-number {
      font-size: 1.6rem; }
      @media all and (max-width: 767px) {
        .ss-related__price--taxin .ss-related__price-number {
          font-size: 1.2rem; } }
  .ss-related__price-unit {
    margin-left: 3px;
    font-size: 1.2rem; }
    @media all and (max-width: 767px) {
      .ss-related__price-unit {
        font-size: 1.1rem;
        margin-left: 2px; } }

@media all and (max-width: 767px) {
  .ss-related-categories {
    margin-left: 0;
    margin-right: 0; } }

.ss-related-categories .ss-related-categories__title {
  margin-bottom: 15px; }

.ss-related-categories__list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -7px; }
  .ss-related-categories__list-item {
    margin: 7px; }

.ss-related-categories__link {
  display: block;
  background: #eee3d6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 10px 5px 15px;
  border-radius: 4px; }
  .ss-related-categories__link-text {
    font-weight: bold; }
  .ss-related-categories__link:after {
    content: ">";
    display: block;
    margin-left: 15px;
    font-weight: bold; }

@media all and (max-width: 767px) {
  #container {
    padding-top: 52px; }
  #gHeader.ss-header {
    padding-top: 11px; }
  #gHeader .hBox .ss-header__logo {
    min-height: 41px; }
  #gHeader .hList {
    width: auto; }
    #gHeader .hList .ss-header__button {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: auto;
      padding: 6px 10px;
      min-height: 52px; }
      #gHeader .hList .ss-header__button-text {
        margin-right: 5px; }
  .ss-header__logo-link {
    display: block;
    width: 85px; } }

.ss-product-detail__color {
  display: flex;
  align-items: center;
  margin: 15px 0; }
  .ss-product-detail__color-label {
    display: flex;
    align-items: center;
    padding-right: 1em; }
    .ss-product-detail__color-label:after {
      content: "：";
      width: 0.5em; }
  .ss-product-detail__color-detail {
    flex-grow: 1; }
  .ss-product-detail__color-table {
    width: 100%;
    border-collapse: collapse; }
    .ss-product-detail__color-table-tr {
      text-align: center; }
    .ss-product-detail__color-table-th {
      font-size: 0.875em;
      padding: 5px;
      border: #A2A2A2 solid 1px; }
    .ss-product-detail__color-table-td {
      padding: 5px;
      border: #A2A2A2 solid 1px; }
  .ss-product-detail__color-circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 5px auto; }

.ss-product-detail__modal-contents {
  display: none; }

.ss-product-detail__modal .modaal-close {
  position: absolute;
  background: #8e8e8e; }
  @media all and (max-width: 767px) {
    .ss-product-detail__modal .modaal-close {
      top: -20px;
      right: -20px; } }
  .ss-product-detail__modal .modaal-close:hover:after, .ss-product-detail__modal .modaal-close:hover:before {
    background: #fff; }

@media all and (max-width: 767px) {
  .ss-product-detail__modal .modaal-content-container {
    padding: 0 25px 25px; } }

.ss-product-detail__modal h3 {
  margin-bottom: 27px;
  padding: 18px 0 19px;
  font-size: 2.8rem;
  text-align: center;
  background-color: #F4EFEB; }

.ss-product-detail__modal h3 img {
  margin: -5px 25px 0 0;
  width: 47px;
  vertical-align: middle; }

.ss-product-detail__modal .text {
  margin-bottom: 45px;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.9; }

.ss-product-detail__modal .title {
  margin-bottom: 32px;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.75;
  letter-spacing: -0.5px; }

.ss-product-detail__modal .title .bg {
  margin-bottom: 16px;
  padding: 4px 8px;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.5;
  background: #F9F4F0; }

.ss-product-detail__modal .title .line {
  margin-bottom: 16px;
  padding-bottom: 9px;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.5;
  border-bottom: 1px solid #F9F4F0; }

.ss-product-detail__modal .txt {
  margin-top: 5px;
  display: block;
  font-size: 1.4rem; }

.ss-product-detail__modal .txtList {
  margin: 0 auto 28px;
  width: 552px; }

.ss-product-detail__modal .txtList li {
  padding: 9px 0; }

.ss-product-detail__modal .txtList li dl {
  display: table; }

.ss-product-detail__modal .txtList dt {
  width: 20%;
  display: table-cell;
  vertical-align: middle;
  font-weight: bold; }

.ss-product-detail__modal .txtList dt span {
  padding: 2px 0;
  min-width: 120px;
  display: inline-block;
  font-size: 1.8rem;
  text-align: center;
  background: #F0D0C9;
  border-radius: 3px; }

.ss-product-detail__modal .txtList dd {
  padding-left: 12px;
  font-size: 1.6rem;
  display: table-cell;
  vertical-align: middle;
  letter-spacing: -0.5px; }

.ss-product-detail__modal .photo1 {
  margin: 0 0 68px 5px;
  text-align: center; }

.ss-product-detail__modal .photo1 img {
  width: 457px; }

.ss-product-detail__modal .sizeUl {
  margin-bottom: 21px; }

.ss-product-detail__modal .sizeUl li {
  width: 46.7%;
  float: left; }

.ss-product-detail__modal .sizeUl li:nth-child(2) {
  float: right; }

.ss-product-detail__modal .sizeUl li p {
  margin: 25px 0 9px;
  font-size: 1.6rem; }

.ss-product-detail__modal .sizeUl li .bg {
  margin: 0 0 -3px;
  padding: 5px 0 6px;
  font-size: 2rem;
  text-align: center;
  background-color: #F8F3EE; }

.ss-product-detail__modal .sizeUl li .bg img {
  margin: -3px 6px 0 0;
  width: 26px;
  vertical-align: middle; }

.ss-product-detail__modal .sizeUl li table {
  width: 100%;
  border-collapse: collapse; }

.ss-product-detail__modal .sizeUl li th {
  font-size: 1.6rem;
  background-color: #E5E5E5; }

.ss-product-detail__modal .sizeUl li td,
.ss-product-detail__modal .sizeUl li th {
  padding: 9px 0;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #808080;
  border-left: none; }

.ss-product-detail__modal .sizeUl li td {
  padding: 10px 0; }

.ss-product-detail__modal .sizeUl li td:last-child,
.ss-product-detail__modal .sizeUl li th:last-child {
  border-right: none; }

.ss-product-detail__modal .sizeUl li .wid01 {
  width: 24%; }

.ss-product-detail__modal .sizeUl li .wid02 {
  width: 43.5%; }

.ss-product-detail__modal .sizeUl li .wid03 {
  width: 32.5%; }

.ss-product-detail__modal .sizeUl li .wid04 {
  width: 28%; }

.ss-product-detail__modal .sizeUl li .wid05 {
  width: 24%; }

.ss-product-detail__modal .note {
  margin-bottom: 72px;
  font-size: 1.6rem; }

.ss-product-detail__modal .photoUl {
  margin: 42px -20px 69px;
  text-align: center;
  overflow: hidden; }

.ss-product-detail__modal .photoUl li {
  width: 179px;
  display: inline-block;
  vertical-align: top; }

.ss-product-detail__modal .photoUl li:nth-child(2) {
  margin: 0 65px 0 57px;
  width: 261px; }

.ss-product-detail__modal .photoUl li span {
  margin-bottom: 16px;
  padding: 1px 0 2px;
  min-width: 120px;
  display: inline-block;
  font-size: 1.8rem;
  text-align: center;
  border-radius: 3px;
  background-color: #EBCCC5; }

.ss-product-detail__modal .borderBox {
  margin: 0 auto 23px;
  padding: 19px 0 28px;
  width: 602px;
  text-align: center;
  box-sizing: border-box;
  border: 2px solid #000;
  border-radius: 3px; }

.ss-product-detail__modal .borderBox .ttl {
  margin-bottom: 18px;
  font-size: 2rem;
  font-weight: bold; }

.ss-product-detail__modal .borderBox p img {
  margin: -3px 25px 0 0;
  width: 228px;
  vertical-align: middle; }

.ss-product-detail__modal .txtUl {
  text-align: center; }

.ss-product-detail__modal .txtUl li {
  padding-left: 1em;
  text-indent: -1em;
  display: inline-block;
  text-align: left;
  line-height: 1.65;
  letter-spacing: -0.5px; }

@media all and (max-width: 767px) {
  .ss-product-detail__modal #pagePath {
    margin-bottom: 32px; }
  .ss-product-detail__modal h3 {
    margin: -3px -25px 19px;
    padding: 18px 0 15px;
    font-size: 1.73rem;
    line-height: 1.3; }
  .ss-product-detail__modal h3 img {
    margin: -2px 12px 0 0;
    width: 32px; }
  .ss-product-detail__modal .text {
    margin: 0 5px 34px;
    font-size: 1.4rem;
    line-height: 1.65; }
  .ss-product-detail__modal .title {
    margin-bottom: 19px;
    font-size: 1.4rem;
    text-align: left;
    line-height: 1.8; }
  .ss-product-detail__modal .title .bg {
    margin: 0 auto -12px;
    padding: 1px 0;
    max-width: 145px;
    display: block;
    font-size: 1.4rem;
    line-height: 1.5;
    text-align: center; }
  .ss-product-detail__modal .title .line {
    margin-bottom: -17px;
    padding-bottom: 9px;
    display: block;
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center; }
  .ss-product-detail__modal .txt {
    margin-top: 0; }
  .ss-product-detail__modal .txtList {
    margin: 0 14px 25px;
    width: auto; }
  .ss-product-detail__modal .txtList dt span {
    padding: 1px 0;
    min-width: 100px;
    display: inline-block;
    font-size: 1.4rem; }
  .ss-product-detail__modal .txtList dd {
    padding-left: 8px;
    font-size: 1.3rem;
    line-height: 1.4; }
  .ss-product-detail__modal .photo1 {
    margin: 0 0 46px; }
  .ss-product-detail__modal .photo1 img {
    width: 97%; }
  .ss-product-detail__modal .sizeUl {
    margin: 0 25px 16px; }
  .ss-product-detail__modal .sizeUl li {
    width: auto;
    float: none !important; }
  .ss-product-detail__modal .sizeUl li:not(:last-child) {
    margin-bottom: 40px; }
  .ss-product-detail__modal .sizeUl li p {
    margin: 15px 0 6px;
    font-size: 1.3rem; }
  .ss-product-detail__modal .sizeUl li .bg {
    padding: 5px 0 4px;
    font-size: 1.5rem;
    text-align: center;
    background-color: #F8F3EE; }
  .ss-product-detail__modal .sizeUl li .bg img {
    margin: -3px 4px 0 0;
    width: 21px;
    vertical-align: middle; }
  .ss-product-detail__modal .sizeUl li table {
    width: 100%;
    border-collapse: collapse; }
  .ss-product-detail__modal .sizeUl li th {
    font-size: 1.4rem;
    background-color: #E5E5E5; }
  .ss-product-detail__modal .sizeUl li td,
  .ss-product-detail__modal .sizeUl li th {
    padding: 6px 0;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #808080;
    border-left: none; }
  .ss-product-detail__modal .sizeUl li td {
    padding: 8px 0 7px; }
  .ss-product-detail__modal .sizeUl li td:last-child,
  .ss-product-detail__modal .sizeUl li th:last-child {
    border-right: none; }
  .ss-product-detail__modal .note {
    margin: 0 0 54px 27px;
    padding-left: 1em;
    text-indent: -1em;
    font-size: 1.4rem;
    letter-spacing: -0.5px; }
  .ss-product-detail__modal .photoUl {
    margin: 31px 28px 50px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: inherit; }
  .ss-product-detail__modal .photoUl li {
    width: 47%; }
  .ss-product-detail__modal .photoUl li:nth-child(1) {
    order: 1; }
  .ss-product-detail__modal .photoUl li:nth-child(3) {
    order: 2; }
  .ss-product-detail__modal .photoUl li:nth-child(2) {
    margin: -26px 0 0 -10%;
    width: 67%;
    order: 3; }
  .ss-product-detail__modal .photoUl li span {
    margin-bottom: 10px;
    padding: 0;
    min-width: 80px;
    display: inline-block;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 3px;
    background-color: #EBCCC5; }
  .ss-product-detail__modal .borderBox {
    margin: 0 17px 13px;
    padding: 9px 0 8px;
    width: auto;
    border-width: 1px; }
  .ss-product-detail__modal .borderBox .ttl {
    margin-bottom: 8px;
    font-size: 1.3rem; }
  .ss-product-detail__modal .borderBox p img {
    margin: 0 auto 6px;
    width: 182px;
    display: block; }
  .ss-product-detail__modal .txtUl {
    margin: 0 17px;
    text-align: left; }
  .ss-product-detail__modal .txtUl li {
    padding-left: 0;
    text-indent: 0;
    display: block;
    line-height: 1.7; } }

@media all and (-ms-high-contrast: none) {
  .ss-product-detail__modal h3 {
    padding: 21px 0 16px; }
  .ss-product-detail__modal .title .bg {
    padding: 6px 8px 2px; }
  .ss-product-detail__modal .txtList dt span {
    padding: 4px 0 0; }
  .ss-product-detail__modal .sizeUl li .bg {
    padding: 7px 0 4px; }
  .ss-product-detail__modal .sizeUl li td,
  .ss-product-detail__modal .sizeUl li th {
    padding: 11px 0 7px; }
  .ss-product-detail__modal .photoUl li span {
    padding: 3px 0 0; } }

.ss-product-detail__modal h3 {
  margin-bottom: 31px;
  padding: 18px 0 19px;
  font-size: 2.8rem;
  text-align: center;
  background-color: #F4EFEB; }

.ss-product-detail__modal h3 img {
  margin: -5px 25px 0 0;
  width: 47px;
  vertical-align: middle; }

.ss-product-detail__modal .text {
  margin-bottom: 28px;
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.8; }

.ss-product-detail__modal .material {
  overflow: hidden;
  margin: 0 auto;
  width: 750px; }

.ss-product-detail__modal .material .inner {
  margin: 42px 0 -1px;
  padding: 0 0 43px 180px;
  position: relative;
  background: url("../img/kiji/line01.jpg") repeat-x left bottom;
  background-size: 5px 1px; }

.ss-product-detail__modal .material .inner .lBox {
  float: left; }

.ss-product-detail__modal .material .inner .lBox > img {
  width: 150px;
  position: absolute;
  left: 0;
  top: 7px; }

.ss-product-detail__modal .material .inner .lBox p {
  margin-bottom: 11px;
  font-size: 2.6rem;
  font-weight: bold; }

.ss-product-detail__modal .material .inner .lBox ul {
  margin-bottom: 20px; }

.ss-product-detail__modal .material .inner .lBox li {
  margin-right: 2px;
  padding-top: 6px;
  width: 36px;
  height: 36px;
  display: inline-block;
  box-sizing: border-box;
  font-size: 1.7rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  background: #8FC320; }

.ss-product-detail__modal .material .inner .lBox li.gray {
  background-color: #909090; }

.ss-product-detail__modal .material .inner .lBox dl {
  font-size: 1.6rem; }

.ss-product-detail__modal .material .inner .lBox dt {
  margin-bottom: 3px;
  color: #7FBD26; }

.ss-product-detail__modal .material .inner .lBox dd {
  line-height: 1.6; }

.ss-product-detail__modal .material .inner .rBox {
  padding: 7px 5px 0 0;
  float: right; }

.ss-product-detail__modal .material .inner .rBox img {
  width: 237px; }

.ss-product-detail__modal .material .inner > p {
  padding-top: 23px;
  clear: both;
  font-size: 1.6rem;
  line-height: 1.8;
  letter-spacing: -0.5px; }

.ss-product-detail__modal .material .inner .txt {
  padding-top: 17px;
  color: #6D6D6D;
  font-size: 1.4rem; }

.ss-product-detail__modal .material .inner ul {
  padding-top: 17px; }

.ss-product-detail__modal .material .inner > ul > li {
  margin: 4px 36px 4px 0;
  display: inline-block; }

.ss-product-detail__modal .material .inner > ul > li a {
  color: #6D6D6D;
  text-decoration: underline; }

.ss-product-detail__modal .material .inner > ul > li a:hover {
  text-decoration: none; }

@media all and (max-width: 767px) {
  .ss-product-detail__modal #pagePath {
    margin-bottom: 32px; }
  .ss-product-detail__modal h3 {
    margin: -3px -25px 19px;
    padding: 18px 0 15px;
    font-size: 1.73rem;
    line-height: 1.3; }
  .ss-product-detail__modal h3 img {
    margin: -2px 12px 0 0;
    width: 32px; }
  .ss-product-detail__modal .text {
    margin: 0 20px 7px;
    font-size: 1.4rem;
    line-height: 1.65; }
  .ss-product-detail__modal .material {
    width: auto; }
  .ss-product-detail__modal .material .inner {
    margin: 32px 0 -1px;
    padding: 0 0 26px;
    position: relative;
    background: url("../img/kiji/line01.jpg") repeat-x left bottom;
    background-size: 2px 1px; }
  .ss-product-detail__modal .material .inner .lBox {
    float: none;
    overflow: hidden;
    position: relative;
    z-index: 1; }
  .ss-product-detail__modal .material .inner .lBox > img {
    padding-top: 4px;
    width: 28%;
    position: static;
    float: left; }
  .ss-product-detail__modal .material .inner .lBox > div {
    width: 67.5%;
    float: right; }
  .ss-product-detail__modal .material .inner .lBox p {
    margin-bottom: 11px;
    font-size: 1.7rem; }
  .ss-product-detail__modal .material .inner .lBox ul {
    margin-bottom: 13px; }
  .ss-product-detail__modal .material .inner .lBox li {
    margin-right: 1px;
    padding-top: 3px;
    width: 25px;
    height: 25px;
    font-size: 1.1rem;
    border-radius: 100%; }
  .ss-product-detail__modal .material .inner .lBox dl {
    font-size: 1.4rem; }
  .ss-product-detail__modal .material .inner .lBox dt {
    margin-bottom: 3px; }
  .ss-product-detail__modal .material .inner .lBox dd {
    line-height: 1.6; }
  .ss-product-detail__modal .material .inner .rBox {
    margin-top: 21px;
    padding: 0 5px 0 0;
    float: none;
    text-align: center; }
  .ss-product-detail__modal .material .inner .rBox img {
    width: 60%; }
  .ss-product-detail__modal .material .inner > p {
    padding: 23px 0 2px;
    font-size: 1.4rem;
    letter-spacing: -0.5px;
    line-height: 1.85; }
  .ss-product-detail__modal .material .inner .txt {
    font-size: 1.3rem; }
  .ss-product-detail__modal .material .inner > ul > li {
    margin: 5px 0;
    display: block;
    font-size: 1.3rem; }
  .ss-product-detail__modal .material .inner > ul > li a:hover {
    text-decoration: underline; } }

@media all and (-ms-high-contrast: none) {
  .ss-product-detail__modal h3 {
    padding: 21px 0 16px; }
  .ss-product-detail__modal .material .inner .lBox li {
    padding-top: 8px; } }

.ss-product-detail__cv {
  margin-top: -116px;
  padding-top: 116px; }
  @media all and (max-width: 767px) {
    .ss-product-detail__cv {
      margin-top: -67px;
      padding-top: 67px; } }
  .ss-product-detail__cv-actions {
    margin: 40px 0; }
    @media all and (max-width: 767px) {
      .ss-product-detail__cv-actions {
        margin: 40px 25px; } }
    .ss-product-detail__cv-actions-item {
      margin: 0 auto;
      width: 100%;
      max-width: 650px; }
  .ss-product-detail__cv-link {
    padding: 7px 80px;
    display: block;
    font-size: 2.4rem;
    font-weight: bold;
    font-family: 'Hiragino Kaku Gothic ProN';
    text-align: center;
    border: 4px solid #FF8B07;
    background: url("../../img/accordion/icon02.png") no-repeat right 62px center;
    background-size: 18px 10px; }
    @media all and (max-width: 767px) {
      .ss-product-detail__cv-link {
        padding: 18px 40px 17px 25px;
        font-size: 1.6rem;
        border: 3px solid #FF8B07;
        background-size: 11px auto;
        background-position: right 20px center; } }

@media all and (max-width: 767px) {
  #js-product-detail__oyasumi-mask--sp {
    padding-top: 15px;
    margin-top: -15px; } }

.ss-form__title {
  display: flex; }
  .ss-form__title-required {
    flex-shrink: 0;
    align-self: flex-start; }
    #main .mailForm .mailTable th .ss-form__title-required {
      line-height: 28px;
      margin: 0 10px 0 0; }
    #main form .tableType.is-present th .ss-form__title-required {
      padding: 0 11px 1px;
      float: right;
      color: #fff;
      font-size: 1.2rem;
      font-weight: normal;
      background-color: #E50012;
      line-height: 28px;
      margin: 0 10px 0 0; }
  .ss-form__title-text {
    margin-right: 5px;
    line-height: 2; }

@media all and (max-width: 767px) {
  .ss-form__list {
    display: flex;
    align-items: center;
    margin: 0 -5px; } }

@media all and (max-width: 767px) {
  #main .mailForm .mailTable .nameUl .ss-form__list-item {
    display: flex;
    margin: 0 5px; }
    #main .mailForm .mailTable .nameUl .ss-form__list-item:first-child {
      margin: 0 5px; }
  .ss-form__list-item span {
    margin-right: 5px;
    flex-shrink: 0; }
  #main .mailForm .mailTable .nameUl .ss-form__list-item span {
    width: auto;
    min-width: 44px; } }

@media all and (max-width: 767px) {
  .ss-form__zip {
    display: flex;
    align-items: center; } }

@media all and (max-width: 767px) {
  .ss-form__birth {
    display: flex;
    align-items: center; }
    #main .mailForm .mailTable td .ss-form__birth .ss-form__birth-day {
      margin-right: 0; }
    #main .mailForm .mailTable td .ss-form__birth .wid03 {
      margin: 0; } }

@media all and (max-width: 767px) {
  #main .ss-form__actions {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center; }
  #main .step1_login .ss-form__actions {
    margin: 0 -5px; }
  #main .step1_login .comSubmit .ss-form__actions-item {
    width: auto;
    margin: 0 5px;
    flex-grow: 1; } }

@media all and (max-width: 767px) {
  #main .step1_unregistered .ss-form__submit-actions,
  #main .step3 .ss-form__submit-actions {
    margin-left: 14px;
    margin-right: 14px; }
  #main .step2 .ss-form__submit-actions {
    margin-left: -8px;
    margin-right: -8px; } }

@media all and (max-width: 767px) {
  #main .ss-form__submit-actions {
    display: flex; } }

@media all and (max-width: 767px) {
  #main .step1_unregistered .ss-form__submit-actions {
    margin-top: 40px; } }

@media all and (max-width: 767px) {
  #main .step1_unregistered .comSubmit .ss-form__submit-actions-item,
  #main .step2 .comSubmit .ss-form__submit-actions-item,
  #main .step3 .comSubmit .ss-form__submit-actions-item {
    margin: 0 8px;
    width: auto;
    flex-grow: 1; } }

#main .comSubmit li .ss-form__submit-button {
  font-size: 1em;
  line-height: 1.5;
  padding: 10px; }
  @media all and (max-width: 767px) {
    #main .comSubmit li .ss-form__submit-button {
      padding: 15px 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100% !important;
      min-width: 0; } }

.ss-form-error {
  display: flex;
  margin: 30px 0; }
  .ss-form-error__text {
    font-size: 1.6rem; }
    @media all and (max-width: 767px) {
      .ss-form-error__text {
        font-size: 2.0rem; } }
    .ss-form-error__text .attention {
      font-size: 1.6rem;
      display: inline-block; }

@media all and (max-width: 767px) {
  #main .ss-entry__actions {
    flex-wrap: nowrap;
    margin-left: -8px;
    margin-right: -8px; }
  #main .member2 .comSubmit .ss-entry__actions-item,
  #main .member3 .comSubmit .ss-entry__actions-item {
    flex-grow: 1;
    margin: 0 8px;
    width: auto; }
    #main .member2 .comSubmit .ss-entry__actions-item.return,
    #main .member3 .comSubmit .ss-entry__actions-item.return {
      width: auto;
      margin: 0 8px; } }

#main .member2 .ss-entry__text {
  color: inherit; }

@media all and (max-width: 767px) {
  .ss-cart__layout {
    display: flex;
    align-items: center;
    margin: 0 -10px; }
    .ss-cart__layout-item {
      margin: 0 10px; } }

@media all and (max-width: 767px) {
  #main .ss-shopping-login__title {
    margin-left: -18px;
    margin-right: -18px; } }

.ss-shopping-login__box {
  margin-top: 10px; }

.ss-shopping-login__contents-title {
  display: flex;
  align-items: center; }
  .ss-shopping-login__contents-title-icon {
    width: 20px;
    margin-right: 5px; }
  .ss-shopping-login__contents-title-text {
    flex-grow: 1; }

.ss-shopping-deliv__address {
  display: flex;
  flex-direction: column;
  margin-bottom: 21px; }
  @media (max-width: 767px) {
    .ss-shopping-deliv__address {
      margin: 0 25px 15px; } }
  .ss-shopping-deliv__address-label {
    position: relative; }
  .ss-shopping-deliv__address-radio {
    position: absolute;
    top: 25px;
    left: 20px;
    margin: 0; }
    #main .step1_unregistered .ss-shopping-deliv__address-radio[type="radio"] {
      display: block; }
  .ss-shopping-deliv__address-detail {
    flex-grow: 1;
    padding: 20px 20px 20px 53px;
    border: 1px solid #C5C5C4; }
    .ss-shopping-deliv__address-radio:checked + .ss-shopping-deliv__address-detail {
      background: #ffefef; }
  .ss-shopping-deliv__address-title {
    font-weight: bold; }
    #main .step1_unregistered form .ss-shopping-deliv__address-title {
      margin: 0; }
      @media (max-width: 767px) {
        #main .step1_unregistered form .ss-shopping-deliv__address-title {
          text-align: left; } }
  #main .step1_unregistered form .ss-shopping-deliv__address-text {
    margin: 0; }
    @media (max-width: 767px) {
      #main .step1_unregistered form .ss-shopping-deliv__address-text {
        text-align: left; } }
  .ss-shopping-deliv__address-actions {
    display: flex;
    justify-content: flex-end;
    margin: 10px -10px 0; }
    .ss-shopping-deliv__address-actions-item {
      margin: 0 10px; }
      .ss-shopping-deliv__address-actions-item a {
        text-decoration: underline; }
        .ss-shopping-deliv__address-actions-item a:hover {
          text-decoration: none; }

#main .step1_unregistered .ss-shopping-deliv__notes {
  font-size: 16px;
  line-height: 1.84; }

.ss-shopping-deliv__error {
  display: flex;
  margin: 30px 0; }
  .ss-shopping-deliv__error__text {
    font-size: 1.6rem; }
    @media all and (max-width: 767px) {
      .ss-shopping-deliv__error__text {
        font-size: 2.0rem; } }
  @media all and (max-width: 767px) {
    .ss-shopping-deliv__error-text .attention {
      font-size: 1.6rem; } }

@media (max-width: 767px) {
  .ss-shopping-payment__method-detail {
    display: none;
    padding: 5px 0; } }

@media (max-width: 767px) {
  .ss-shopping-payment__method-detail.is-open {
    display: block; } }

.ss-shopping-payment__title {
  display: flex; }
  #main .step2 .sec01 .ss-shopping-payment__title,
  #main .step2 .sec03 .ss-shopping-payment__title,
  #main .step2 .sec04 .ss-shopping-payment__title {
    margin-bottom: 16px; }
  .ss-shopping-payment__title.has-border {
    padding-bottom: 5px;
    border-bottom: solid 1px #C5C5C4; }
  .ss-shopping-payment__title-mark {
    font-size: 13px;
    padding: 7px;
    line-height: 1.2;
    margin-right: 10px;
    flex-shrink: 0;
    align-self: flex-start; }
    @media (max-width: 767px) {
      .ss-shopping-payment__title-mark {
        font-size: 10px;
        padding: 5px; } }
    .ss-shopping-payment__title-mark.is-required {
      color: #fff;
      background: #E50012; }
    .ss-shopping-payment__title-mark.is-optional {
      color: #555;
      background: #dcdcdc; }

.ss-shopping-payment__check-list {
  display: flex;
  flex-wrap: wrap; }
  #main form td .ss-shopping-payment__check-list {
    margin-left: -5px;
    margin-right: -5px; }
  #main .step2 .sec05 ul .ss-shopping-payment__check-list-item {
    width: 50%;
    box-sizing: border-box;
    padding: 0 5px;
    margin: 10px 0; }
    #main .step2 .sec05 ul .ss-shopping-payment__check-list-item:not(:last-child) {
      margin: 10px 0; }

.ss-shopping-payment__coupon {
  display: flex;
  margin-bottom: 35px; }
  .ss-shopping-payment__coupon-parts {
    display: flex; }

.ss-shopping-payment__modal {
  display: flex; }
  .ss-shopping-payment__modal-trigger {
    background: #ff8b06;
    padding: 0 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; }
    .ss-shopping-payment__modal-trigger.is-disabled {
      pointer-events: none; }
    .ss-shopping-payment__modal-trigger:visited {
      color: #fff; }
    .ss-shopping-payment__modal-trigger-text {
      color: #fff;
      white-space: nowrap; }
  .ss-shopping-payment__modal-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px; }
  .ss-shopping-payment__modal-button {
    display: block;
    text-align: center;
    background: #fff;
    padding: 10px 20px;
    border: solid 1px #C5C5C4;
    font-weight: bold;
    font-size: 1.4rem;
    cursor: pointer; }

.ss-shopping-payment__modaal-wrapper textarea[name='offer'] {
  width: 100%;
  min-height: 200px;
  border: none;
  padding: 0;
  font-size: 1.2em;
  overflow-y: scroll;
  transform: translateZ(0); }

.ss-shopping-payment__modaal-wrapper .modaal-content-container {
  padding: 50px 30px 20px; }
  @media all and (max-width: 767px) {
    .ss-shopping-payment__modaal-wrapper .modaal-content-container {
      padding: 50px 15px 20px; } }

.ss-shopping-payment__modaal-wrapper .modaal-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer; }
  .ss-shopping-payment__modaal-wrapper .modaal-close:before, .ss-shopping-payment__modaal-wrapper .modaal-close:after {
    background: #C5C5C4; }

.ss-shopping-payment__check-label {
  font-weight: bold; }

.ss-shopping-payment__check-notes {
  margin-top: 10px; }

.ss-shopping-complete {
  padding: 0 25px; }
  .ss-shopping-complete__title {
    color: #ff8b06;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.3; }
    .ss-shopping-complete__title:before, .ss-shopping-complete__title:after {
      content: '';
      width: 2px;
      height: 30px;
      background: #ff8b06;
      margin: 0 10px; }
    .ss-shopping-complete__title:before {
      transform: rotate(-30deg); }
    .ss-shopping-complete__title:after {
      transform: rotate(30deg); }
  .ss-shopping-complete__contents {
    margin: 30px 0; }
  .ss-shopping-complete__image {
    width: 100%;
    max-width: 750px;
    margin: 20px auto; }
  .ss-shopping-complete__message {
    text-align: center; }
    .ss-shopping-complete__message-label {
      font-size: 20px;
      font-weight: bold; }
    .ss-shopping-complete__message-detail {
      margin-top: 10px; }
    .ss-shopping-complete__message-text {
      display: flex;
      flex-direction: column; }
      .ss-shopping-complete__message-text--main {
        font-size: 16px; }
      .ss-shopping-complete__message-text--sub {
        font-size: 16px;
        margin-top: 5px; }

.ss-present__check-list {
  display: flex;
  flex-wrap: wrap; }
  #main form td .ss-present__check-list {
    margin-left: -5px;
    margin-right: -5px; }
  .ss-present__check-list-item {
    width: 50%;
    box-sizing: border-box;
    padding: 0 5px; }

.ss-nonmember__table tr.is-close {
  display: none; }

.attention {
  color: #E20012; }

#gFooter .fBox .fLink {
  margin-bottom: 15px; }

#gFooter .fBox .fLogo {
  padding-top: 20px; }

@media all and (max-width: 767px) {
  #gFooter .fBox .fLink {
    margin-bottom: 16px; }
  #main .comLoginBox .rBox .title {
    margin-bottom: 5px;
    font-size: 1.75rem; }
  #main .cart .ttl {
    font-size: 16px;
    line-height: 1.84; }
  #main .step1_login .txt {
    font-size: 16px;
    text-align: center; }
  #main .step1_login .btmTxt {
    font-size: 1.33333em; }
  #pagePath {
    margin: 11px 15px; }
  #main .member2 .formImg,
  #main .member3 .formImg,
  #main .member4 .formImg {
    margin: 0 -8px; }
  #main .step1_unregistered form .addTxt {
    text-align: center; }
    #main .step1_unregistered form .addTxt a {
      display: inline-block; }
  #main .step2 textarea {
    height: 100px; }
  #main .step2 .sec03 input {
    width: auto; }
  #main .step2 .tableBox td p {
    font-size: 1.4rem; }
  #main .step2 .sec04 .note {
    color: #000; }
  #main .step3 .txt {
    font-size: 16px;
    line-height: 1.84; }
  #main .step3 .topBox ul .num {
    width: 120px; }
  #main .cart .thStyle01 input[type="checkbox"] + label {
    font-weight: normal;
    font-size: 1.55rem; }
  .attention {
    font-size: 20px; } }

#main .comSubmit .ss-amazon-pay__cart-button {
  margin: 7px 20px;
  vertical-align: top; }

@media (max-width: 767px) {
  #main .step1_login .comSubmit .ss-amazon-pay__cart-button {
    margin-top: 14px; } }

#main .ss-amazon-pay__block.step1_login {
  margin: 0; }
  #main .ss-amazon-pay__block.step1_login table {
    margin: 0; }

#main .ss-amazon-pay__block.step3 {
  margin: 0 0 40px; }
  @media (max-width: 767px) {
    #main .ss-amazon-pay__block.step3 .topBox {
      margin: 0; } }
  @media (max-width: 767px) {
    #main .ss-amazon-pay__block.step3 .topBox .shopBox {
      margin: 0; } }
  #main .ss-amazon-pay__block.step3 .inner {
    margin: 0; }

@media (max-width: 767px) {
  #main .ss-amazon-pay #amazon_info .sec05 {
    margin: 0 0 40px; } }

#main .ss-amazon-pay #amazon_info .sec05 h3 {
  margin: 0 0 12px; }
  @media (max-width: 767px) {
    #main .ss-amazon-pay #amazon_info .sec05 h3 {
      margin: 0 0 12px; } }

.ss-amazon-pay .widget-container {
  width: 100% !important; }
  .ss-amazon-pay .widget-container iframe {
    width: 100%; }

.ss-form__zip {
  display: flex;
  align-items: center; }
  .ss-form__zip-button {
    cursor: pointer; }

.ss-form__notes {
  margin: 10px 0 0; }
  .ss-form__notes-link:link {
    text-decoration: underline; }
  .ss-form__notes-link:hover {
    text-decoration: underline; }

#main .ss-entry__personal-info .ss-entry__personal-info-title {
  margin: 44px 0 10px; }
  @media all and (max-width: 767px) {
    #main .ss-entry__personal-info .ss-entry__personal-info-title {
      margin: 23px 0 7px; } }

.ss-credit__info {
  text-align: center; }
  .ss-credit__info-title {
    color: #E20012;
    font-size: 18px;
    font-weight: bold; }
  .ss-credit__info-text, .ss-credit__info-text--security {
    font-size: 15px;
    margin-top: 5px; }
  .ss-credit__info-card {
    margin: 10px 0 20px;
    text-align: center; }
    .ss-credit__info-card-label {
      font-size: 15px; }
    .ss-credit__info-card-detail {
      width: 100%;
      max-width: 400px;
      margin: auto; }

.ss-credit__example {
  font-size: 90%;
  margin-top: 5px; }
  .ss-credit__example-text {
    font-size: 1em; }

.ss-credit__instruction {
  display: flex;
  justify-content: center;
  margin-bottom: 40px; }
  @media (max-width: 767px) {
    .ss-credit__instruction {
      margin-top: 20px;
      margin-bottom: 20px; } }
  @media all and (max-width: 767px) {
    .ss-credit__instruction-text {
      font-size: 15.5px; } }

@media all and (max-width: 767px) {
  #main .ss-credit__actions {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-left: -5px;
    margin-right: -5px; } }

#main .step2 .ss-credit__actions {
  margin-bottom: 40px; }
  @media all and (max-width: 767px) {
    #main .step2 .ss-credit__actions {
      margin-bottom: 30px; } }

@media all and (max-width: 767px) {
  #main .step2 .comSubmit .ss-credit__actions-item {
    width: auto;
    margin: 0 5px;
    flex-grow: 1; } }

@media all and (max-width: 767px) {
  #main .ss-credit__actions .ss-credit__actions-item .ss-credit__actions-button {
    padding: 15px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100% !important;
    min-width: 0;
    font-size: 15px; } }

.ss-credit__attention {
  display: flex;
  justify-content: center;
  text-align: center; }
  @media all and (max-width: 767px) {
    .ss-credit__attention {
      text-align: left; } }
  .ss-credit__attention-text {
    font-size: 18px; }

.ss-credit__register-card {
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #C4C4C3; }
  @media all and (max-width: 767px) {
    .ss-credit__register-card {
      margin-bottom: 0px;
      padding: 20px 15px; } }
  .ss-credit__register-card-label {
    font-size: 14px; }

@media all and (max-width: 767px) {
  #main .mailForm.is-module {
    margin-left: 0;
    margin-right: 0; } }

#main .mailForm .mailTable td input[type="text"]::placeholder {
  color: rgba(0, 0, 0, 0.3); }

#main .mailForm .mailTable td input[type="text"].is-size-auto {
  width: auto; }

@media all and (max-width: 767px) {
  #main .mailForm .mailTable td select.is-size-auto {
    width: auto; } }

#main .topBox .rCard .wrap a.ss-btn {
  margin-right: 10px;
  margin-left: 0; }

#main .topBox .rCard .addBox.ss-addBox {
  width: 320px;
  right: 0;
  left: auto; }

#main .topBox .rCard .addInner.ss-addInner {
  padding: 14px 28px; }

#main .topBox .rCard ul.ss-list {
  overflow: visible;
  display: flex;
  justify-content: center;
  margin: 10px -5px 0; }

#main .topBox .rCard li.ss-list-item {
  float: none;
  margin: 0 5px;
  white-space: nowrap; }

#main .topBox .rCard li.ss-list-item:nth-child(2) {
  float: none; }

#main .topBox .rCard li.ss-list-item a {
  color: #fff;
  background-color: #FF8B07; }

#main .topBox .rCard li.ss-list-item.close a {
  color: #FF8B07;
  border: 1px solid #FF8B07;
  background: #fff; }

@media (max-width: 767px) {
  #main .topBox .rCard .addBox.ss-addBox {
    width: auto;
    left: 0; }
  #main .topBox .rCard .addInner.ss-addInner {
    padding: 10px 12px 13px; } }

.ss-credit__register-card.is-another {
  padding: 0; }

.is-registered .ss-credit__register-card-contents {
  display: flex;
  align-items: center; }

.is-registered .ss-credit__register-card-label {
  margin-left: 10px; }
  .is-registered .ss-credit__register-card-label--em {
    font-weight: bold;
    font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.ss-credit__select-regist-card {
  display: flex;
  margin-left: 15px; }
  .ss-credit__select-regist-card-item {
    flex-grow: 1;
    display: flex;
    padding-right: 10px;
    margin: 5px 0; }
    .ss-credit__select-regist-card-item-text {
      word-break: break-all; }
  .ss-credit__select-regist-card input[type="button"] {
    color: #000;
    background: #dad7d3;
    border: none;
    padding: 5px 5px;
    border-radius: 2px; }
  .ss-credit__select-regist-card .btn_area {
    align-self: center; }

.ss-credit__register-card-accordion {
  padding: 20px; }
  .ss-credit__register-card-accordion-label {
    display: flex;
    justify-content: space-between;
    font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-weight: bold;
    font-size: 14px; }
    .ss-credit__register-card-accordion-label:after {
      content: "+";
      display: block;
      width: 1.5em;
      text-align: center; }
      .is-active .ss-credit__register-card-accordion-label:after {
        content: "-"; }
  .ss-credit__register-card-accordion-detail {
    display: none;
    padding: 20px 0 0; }
    .is-active .ss-credit__register-card-accordion-detail {
      display: block; }
  .ss-credit__register-card-accordion-title {
    margin-bottom: 10px; }
  .ss-credit__register-card-accordion-tetx {
    color: #999; }

.ss-mypage-change-card .information {
  margin: 10px 0; }

.ss-mypage-change-card .attention {
  font-size: 1em; }
  @media (max-width: 767px) {
    .ss-mypage-change-card .attention {
      font-size: 1em; } }

.ss-mypage-change-card .ss-credit__info {
  text-align: left; }

.ss-mypage-change-card-layout {
  margin-bottom: 40px;
  border-bottom: 1px solid #C4C4C3; }
  @media (max-width: 767px) {
    .ss-mypage-change-card-layout {
      margin: 0 17px 40px; } }

.ss-mypage-change-card-title {
  text-align: center;
  font-size: 1.9rem;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ss-mypage-change-card-title {
      font-size: 1.8rem; } }

.ss-mypage-change-card-table {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
  border-collapse: collapse; }
  .ss-mypage-change-card-table th {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #C4C4C3;
    background: #f4efeb;
    padding: 11px 15px; }
    @media (max-width: 767px) {
      .ss-mypage-change-card-table th {
        font-size: 1.5rem;
        padding: 15px 16px; } }
  .ss-mypage-change-card-table td {
    border: 1px solid #C4C4C3; }
  .ss-mypage-change-card-table p {
    text-align: center;
    padding: 40px 15px;
    font-size: 15px; }
    @media (max-width: 767px) {
      .ss-mypage-change-card-table p {
        padding: 40px 16px; } }
  .ss-mypage-change-card-table.is-center {
    text-align: left; }

.ss-mypage-change-card-data {
  display: flex; }
  .ss-mypage-change-card-data.is-checked {
    background: #ffefef; }
  .ss-mypage-change-card-data__input {
    width: 80px;
    flex-shrink: 0;
    border-right: 1px solid #C4C4C3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 15px;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .ss-mypage-change-card-data__input {
        width: 60px;
        padding: 15px 16px; } }
  .ss-mypage-change-card-data__key {
    width: 60px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767px) {
      .ss-mypage-change-card-data__key {
        width: 40px; } }
  .ss-mypage-change-card-data__detail {
    flex-grow: 1;
    padding: 11px 15px;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .ss-mypage-change-card-data__detail {
        padding: 15px 16px; } }

#main .comTopBox ul.ss-mypage-navi {
  margin: 0 -10px;
  justify-content: flex-start; }
  #main .comTopBox ul.ss-mypage-navi li {
    padding: 0 10px; }

@media (max-width: 767px) {
  #main .comSubmit li.ss-mypage-change-card__actions-item {
    width: 100%;
    margin: 0; } }

#main .comSubmit li a.ss-mypage-change-card__button {
  width: 100%;
  max-width: 240px;
  margin: 0 auto 40px;
  background: #949494;
  border-color: #949494;
  color: #fff; }

.ss-tab {
  margin: 25px 0 10px; }
  .ss-tab__notes {
    color: #000;
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: -15px;
    width: 50%;
    text-align: center;
    white-space: nowrap; }
    @media (max-width: 767px) {
      .ss-tab__notes {
        font-size: 15px; } }
    .ss-tab__notes.is-right {
      margin-left: auto; }
    .ss-tab__notes.is-left {
      margin-right: auto; }
  .ss-tab__label {
    display: flex;
    padding: 0 4px; }
    .ss-tab__label-item {
      width: calc(48% - 8px);
      margin: 0 4px;
      border: #e8e8e8 solid 2px;
      border-bottom: none;
      background: #e8e8e8;
      padding: 4px 15px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 10px 10px 0 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .ss-tab__label-item.is-active {
        width: calc(52% - 8px);
        color: #fff;
        font-size: 18px;
        background: #FF8B07;
        border: #FF8B07 solid 2px;
        border-bottom: none;
        position: relative;
        transform: translateY(-6px); }
        .ss-tab__label-item.is-active:after {
          content: "";
          width: calc(100% + 4px);
          height: 6px;
          position: absolute;
          bottom: -6px;
          left: -2px;
          background: #FF8B07; }
      .ss-tab__label-item:last-child {
        position: relative; }
      .ss-tab__label-item-icon {
        width: 30px;
        position: absolute;
        left: 10px;
        top: -2px; }
  .ss-tab__contents-item {
    padding: 15px 25px;
    background: #f6f1eb;
    display: none; }
    @media (max-width: 767px) {
      .ss-tab__contents-item {
        padding: 15px; } }
    .ss-tab__contents-item.is-active {
      display: block;
      border: #FF8B07 solid 2px; }
  .ss-tab__contents-comment {
    color: #E20012;
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      .ss-tab__contents-comment {
        font-size: 14px; } }
  @media (max-width: 767px) {
    #main .topBox .rBox .styleTable .ss-tab__table-th {
      width: 31.5%; } }
  .ss-tab .ss-product-detail__price {
    justify-content: flex-start;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .ss-tab .ss-product-detail__price {
        justify-content: center; } }

@keyframes flash {
  50% {
    opacity: 0.25; } }

#main .ss-slider {
  margin: 44px 0 69px; }
  @media (max-width: 767px) {
    #main .ss-slider {
      margin: 33px 0 33px; } }
  @media (max-width: 767px) {
    #main .ss-slider .slider .slick-dots {
      margin-top: 12px; } }
  @media (max-width: 767px) {
    #main .ss-slider .slider .slick-dots li {
      width: 12px;
      height: 12px;
      background: url("../../img/top/dot02.png") no-repeat;
      background-size: 12px 12px !important;
      margin: 0 8px; } }
  #main .ss-slider .slider .slick-dots li.slick-active {
    background: url("../../img/top/dot01.png") no-repeat; }
  @media (max-width: 767px) {
    #main .ss-slider .arrow {
      display: block;
      margin: 0;
      width: auto;
      position: static; } }
  @media (max-width: 767px) {
    #main .ss-slider .prev {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-100%); } }
  @media (max-width: 767px) {
    #main .ss-slider .next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-100%); } }

.ss-campaign {
  margin-bottom: 55px; }
  @media (max-width: 767px) {
    .ss-campaign {
      margin-bottom: 25px; } }
  .ss-campaign__body {
    padding: 0 20px;
    max-width: 970px;
    margin: 0 auto; }
  .ss-campaign__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    @media (max-width: 767px) {
      .ss-campaign__list {
        flex-direction: column; } }
    .ss-campaign__list-item {
      width: 50%;
      padding: 0 10px;
      box-sizing: border-box;
      flex-grow: 1;
      margin: 0 0 10px; }
      @media (max-width: 767px) {
        .ss-campaign__list-item {
          width: auto; } }
  .ss-campaign__link {
    display: block;
    text-align: center; }

.ss-media {
  margin: 55px 0 63px; }
  @media (max-width: 767px) {
    .ss-media {
      margin: 25px 0 35px; } }
  .ss-media__title img {
    width: 100%; }
  .ss-media__body {
    padding: 0 20px;
    max-width: 970px;
    margin: 0 auto; }
  .ss-media__contents {
    border: solid 3px #c6a45c;
    padding: 18px;
    position: relative; }
    @media (max-width: 767px) {
      .ss-media__contents {
        border: solid 1px #c6a45c;
        padding: 10px 16px; } }
  .ss-media__item {
    padding: 0 18px; }
    @media (max-width: 767px) {
      .ss-media__item {
        padding: 0 2px; } }
  .ss-media .slick-prev,
  .ss-media .slick-next {
    position: absolute;
    transform: translateY(-50%);
    width: 10px;
    height: 0;
    padding-top: 44px;
    overflow: hidden;
    border: none;
    background: none;
    cursor: pointer; }
  .ss-media .slick-prev {
    top: 50%;
    left: -8px;
    background: url("../../img/common/prev.png") no-repeat center;
    background-size: cover; }
    @media (max-width: 767px) {
      .ss-media .slick-prev {
        left: -14px; } }
  .ss-media .slick-next {
    top: 50%;
    right: -8px;
    background: url("../../img/common/next.png") no-repeat center;
    background-size: cover; }
    @media (max-width: 767px) {
      .ss-media .slick-next {
        right: -14px; } }

.ss-recommend .ss-aw-recommend__title {
  display: flex;
  flex-direction: column; }
  #main .ss-recommend .ss-aw-recommend__title {
    border: none;
    padding: 0;
    margin-bottom: 22px; }
    @media (max-width: 767px) {
      #main .ss-recommend .ss-aw-recommend__title {
        margin-bottom: 13px; } }
  .ss-recommend .ss-aw-recommend__title:after {
    content: "";
    display: block;
    width: 422px;
    height: 17px;
    background: url("../../img/top/line_title.png") no-repeat center; }
    @media (max-width: 767px) {
      .ss-recommend .ss-aw-recommend__title:after {
        width: 349.5px;
        height: 14px;
        background: url("../../img/top/line_title_sp.png") no-repeat center;
        background-size: cover; } }

.ss-recommend .ss-aw-recommend__title-text {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0; }
  @media (max-width: 767px) {
    .ss-recommend .ss-aw-recommend__title-text {
      flex-direction: row;
      letter-spacing: 0.04em;
      font-size: 20px; } }
  .ss-recommend .ss-aw-recommend__title-text:before {
    content: "";
    display: block;
    width: 28px;
    height: 26px;
    background: url("../../img/top/ico_title_recommend.png") no-repeat center;
    margin: 0 0 5px; }
    @media (max-width: 767px) {
      .ss-recommend .ss-aw-recommend__title-text:before {
        width: 25px;
        height: 23px;
        margin: 0 10px 0 0;
        background-size: cover; } }

#main .ss-recommend .ss-aw-recommend__list {
  margin-bottom: 26px; }
  @media (max-width: 767px) {
    #main .ss-recommend .ss-aw-recommend__list {
      margin-bottom: 0; } }

.ss-recommend .ss-aw-recommend__list-item:nth-child(n + 7) {
  display: none; }

@media (max-width: 767px) {
  .ss-recommend .ss-aw-recommend__list-item:nth-child(n + 5) {
    display: none; } }

.ss-recommend .is-active .ss-aw-recommend__list-item:nth-child(n + 7) {
  display: block; }

@media (max-width: 767px) {
  .ss-recommend .is-active .ss-aw-recommend__list-item:nth-child(n + 5) {
    display: block; } }

.ss-recommend__actions-button {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  border: #b4b4b3 solid 3px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  position: relative;
  cursor: pointer;
  background: #fff;
  box-sizing: border-box; }
  @media (max-width: 767px) {
    .ss-recommend__actions-button {
      border: #b4b4b3 solid 2px;
      border-radius: 5px; } }
  .ss-recommend__actions-button-text {
    font-size: 20px;
    letter-spacing: 0.02em;
    font-feature-settings: "palt"; }
    .ss-recommend__actions-button-text.is-open {
      display: block; }
      .is-active .ss-recommend__actions-button-text.is-open {
        display: none; }
    .ss-recommend__actions-button-text.is-close {
      display: none; }
      .is-active .ss-recommend__actions-button-text.is-close {
        display: block; }
    @media (max-width: 767px) {
      .ss-recommend__actions-button-text {
        font-size: 17px;
        letter-spacing: 0.05em; } }
  .ss-recommend__actions-button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 141px;
    display: block;
    line-height: 1; }
    @media (max-width: 767px) {
      .ss-recommend__actions-button-icon {
        width: 14.5px;
        margin: 0 0 0 118px; } }
    .is-active .ss-recommend__actions-button-icon {
      transform: translateY(-50%) rotate(180deg); }

.ss-recommend .ss-aw-recommend__actions-item {
  font-size: 16px; }
  @media (max-width: 767px) {
    .ss-recommend .ss-aw-recommend__actions-item {
      font-size: 13px; } }

.ss-commitment {
  margin-top: 73px;
  background: #ffedd9; }
  @media (max-width: 767px) {
    .ss-commitment {
      margin-top: 39px; } }
  .ss-commitment__body {
    padding: 0 20px;
    max-width: 970px;
    margin: 0 auto; }
  .ss-commitment__heading {
    padding: 42px 0 0; }
    @media (max-width: 767px) {
      .ss-commitment__heading {
        padding: 24px 0 0; } }
  .ss-commitment__title {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .ss-commitment__title:after {
      content: "";
      display: block;
      width: 422px;
      height: 17px;
      background: url("../../img/top/line_title.png") no-repeat center; }
      @media (max-width: 767px) {
        .ss-commitment__title:after {
          width: 349.5px;
          height: 14px;
          background: url("../../img/top/line_title_sp.png") no-repeat center;
          background-size: cover; } }
    .ss-commitment__title-text {
      font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
      font-size: 20px;
      letter-spacing: 0.05em;
      font-weight: bold;
      font-feature-settings: "palt";
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 767px) {
        .ss-commitment__title-text {
          flex-direction: row;
          font-size: 20px; } }
      .ss-commitment__title-text:before {
        content: "";
        display: block;
        width: 33px;
        height: 30px;
        background: url("../../img/top/ico_title_commitment.png") no-repeat center;
        margin: 0 0 5px; }
        @media (max-width: 767px) {
          .ss-commitment__title-text:before {
            width: 28.5px;
            height: 25.5px;
            background-size: cover;
            margin: 0 10px 0 0; } }
  .ss-commitment__contents {
    padding: 32px 0 65px; }
    @media (max-width: 767px) {
      .ss-commitment__contents {
        padding: 11px 0 27px; } }
  .ss-commitment__list {
    display: flex;
    margin: 0 -10px; }
    @media (max-width: 767px) {
      .ss-commitment__list {
        margin: 0 -4.5px; } }
    .ss-commitment__list-item {
      width: 50%;
      padding: 0 10px;
      box-sizing: border-box; }
      @media (max-width: 767px) {
        .ss-commitment__list-item {
          padding: 0 4.5px; } }
  .ss-commitment__item-link {
    display: flex; }
    @media (max-width: 767px) {
      .ss-commitment__item-link {
        flex-direction: column; } }
  .ss-commitment__item-image {
    width: 54.28571%;
    margin: 0;
    flex-shrink: 0; }
    @media (max-width: 767px) {
      .ss-commitment__item-image {
        width: auto; } }
  .ss-commitment__item-contents {
    margin: 0 0 0 20px;
    padding: 11px 0 0; }
    @media (max-width: 767px) {
      .ss-commitment__item-contents {
        margin: 0 0 0 0px; } }
  .ss-commitment__item-title {
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-size: 18px; }
    @media (max-width: 767px) {
      .ss-commitment__item-title {
        font-size: 15px; } }
  .ss-commitment__item-text {
    font-size: 16px;
    line-height: 1.375;
    margin: 10px 0 0; }
    @media (max-width: 767px) {
      .ss-commitment__item-text {
        font-size: 15px;
        line-height: 1.5;
        margin: 4px 0 0; } }
  .ss-commitment__item-actions {
    font-size: 16px;
    text-align: right;
    margin: 20px 0 0;
    padding: 0 14px 0 0; }
    @media (max-width: 767px) {
      .ss-commitment__item-actions {
        font-size: 13px;
        margin: 0;
        padding: 0; } }

.ss-columns {
  margin-top: 131px;
  background: #ffebeb;
  padding-bottom: 47px; }
  @media (max-width: 767px) {
    .ss-columns {
      margin-top: 98px;
      padding-bottom: 28px; } }
  .ss-columns__body {
    padding: 0 20px;
    max-width: 970px;
    margin: 0 auto; }
  .ss-columns__heading {
    text-align: center; }
  .ss-columns__title {
    transform: translateY(-79px); }
    @media (max-width: 767px) {
      .ss-columns__title {
        transform: translateY(-68px);
        max-width: 256px;
        margin: auto; } }
  .ss-columns__contents {
    margin: -46px 0 0; }
    @media (max-width: 767px) {
      .ss-columns__contents {
        margin: -55px 0 0; } }
  .ss-columns__banner {
    margin: -46px 0 0;
    text-align: center; }
  .ss-columns__date {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 27px 0 0; }
    @media (max-width: 767px) {
      .ss-columns__date {
        justify-content: flex-start;
        margin: 12px 0 0; } }
    .ss-columns__date-icon {
      width: 70px;
      height: 25px;
      line-height: 25px;
      color: #fff;
      background: #e90000;
      text-align: center;
      font-size: 18px;
      letter-spacing: 0.05em;
      margin: 0 15px 0 0; }
      @media (max-width: 767px) {
        .ss-columns__date-icon {
          font-size: 10px;
          width: 44px;
          height: 16px;
          line-height: 16px; } }
    .ss-columns__date-text {
      font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
      font-size: 18px;
      font-weight: bold; }
      @media (max-width: 767px) {
        .ss-columns__date-text {
          font-size: 15px; } }
  .ss-columns__list {
    display: flex;
    margin: 25px -11px 0; }
    @media (max-width: 767px) {
      .ss-columns__list {
        flex-direction: column;
        margin-top: 12px; } }
    .ss-columns__list-item {
      width: 25%;
      padding: 0 11px;
      box-sizing: border-box;
      display: flex; }
      @media (max-width: 767px) {
        .ss-columns__list-item {
          width: auto;
          margin-bottom: 15px;
          display: block; } }
  .ss-columns__item {
    display: flex; }
    @media (max-width: 767px) {
      .ss-columns__item {
        display: block; } }
    .ss-columns__item-link {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media (max-width: 767px) {
        .ss-columns__item-link {
          flex-direction: row; } }
    .ss-columns__item-label {
      font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif;
      font-size: 16px;
      text-align: center;
      padding: 4px 10px; }
      @media (max-width: 767px) {
        .ss-columns__item-label {
          font-size: 15px;
          padding: 3px 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } }
      .ss-columns__item-label.is-pc {
        display: block; }
        @media (max-width: 767px) {
          .ss-columns__item-label.is-pc {
            display: none; } }
      .ss-columns__item-label.is-sp {
        display: none; }
        @media (max-width: 767px) {
          .ss-columns__item-label.is-sp {
            display: block; } }
      .ss-columns__list-item:nth-child(1) .ss-columns__item-label {
        background: #eac4b2; }
      .ss-columns__list-item:nth-child(2) .ss-columns__item-label {
        background: #8fb95b; }
      .ss-columns__list-item:nth-child(3) .ss-columns__item-label {
        background: #e9bd63; }
      .ss-columns__list-item:nth-child(4) .ss-columns__item-label {
        background: #99c2bb; }
    .ss-columns__item-image {
      margin: 14px 0 0;
      flex-shrink: 0; }
      @media (max-width: 767px) {
        .ss-columns__item-image {
          margin: 0;
          width: 42.68657%; } }
      .ss-columns__item-image-box {
        width: 100%;
        height: 0;
        padding-top: 83.33333%;
        position: relative;
        overflow: hidden;
        background: #000; }
        .ss-columns__item-image-box img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
    .ss-columns__item-contents {
      background: #fff;
      padding: 16px 10px;
      flex-grow: 1; }
      @media (max-width: 767px) {
        .ss-columns__item-contents {
          padding: 7px 10px;
          width: calc(100% - 42.68657%); } }
    .ss-columns__item-title {
      font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
      font-size: 16px;
      font-weight: bold;
      min-height: 3em; }
      @media (max-width: 767px) {
        .ss-columns__item-title {
          display: none; } }
    .ss-columns__item-date {
      color: #9a9a9a;
      font-size: 12px;
      margin: 4px 0 0; }
      @media (max-width: 767px) {
        .ss-columns__item-date {
          display: none; } }
    .ss-columns__item-text {
      font-size: 16px;
      line-height: 1.83333;
      margin: 3px 0 0; }
      @media (max-width: 767px) {
        .ss-columns__item-text {
          font-size: 13px;
          line-height: 1.5;
          margin: 7px 0 0 9px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden; } }
    .ss-columns__item-actions {
      font-size: 16px;
      text-align: right;
      margin: 8px 0 0; }
      @media (max-width: 767px) {
        .ss-columns__item-actions {
          font-size: 13px;
          margin: 1px 0 0; } }
  .ss-columns__actions {
    margin: 45px 0 0; }
    @media (max-width: 767px) {
      .ss-columns__actions {
        margin: 9px 0 0; } }
    .ss-columns__actions-button {
      display: block;
      font-size: 20px;
      line-height: 54px;
      width: 100%;
      max-width: 400px;
      height: 54px;
      margin: 0 auto;
      color: #fff;
      background: #f18e8a;
      text-align: center;
      border-radius: 27px;
      padding: 0 64px;
      box-sizing: border-box;
      position: relative; }
      @media (max-width: 767px) {
        .ss-columns__actions-button {
          font-size: 17px;
          height: 45px;
          line-height: 45px;
          max-width: 275px;
          padding: 0 36px 0 23px; } }
      .ss-columns__actions-button:visited, .ss-columns__actions-button:hover, .ss-columns__actions-button:link {
        color: #fff; }
      .ss-columns__actions-button:after {
        position: absolute;
        top: 50%;
        right: 44px;
        transform: translateY(-50%);
        content: "";
        display: block;
        width: 10px;
        height: 17px;
        background: url("../../img/top/arrow-column.png") no-repeat center; }
        @media (max-width: 767px) {
          .ss-columns__actions-button:after {
            width: 8px;
            height: 14px;
            right: 23px;
            background-size: cover; } }
  .ss-columns__article {
    padding: 14px 0 0; }
    @media (max-width: 767px) {
      .ss-columns__article {
        padding: 0; } }
    .ss-columns__article-link {
      display: flex; }
      @media (max-width: 767px) {
        .ss-columns__article-link {
          flex-direction: column; } }
    .ss-columns__article-image {
      width: 582px;
      width: 62.58065%;
      margin: 0;
      flex-shrink: 0;
      text-align: center; }
      @media (max-width: 767px) {
        .ss-columns__article-image {
          width: auto;
          margin: 4px 0 0; } }
    .ss-columns__article-contents {
      margin: 0 0 0 44px; }
      @media (max-width: 767px) {
        .ss-columns__article-contents {
          margin: 0; } }
    .ss-columns__article-info {
      display: flex;
      flex-direction: column;
      font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
      font-weight: bold; }
      @media (max-width: 767px) {
        .ss-columns__article-info {
          align-items: center; } }
      .ss-columns__article-info.is-pc {
        display: flex; }
        @media (max-width: 767px) {
          .ss-columns__article-info.is-pc {
            display: none; } }
      .ss-columns__article-info.is-sp {
        display: none; }
        @media (max-width: 767px) {
          .ss-columns__article-info.is-sp {
            display: flex; } }
      .ss-columns__article-info--sub {
        font-size: 16px; }
        @media (max-width: 767px) {
          .ss-columns__article-info--sub {
            font-size: 15px; } }
      .ss-columns__article-info--main {
        font-size: 20px; }
        @media (max-width: 767px) {
          .ss-columns__article-info--main {
            font-size: 18px; } }
    .ss-columns__article-date {
      display: flex;
      align-items: center;
      margin: 14px 0 0; }
      @media (max-width: 767px) {
        .ss-columns__article-date {
          justify-content: flex-start;
          margin: 15px 0 0; } }
      .ss-columns__article-date-icon {
        width: 70px;
        height: 25px;
        line-height: 25px;
        color: #fff;
        background: #e90000;
        text-align: center;
        font-size: 18px;
        letter-spacing: 0.05em;
        margin: 0 15px 0 0; }
        @media (max-width: 767px) {
          .ss-columns__article-date-icon {
            font-size: 10px;
            width: 44px;
            height: 16px;
            line-height: 16px; } }
      .ss-columns__article-date-text {
        font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
        font-size: 18px;
        font-weight: bold; }
        @media (max-width: 767px) {
          .ss-columns__article-date-text {
            font-size: 15px; } }
    .ss-columns__article-text {
      font-size: 16px;
      line-height: 1.875;
      margin: 5px 0 0; }
      @media (max-width: 767px) {
        .ss-columns__article-text {
          font-size: 13px;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden; } }
    .ss-columns__article-actions {
      font-size: 16px;
      text-align: right;
      margin: 5px 0 0; }
      @media (max-width: 767px) {
        .ss-columns__article-actions {
          font-size: 13px; } }

.ss-feature {
  margin-top: 80px; }
  @media (max-width: 767px) {
    .ss-feature {
      margin-top: 38px; } }
  .ss-feature__body {
    padding: 0 20px;
    max-width: 970px;
    margin: 0 auto; }
  .ss-feature__title {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .ss-feature__title:after {
      content: "";
      display: block;
      width: 422px;
      height: 17px;
      background: url("../../img/top/line_title.png") no-repeat center; }
      @media (max-width: 767px) {
        .ss-feature__title:after {
          width: 349.5px;
          height: 14px;
          background: url("../../img/top/line_title_sp.png") no-repeat center;
          background-size: cover; } }
    .ss-feature__title-text {
      font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
      font-size: 20px;
      font-weight: normal;
      font-feature-settings: "palt";
      display: flex;
      align-items: center;
      justify-content: center; }
      .ss-feature__title-text:before, .ss-feature__title-text:after {
        content: "";
        display: block;
        width: 25px;
        height: 25px;
        background: url("../../img/top/ico_title_feature.png") no-repeat center;
        margin: 0 24px; }
        @media (max-width: 767px) {
          .ss-feature__title-text:before, .ss-feature__title-text:after {
            width: 22px;
            height: 22px;
            background-size: cover;
            margin: 0 19px; } }
    .ss-feature__title-specer {
      width: 0.5em; }
  .ss-feature__contents {
    padding: 32px 0 21px; }
    @media (max-width: 767px) {
      .ss-feature__contents {
        padding: 14px 0 21px; } }
  .ss-feature__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px; }
    @media (max-width: 767px) {
      .ss-feature__list {
        flex-direction: column;
        margin: 0; } }
    .ss-feature__list-item {
      width: 50%;
      padding: 0 12px 12px;
      box-sizing: border-box; }
      @media (max-width: 767px) {
        .ss-feature__list-item {
          width: auto;
          padding: 0 5px 20px; } }
      .ss-feature__list-item:nth-child(n + 5) {
        display: none; }
        .is-active .ss-feature__list-item:nth-child(n + 5) {
          display: block; }
      @media (max-width: 767px) {
        .ss-feature__list-item:nth-child(n + 3) {
          display: none; }
          .is-active .ss-feature__list-item:nth-child(n + 3) {
            display: block; } }
  .ss-feature__banner {
    text-align: center; }
  .ss-feature__actions {
    margin: 26px 0 0; }
    @media (max-width: 767px) {
      .ss-feature__actions {
        margin: 4px 0 0; } }
    .ss-feature__actions-button {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      display: block;
      border: #b4b4b3 solid 3px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 10px;
      position: relative;
      cursor: pointer;
      background: #fff;
      box-sizing: border-box; }
      @media (max-width: 767px) {
        .ss-feature__actions-button {
          border: #b4b4b3 solid 2px;
          border-radius: 5px; } }
      .ss-feature__actions-button-text {
        font-size: 20px;
        letter-spacing: 0.02em;
        font-feature-settings: "palt"; }
        .ss-feature__actions-button-text.is-open {
          display: block; }
          .is-active .ss-feature__actions-button-text.is-open {
            display: none; }
        .ss-feature__actions-button-text.is-close {
          display: none; }
          .is-active .ss-feature__actions-button-text.is-close {
            display: block; }
        @media (max-width: 767px) {
          .ss-feature__actions-button-text {
            font-size: 17px;
            letter-spacing: 0.05em; } }
      .ss-feature__actions-button-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        margin: 0 0 0 141px;
        display: block;
        line-height: 1; }
        @media (max-width: 767px) {
          .ss-feature__actions-button-icon {
            width: 14.5px;
            margin: 0 0 0 118px; } }
        .is-active .ss-feature__actions-button-icon {
          transform: translateY(-50%) rotate(180deg); }

#main .ss-ranking {
  margin-top: 79px; }
  @media (max-width: 767px) {
    #main .ss-ranking {
      margin-top: 39px;
      margin-bottom: 45px; } }

.ss-ranking__title {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 20px;
  letter-spacing: 0.15em;
  font-weight: bold;
  font-feature-settings: "palt";
  display: flex;
  flex-direction: column;
  align-items: center; }
  .comProduct .ss-ranking__title {
    border: none; }
  .comProduct.numProduct .ss-ranking__title {
    padding: 0; }
  .ss-ranking__title:after {
    content: "";
    display: block;
    width: 422px;
    height: 17px;
    background: url("../../img/top/line_title.png") no-repeat center; }
    @media (max-width: 767px) {
      .ss-ranking__title:after {
        width: 349.5px;
        height: 14px;
        background: url("../../img/top/line_title_sp.png") no-repeat center;
        background-size: cover; } }
  .ss-ranking__title-text {
    flex-direction: column;
    align-items: center;
    margin: 0; }
    .comProduct.numProduct h2 .ss-ranking__title-text {
      display: flex;
      padding: 0;
      background: none; }
    @media (max-width: 767px) {
      .ss-ranking__title-text {
        flex-direction: row; } }
    .ss-ranking__title-text:before {
      content: "";
      display: block;
      width: 32px;
      height: 22px;
      background: url("../../img/top/ico_title_ranking.png") no-repeat center;
      margin: 0 0 8px; }
      @media (max-width: 767px) {
        .ss-ranking__title-text:before {
          width: 27px;
          height: 19px;
          background-size: cover;
          margin: 0 10px 0 0; } }

.ss-ranking__actions {
  margin: 34px 0 0; }
  @media (max-width: 767px) {
    .ss-ranking__actions {
      margin: 9px 0 0; } }
  .ss-ranking__actions-button {
    display: block;
    font-size: 20px;
    line-height: 54px;
    width: 100%;
    max-width: 400px;
    height: 54px;
    margin: 0 auto;
    color: #000000;
    background: #eee3d6;
    text-align: center;
    border-radius: 27px;
    padding: 0 64px;
    box-sizing: border-box;
    position: relative; }
    @media (max-width: 767px) {
      .ss-ranking__actions-button {
        font-size: 17px;
        height: 45px;
        line-height: 45px;
        max-width: 275px;
        padding: 0 36px 0 36px; } }
    .ss-ranking__actions-button:visited, .ss-ranking__actions-button:hover, .ss-ranking__actions-button:link {
      color: #000000; }
    .ss-ranking__actions-button:after {
      position: absolute;
      top: 50%;
      right: 44px;
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 10px;
      height: 17px;
      background: url("../../img/top/arrow-column-b.png") no-repeat center; }
      @media (max-width: 767px) {
        .ss-ranking__actions-button:after {
          width: 8px;
          height: 14px;
          right: 23px;
          background-size: cover; } }

.ss-checked-items {
  margin-top: 97px;
  margin-bottom: 75px; }
  @media (max-width: 767px) {
    .ss-checked-items {
      margin-top: 45px;
      margin-bottom: 33px; } }
  .ss-checked-items__title {
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    letter-spacing: 0.175em;
    font-weight: bold;
    font-feature-settings: "palt";
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 767px) {
      .ss-checked-items__title {
        letter-spacing: 0.04em; } }
    .comProduct .ss-checked-items__title {
      padding-bottom: 9px;
      margin-bottom: 32px; }
      @media (max-width: 767px) {
        .comProduct .ss-checked-items__title {
          border: none;
          padding-bottom: 0;
          margin-bottom: 20px; } }
    @media (max-width: 767px) {
      .ss-checked-items__title:after {
        content: "";
        display: block;
        width: 349.5px;
        height: 14px;
        background: url("../../img/top/line_title_sp.png") no-repeat center;
        background-size: cover; } }
    .ss-checked-items__title-text {
      flex-direction: column;
      align-items: center;
      margin: 0; }
      .comProduct h2 .ss-checked-items__title-text {
        display: flex;
        padding: 0;
        background: none; }
      @media (max-width: 767px) {
        .ss-checked-items__title-text {
          flex-direction: row;
          font-size: 20px; } }
      .ss-checked-items__title-text:before {
        content: "";
        display: block;
        width: 24px;
        height: 20px;
        background: url("../../img/top/ico_title_checked.png") no-repeat center;
        margin: 0 0 8px; }
        @media (max-width: 767px) {
          .ss-checked-items__title-text:before {
            width: 19px;
            height: 15.5px;
            background-size: cover;
            margin: 0 10px 0 0; } }

.ss-event {
  margin-top: 75px; }
  @media (max-width: 767px) {
    .ss-event {
      margin-top: 33px; } }
  .ss-event__body {
    padding: 0 20px;
    max-width: 970px;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .ss-event__body {
        padding: 0;
        max-width: 375px; } }
  .ss-event__contents {
    min-height: 788px;
    background: url("../../img/top/bg_event.jpg") no-repeat top center;
    background-size: cover;
    box-sizing: border-box;
    padding: 175px 0 0; }
    @media (max-width: 767px) {
      .ss-event__contents {
        min-height: 574px;
        background: url("../../img/top/bg_event_sp.jpg") no-repeat top center;
        background-size: cover;
        padding: 120px 0 0; } }
  .ss-event__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-weight: bold; }
    .ss-event__text--main {
      font-size: 20px; }
      @media (max-width: 767px) {
        .ss-event__text--main {
          font-size: 19px; } }
    .ss-event__text--sub {
      font-size: 16px;
      margin: 5px 0 0; }
      @media (max-width: 767px) {
        .ss-event__text--sub {
          font-size: 15px;
          margin: 0; } }
  .ss-event__actions {
    margin: 460px 0 0; }
    @media (max-width: 767px) {
      .ss-event__actions {
        margin: 334px 0 0; } }
    .ss-event__actions-button {
      display: block;
      font-size: 20px;
      line-height: 54px;
      width: 100%;
      max-width: 400px;
      height: 54px;
      margin: 0 auto;
      color: #fff;
      background: #56b285;
      text-align: center;
      border-radius: 27px;
      padding: 0 64px;
      box-sizing: border-box;
      position: relative; }
      @media (max-width: 767px) {
        .ss-event__actions-button {
          font-size: 17px;
          height: 45px;
          line-height: 45px;
          max-width: 275px;
          padding: 0 36px 0 36px; } }
      .ss-event__actions-button:visited, .ss-event__actions-button:hover, .ss-event__actions-button:link {
        color: #fff; }
      .ss-event__actions-button:after {
        position: absolute;
        top: 50%;
        right: 44px;
        transform: translateY(-50%);
        content: "";
        display: block;
        width: 10px;
        height: 17px;
        background: url("../../img/top/arrow-column.png") no-repeat center; }
        @media (max-width: 767px) {
          .ss-event__actions-button:after {
            width: 8px;
            height: 14px;
            right: 23px;
            background-size: cover; } }

.ss-new {
  margin-top: 74px; }
  @media (max-width: 767px) {
    .ss-new {
      margin-top: 39px; } }
  .ss-new__body {
    padding: 0 20px;
    max-width: 970px;
    margin: 0 auto; }
  .ss-new__title {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .ss-new__title:after {
      content: "";
      display: block;
      width: 422px;
      height: 17px;
      background: url("../../img/top/line_title.png") no-repeat center; }
      @media (max-width: 767px) {
        .ss-new__title:after {
          width: 349.5px;
          height: 14px;
          background: url("../../img/top/line_title_sp.png") no-repeat center;
          background-size: cover; } }
    .ss-new__title-text {
      font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
      font-size: 20px;
      letter-spacing: 0.05em;
      font-weight: bold;
      font-feature-settings: "palt";
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 767px) {
        .ss-new__title-text {
          flex-direction: row;
          font-size: 20px; } }
      .ss-new__title-text:before {
        content: "";
        display: block;
        width: 23px;
        height: 32px;
        background: url("../../img/top/ico_title_new.png") no-repeat center;
        margin: 0 0 2px; }
        @media (max-width: 767px) {
          .ss-new__title-text:before {
            width: 18.5px;
            height: 26.5px;
            background-size: cover;
            margin: 0 10px 0 0; } }
  .ss-new__contents {
    padding: 44px 0 41px; }
    @media (max-width: 767px) {
      .ss-new__contents {
        padding: 12px 0 0; } }
  .ss-new__list {
    display: flex;
    margin: 0 -10px; }
    @media (max-width: 767px) {
      .ss-new__list {
        flex-direction: column; } }
    .ss-new__list-item {
      width: 50%;
      padding: 0 10px;
      box-sizing: border-box; }
      @media (max-width: 767px) {
        .ss-new__list-item {
          width: auto;
          margin-bottom: 20px; } }
  .ss-new__banner {
    text-align: center; }

.ss-category {
  font-family: 'Hiragino Kaku Gothic ProN';
  width: 100%;
  max-width: 780px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 15px; }
  @media (max-width: 767px) {
    .ss-category {
      padding: 0 24px; } }
  .ss-category__heading {
    padding-top: 15px; }
    @media (max-width: 767px) {
      .ss-category__heading {
        padding-top: 2px; } }
  .ss-category__title {
    line-height: 1.2;
    font-size: 20px;
    font-weight: bold; }
  .ss-category__popular, .ss-category__anchor {
    max-width: 700px;
    margin: 56px auto 0; }
    @media (max-width: 767px) {
      .ss-category__popular, .ss-category__anchor {
        margin-top: 33px; } }
    #main .ss-category__popular-title, #main .ss-category__anchor-title {
      font-size: 20px;
      line-height: 1.2;
      font-weight: bold;
      box-sizing: border-box;
      padding: 0 0 13px;
      border-bottom: solid 1px #000; }
      @media (max-width: 767px) {
        #main .ss-category__popular-title, #main .ss-category__anchor-title {
          display: block;
          font-size: 15px;
          padding-bottom: 7px; } }
  .ss-category__popular-list {
    display: flex;
    margin: 23px 0 0;
    padding: 0 0 13px; }
    @media (max-width: 767px) {
      .ss-category__popular-list {
        margin-top: 13px;
        padding-bottom: 17px; } }
    .ss-category__popular-list-item {
      width: 50%;
      box-sizing: border-box; }
      .ss-category__popular-list-item:nth-child(1) {
        padding: 0px 10px 0 70px; }
        @media (max-width: 767px) {
          .ss-category__popular-list-item:nth-child(1) {
            padding: 0 13.5px 0 0; } }
      .ss-category__popular-list-item:nth-child(2) {
        padding: 0px 70px 0 10px; }
        @media (max-width: 767px) {
          .ss-category__popular-list-item:nth-child(2) {
            padding: 0 0 0 13.5px; } }
  .ss-category__popular-product {
    max-width: 230px;
    margin: auto; }
    .ss-category__popular-product-link {
      display: block; }
      .ss-category__popular-product-link:hover {
        opacity: 0.8; }
    .ss-category__popular-product-title {
      font-size: 16px;
      line-height: 1.4;
      font-weight: normal;
      display: flex;
      align-items: center; }
      .ss-category__popular-product-title-number {
        flex-shrink: 0;
        width: 55px;
        height: 47px;
        background-image: url("../../img/category/ico-crown.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        font-size: 19px;
        font-weight: normal;
        color: #fff;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 12px 0 0; }
        @media (max-width: 767px) {
          .ss-category__popular-product-title-number {
            width: 38px;
            height: 32.5px;
            font-size: 13.25px; } }
      .ss-category__popular-product-title-text {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        margin: 0 0 0 7px;
        padding: 8px 0 0; }
        @media (max-width: 767px) {
          .ss-category__popular-product-title-text {
            display: none; } }
    .ss-category__popular-product-image {
      margin: 13px 0 0; }
      @media (max-width: 767px) {
        .ss-category__popular-product-image {
          margin-top: 6px; } }
    .ss-category__popular-product-text {
      display: none; }
      @media (max-width: 767px) {
        .ss-category__popular-product-text {
          display: block;
          font-size: 14px;
          line-height: 1.4;
          margin: 10px 0 0; } }
  .ss-category__anchor-contents {
    padding: 0 0 50px; }
    @media (max-width: 767px) {
      .ss-category__anchor-contents {
        padding: 0px 0 34px; } }
  .ss-category__anchor-layout {
    display: flex;
    border-bottom: solid 1px #dddddd; }
    .ss-category__anchor-layout-item {
      width: 50%;
      box-sizing: border-box; }
      .ss-category__anchor-layout-item:nth-child(1) {
        padding: 0 0 0 153px; }
        @media (max-width: 767px) {
          .ss-category__anchor-layout-item:nth-child(1) {
            padding: 0 0 0 20px; } }
      .ss-category__anchor-layout-item:nth-child(2) {
        padding: 0 0 0 36px; }
        @media (max-width: 767px) {
          .ss-category__anchor-layout-item:nth-child(2) {
            padding: 0; } }
  .ss-category__anchor-link {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    padding: 4px 0; }
    @media (max-width: 767px) {
      .ss-category__anchor-link {
        font-size: 14px;
        display: flex;
        padding: 9px 0; } }
    .ss-category__anchor-link:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      margin: 0 9px 0 0; }
    .ss-category__anchor-link:hover {
      opacity: 0.8; }
    .ss-category__anchor-link.is-anchor-01:before {
      border-color: #c89b9b transparent transparent transparent; }
    .ss-category__anchor-link.is-anchor-02:before {
      border-color: #8ea0bf transparent transparent transparent; }
    .ss-category__anchor-link.is-anchor-03:before {
      border-color: #c9a977 transparent transparent transparent; }
    .ss-category__anchor-link.is-anchor-04:before {
      border-color: #c9c379 transparent transparent transparent; }
    .ss-category__anchor-link.is-anchor-05:before {
      border-color: #95c68d transparent transparent transparent; }
    .ss-category__anchor-link.is-anchor-06:before {
      border-color: #ae97c7 transparent transparent transparent; }
    .ss-category__anchor-link.is-anchor-07:before {
      border-color: #91c1bf transparent transparent transparent; }
  .ss-category__division {
    padding-top: 101px;
    margin-top: -101px;
    margin-bottom: 21px; }
    @media (max-width: 767px) {
      .ss-category__division {
        padding-top: 52px;
        margin-top: -52px;
        margin-bottom: 4px; } }
    #main .ss-category__division-title {
      font-size: 20px;
      line-height: 1.2;
      font-weight: bold;
      padding: 15px 0 0;
      text-align: center; }
      @media (max-width: 767px) {
        #main .ss-category__division-title {
          display: block;
          font-size: 18px; } }
    #anchor-01 .ss-category__division-title {
      color: #c89b9b; }
    #anchor-02 .ss-category__division-title {
      color: #8ea0bf; }
    #anchor-03 .ss-category__division-title {
      color: #c9a977; }
    #anchor-04 .ss-category__division-title {
      color: #c9c379; }
    #anchor-05 .ss-category__division-title {
      color: #95c68d; }
    #anchor-06 .ss-category__division-title {
      color: #ae97c7; }
    #anchor-07 .ss-category__division-title {
      color: #91c1bf; }
  .ss-category__subdivision {
    margin: 15px 0 0; }
    @media (max-width: 767px) {
      .ss-category__subdivision {
        margin: 10px 0 0; } }
    .ss-category__subdivision-title {
      font-size: 20px;
      font-weight: normal; }
    .ss-category__subdivision-link {
      display: block;
      font-size: inherit;
      font-weight: inherit;
      line-height: 44px;
      position: relative;
      padding: 0 0 0 26px; }
      .ss-category__subdivision-link:hover {
        opacity: 0.8; }
      @media (max-width: 767px) {
        .ss-category__subdivision-link {
          font-size: 17px;
          margin: 0 -24px;
          line-height: 50px;
          padding: 0 0 0 24px; } }
      .ss-category__subdivision-link:visited, .ss-category__subdivision-link:link {
        color: #fff; }
      .ss-category__subdivision-link:before, .ss-category__subdivision-link:after {
        content: "";
        display: block;
        width: 13px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 50%;
        right: 27px;
        z-index: 1;
        transform-origin: 92.30769% 50%; }
        @media (max-width: 767px) {
          .ss-category__subdivision-link:before, .ss-category__subdivision-link:after {
            width: 10px;
            height: 1px;
            transform-origin: 95% 50%; } }
      .ss-category__subdivision-link:before {
        transform: translateY(-50%) rotate(45deg); }
      .ss-category__subdivision-link:after {
        transform: translateY(-50%) rotate(-45deg); }
      #anchor-01 .ss-category__subdivision-link {
        background-color: #c89b9b; }
      #anchor-02 .ss-category__subdivision-link {
        background-color: #8ea0bf; }
      #anchor-03 .ss-category__subdivision-link {
        background-color: #c9a977; }
      #anchor-04 .ss-category__subdivision-link {
        background-color: #c9c379; }
      #anchor-05 .ss-category__subdivision-link {
        background-color: #95c68d; }
      #anchor-06 .ss-category__subdivision-link {
        background-color: #ae97c7; }
      #anchor-07 .ss-category__subdivision-link {
        background-color: #91c1bf; }
    .ss-category__subdivision-contents {
      overflow: hidden;
      margin: 12px 0 0;
      padding: 0 0 10px; }
      @media (max-width: 767px) {
        .ss-category__subdivision-contents {
          padding: 0 0 17px; } }
    .ss-category__subdivision-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px; }
      @media (max-width: 767px) {
        .ss-category__subdivision-list {
          margin: 0 -13.5px; } }
      .ss-category__subdivision-list-item {
        width: 25%;
        padding: 15px;
        box-sizing: border-box;
        display: flex; }
        @media (max-width: 767px) {
          .ss-category__subdivision-list-item {
            width: 50%;
            padding: 13.5px; } }
    .ss-category__subdivision-item {
      flex-grow: 1;
      display: flex; }
      .ss-category__subdivision-item-link {
        flex-grow: 1;
        border: #f2f2f2 solid 2px;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 3px;
        position: relative; }
        .ss-category__subdivision-item-link:hover {
          opacity: 0.8; }
        @media (max-width: 767px) {
          .ss-category__subdivision-item-link {
            border-width: 1px;
            border-radius: 5px; } }
        .ss-category__subdivision-item-link:before, .ss-category__subdivision-item-link:after {
          content: "";
          display: block;
          width: 19px;
          height: 2px;
          background: #f2f2f2;
          position: absolute;
          top: 50%;
          right: 10px;
          z-index: 1;
          transform-origin: 94.73684% 50%; }
          @media (max-width: 767px) {
            .ss-category__subdivision-item-link:before, .ss-category__subdivision-item-link:after {
              width: 17px;
              height: 1px;
              transform-origin: 97.05882% 50%; } }
        .ss-category__subdivision-item-link:before {
          transform: translateY(-50%) rotate(45deg); }
        .ss-category__subdivision-item-link:after {
          transform: translateY(-50%) rotate(-45deg); }
        #anchor-01 .ss-category__subdivision-item-link {
          border-color: #c89b9b; }
          #anchor-01 .ss-category__subdivision-item-link:before, #anchor-01 .ss-category__subdivision-item-link:after {
            background: #c89b9b; }
        #anchor-02 .ss-category__subdivision-item-link {
          border-color: #8ea0bf; }
          #anchor-02 .ss-category__subdivision-item-link:before, #anchor-02 .ss-category__subdivision-item-link:after {
            background: #8ea0bf; }
        #anchor-03 .ss-category__subdivision-item-link {
          border-color: #c9a977; }
          #anchor-03 .ss-category__subdivision-item-link:before, #anchor-03 .ss-category__subdivision-item-link:after {
            background: #c9a977; }
        #anchor-04 .ss-category__subdivision-item-link {
          border-color: #c9c379; }
          #anchor-04 .ss-category__subdivision-item-link:before, #anchor-04 .ss-category__subdivision-item-link:after {
            background: #c9c379; }
        #anchor-05 .ss-category__subdivision-item-link {
          border-color: #95c68d; }
          #anchor-05 .ss-category__subdivision-item-link:before, #anchor-05 .ss-category__subdivision-item-link:after {
            background: #95c68d; }
        #anchor-06 .ss-category__subdivision-item-link {
          border-color: #ae97c7; }
          #anchor-06 .ss-category__subdivision-item-link:before, #anchor-06 .ss-category__subdivision-item-link:after {
            background: #ae97c7; }
        #anchor-07 .ss-category__subdivision-item-link {
          border-color: #91c1bf; }
          #anchor-07 .ss-category__subdivision-item-link:before, #anchor-07 .ss-category__subdivision-item-link:after {
            background: #91c1bf; }
      .ss-category__subdivision-item-image {
        margin: 6px auto 0;
        text-align: center;
        max-width: 108px; }
        @media (max-width: 767px) {
          .ss-category__subdivision-item-image {
            margin: 10px auto 0;
            max-width: 94px; } }
      .ss-category__subdivision-item-title {
        font-size: 16px;
        line-height: 1.125;
        font-weight: normal;
        text-align: center;
        min-height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 7px; }
        @media (max-width: 767px) {
          .ss-category__subdivision-item-title {
            font-size: 14px;
            line-height: 1.21429;
            min-height: 34px;
            margin: 0 0 6px; } }

.ss-slogan {
  font-size: 20px;
  line-height: 1.4;
  margin: 13px 0 12px; }
  @media (max-width: 767px) {
    .ss-slogan {
      font-size: 17px;
      line-height: 1.35294;
      margin: 7px 0 4px; } }

.ss-detail-tab {
  width: 100%;
  max-width: 782px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box; }
  @media (max-width: 767px) {
    .ss-detail-tab {
      padding: 0;
      margin-top: 38px; } }
  .ss-detail-tab__info {
    display: flex;
    margin: 0 0 11px; }
    @media (max-width: 767px) {
      .ss-detail-tab__info {
        margin: 0 0 8px; } }
    .ss-detail-tab__info-item {
      width: 50%;
      text-align: center;
      font-size: 18px;
      line-height: 1.42857;
      white-space: nowrap; }
      @media (max-width: 767px) {
        .ss-detail-tab__info-item {
          font-size: 14px; } }
      .ss-detail-tab__info-item-text {
        font-size: inherit;
        line-height: inherit;
        display: block; }
        .is-non-active .ss-detail-tab__info-item-text {
          display: none; }
        .ss-detail-tab__info-item-text-break {
          display: none; }
          @media (max-width: 767px) {
            .ss-detail-tab__info-item-text-break {
              display: inline; } }
  .ss-detail-tab__body {
    border: #b2b2b2 solid 2px; }
    @media (max-width: 767px) {
      .ss-detail-tab__body {
        border-width: 1px; } }
  .ss-detail-tab__label {
    display: flex;
    border-bottom: #b2b2b2 solid 2px; }
    @media (max-width: 767px) {
      .ss-detail-tab__label {
        border-width: 1px; } }
    .ss-detail-tab__label-item {
      width: 50%;
      box-sizing: border-box;
      text-align: center;
      font-size: 16px;
      background: #ededed;
      padding: 9px;
      cursor: pointer; }
      @media (max-width: 767px) {
        .ss-detail-tab__label-item {
          font-size: 14px; } }
      .ss-detail-tab__label-item:nth-child(1) {
        border-right: #b2b2b2 solid 1px; }
        @media (max-width: 767px) {
          .ss-detail-tab__label-item:nth-child(1) {
            border-width: 1px; } }
      .ss-detail-tab__label-item:nth-child(2) {
        border-left: #b2b2b2 solid 1px; }
        @media (max-width: 767px) {
          .ss-detail-tab__label-item:nth-child(2) {
            border-right: none; } }
      .ss-detail-tab__label-item.is-active {
        background: #fff; }
  .ss-detail-tab__panel-item {
    display: none; }
    .ss-detail-tab__panel-item.is-active {
      display: block; }
    .ss-detail-tab__panel-item .topBox {
      width: auto !important;
      margin: 0 !important; }
    .ss-detail-tab__panel-item .rBox {
      float: none !important;
      width: auto !important;
      padding-right: 0 !important; }
    .ss-detail-tab__panel-item .sizeTable {
      margin-right: 0 !important; }
    .ss-detail-tab__panel-item .rLink {
      padding: 0 !important;
      border: none !important;
      margin-bottom: 30px !important; }
  .ss-detail-tab__contents {
    max-width: 682px;
    margin: auto;
    padding: 0 16px;
    box-sizing: border-box; }
    .ss-detail-tab__contents-title {
      font-size: 16px;
      background: #ededed;
      margin: 30px 0 21px;
      padding: 2px 16px; }
      @media (max-width: 767px) {
        .ss-detail-tab__contents-title {
          font-size: 14px;
          padding: 3px 8px;
          margin: 20px 0 15px; } }
    .ss-detail-tab__contents-text {
      font-size: 16px;
      line-height: 1.5;
      margin: 10px 0; }
      @media (max-width: 767px) {
        .ss-detail-tab__contents-text {
          font-size: 14px;
          line-height: 1.42857; } }
  .ss-detail-tab__def {
    padding: 40px 0 44px; }
    @media (max-width: 767px) {
      .ss-detail-tab__def {
        padding: 24px 0 29px; } }
    .ss-detail-tab__def-term {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
      margin: 0 0 23px; }
      @media (max-width: 767px) {
        .ss-detail-tab__def-term {
          font-size: 17px;
          margin: 0 0 8px; } }
    .ss-detail-tab__def-desc {
      font-size: 16px;
      line-height: 1.5; }
      @media (max-width: 767px) {
        .ss-detail-tab__def-desc {
          font-size: 14px;
          line-height: 1.42857; } }

.ss-related-product__title {
  font-family: 'Hiragino Kaku Gothic ProN';
  font-size: 20px;
  line-height: 1.2;
  background: #eee3d6;
  max-width: 750px;
  box-sizing: border-box;
  padding: 8px 50px;
  margin: 58px auto 0; }
  @media (max-width: 767px) {
    .ss-related-product__title {
      margin: 44px -25px 0;
      font-size: 17px;
      font-weight: bold;
      padding: 15px 25px; } }

.ss-related-product__actions {
  max-width: 460px;
  margin: auto; }

.ss-related-product__link {
  display: block;
  font-family: 'Hiragino Kaku Gothic ProN';
  padding: 14px 50px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  border: 4px solid #FF8B07;
  border-radius: 6px;
  position: relative;
  background: #fff; }
  @media (max-width: 767px) {
    .ss-related-product__link {
      font-size: 15px;
      border-radius: 2px;
      border-width: 2px; } }
  .ss-related-product__link:visited, .ss-related-product__link:link {
    color: #ff8b06; }
  .ss-related-product__link:hover {
    opacity: 0.7; }
  .ss-related-product__link:after {
    content: "";
    display: block;
    width: 11px;
    height: 18px;
    background: url("../../img/detail/ico-arrow-orange.png") no-repeat center;
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .ss-related-product__link:after {
        background: url("../../img/detail/ico-arrow-orange-sp.png") no-repeat center;
        background-size: contain;
        width: 8.5px;
        height: 15px; } }

.ss-related-product .comProduct {
  margin-top: 36px;
  margin-bottom: 43px; }
  @media (max-width: 767px) {
    .ss-related-product .comProduct {
      margin-top: 30px;
      margin-bottom: 24px; } }
  .ss-related-product .comProduct h2 {
    font-family: 'Hiragino Kaku Gothic ProN';
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 32px;
    font-size: 18px; }
    @media (max-width: 767px) {
      .ss-related-product .comProduct h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        border: none;
        padding-bottom: 0px;
        margin-bottom: 18px;
        font-size: 17px; }
        .ss-related-product .comProduct h2:after {
          content: "";
          display: block;
          width: 330px;
          height: 14px;
          background: url("../../img/top/line_title_sp.png") no-repeat center;
          background-size: contain; } }

.ss-about-product__title {
  font-family: 'Hiragino Kaku Gothic ProN';
  font-size: 20px;
  line-height: 1.2;
  background: #eee3d6;
  max-width: 750px;
  box-sizing: border-box;
  padding: 8px 50px;
  margin: 58px auto 0; }
  @media (max-width: 767px) {
    .ss-about-product__title {
      margin: 44px -25px 0;
      font-size: 17px;
      font-weight: bold;
      padding: 15px 25px; } }

.ss-about-product__movie {
  max-width: 750px;
  box-sizing: border-box;
  margin: 0 auto 0; }
  .ss-about-product__movie-description {
    font-family: 'Hiragino Kaku Gothic ProN';
    font-size: 18px;
    text-align: center;
    margin: 33px 0 0; }
    @media (max-width: 767px) {
      .ss-about-product__movie-description {
        font-size: 17px;
        font-weight: bold; } }
  .ss-about-product__movie-contents {
    max-width: 540px;
    margin: 15px auto 0; }
    @media (max-width: 767px) {
      .ss-about-product__movie-contents {
        margin-top: 11px; } }
    .ss-about-product__movie-contents-frame {
      position: relative;
      width: 100%;
      padding-top: 56.25%; }
      .ss-about-product__movie-contents-frame iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important; }

.ss-about-product__accordion.comAccordionJs .ss-about-product__accordion-actions {
  width: 100%;
  max-width: 460px;
  margin: 0 auto; }

.ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link {
  display: block;
  font-family: 'Hiragino Kaku Gothic ProN';
  padding: 14px 50px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  border: 4px solid #FF8B07;
  border-radius: 6px;
  position: relative;
  background: #fff; }
  @media (max-width: 767px) {
    .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link {
      font-size: 15px;
      border-radius: 2px;
      border-width: 2px; } }
  .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:visited, .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:link {
    color: #ff8b06; }
  .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:hover {
    opacity: 0.7; }
  .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:after {
    content: "";
    display: block;
    width: 11px;
    height: 18px;
    background: url("../../img/detail/ico-arrow-orange.png") no-repeat center;
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%) rotate(90deg); }
    @media (max-width: 767px) {
      .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:after {
        background: url("../../img/detail/ico-arrow-orange-sp.png") no-repeat center;
        background-size: contain;
        width: 8.5px;
        height: 15px; } }
  .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link.on {
    background: #fff; }
    .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link.on:after {
      transform: translateY(-50%) rotate(-90deg); }

.ss-product-detail__cv-actions {
  margin: 30px 0 0; }
  .ss-product-detail__cv-actions-item {
    margin: 0 auto;
    width: 100%;
    max-width: 460px; }

.ss-product-detail__cv-link {
  font-family: 'Hiragino Kaku Gothic ProN';
  padding: 10px 50px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  border: 4px solid #FF8B07;
  border-radius: 6px;
  position: relative;
  background: #FF8B07;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767px) {
    .ss-product-detail__cv-link {
      font-size: 15px;
      border-radius: 2px;
      border-width: 2px; } }
  .ss-product-detail__cv-link:visited, .ss-product-detail__cv-link:link {
    color: #fff; }
  .ss-product-detail__cv-link:hover {
    opacity: 0.7; }
  .ss-product-detail__cv-link:before {
    content: "";
    display: block;
    width: 31px;
    height: 32px;
    background: url("../../img/detail/ico-cart.png") no-repeat center;
    margin: 0 20px 0 -30px; }
    @media (max-width: 767px) {
      .ss-product-detail__cv-link:before {
        background-size: contain;
        width: 24.5px;
        height: 25.5px;
        margin: 0 10px 0 -15px; } }
  .ss-product-detail__cv-link:after {
    content: "";
    display: block;
    width: 19px;
    height: 12px;
    background: url("../../img/detail/ico-arrow-white.png") no-repeat center;
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .ss-product-detail__cv-link:after {
        background: url("../../img/detail/ico-arrow-white-sp.png") no-repeat center;
        background-size: contain;
        width: 15px;
        height: 8.5px; } }

.ss-recommend-product .comProduct {
  margin-top: 92px;
  margin-bottom: 88px; }
  @media (max-width: 767px) {
    .ss-recommend-product .comProduct {
      margin-top: 56px;
      margin-bottom: 65px; } }
  .ss-recommend-product .comProduct h2 {
    font-family: 'Hiragino Kaku Gothic ProN';
    font-weight: bold;
    padding-bottom: 8px;
    margin-bottom: 27px;
    font-size: 18px; }
    @media (max-width: 767px) {
      .ss-recommend-product .comProduct h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        border: none;
        padding-bottom: 0px;
        margin-bottom: 18px;
        font-size: 17px; }
        .ss-recommend-product .comProduct h2:after {
          content: "";
          display: block;
          width: 330px;
          height: 14px;
          background: url("../../img/top/line_title_sp.png") no-repeat center;
          background-size: contain; } }

.ss-product-slide__caption {
  font-family: 'Hiragino Kaku Gothic ProN';
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
  text-align: center; }
  @media (max-width: 767px) {
    .ss-product-slide__caption {
      font-size: 14px; }
      #main .topBox .lBig .ss-product-slide__caption {
        margin-top: 10px; } }

.ss-product-slide__counter {
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 6px; }
  @media (max-width: 767px) {
    .ss-product-slide__counter {
      font-size: 14px;
      margin-top: 10px; } }

@media (max-width: 767px) {
  #main .topBox .ss-product-slide .slick-dots {
    margin-top: 9px; } }

.ss-line {
  border-top: 1px solid #cbcbcb;
  margin: 12px 0 24px; }

.ss-product-list__title {
  width: 100%;
  max-width: 960px;
  margin: 39px auto;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 2rem;
  line-height: 1.4;
  font-weight: bold;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ss-product-list__title:after {
    content: "";
    display: block;
    width: 422px;
    height: 17px;
    background: url(../../img/top/line_title.png) no-repeat center; }
    @media (max-width: 767px) {
      .ss-product-list__title:after {
        width: 349.5px;
        height: 14px;
        background: url(../../img/top/line_title_sp.png) no-repeat center;
        background-size: cover; } }
  @media (max-width: 767px) {
    .ss-product-list__title {
      font-size: 1.8rem;
      margin: 20px auto; } }

.ss-product-list__product-photo-link:hover {
  opacity: 0.7; }

.ss-product-list__product-title-link:link:hover, .ss-product-list__product-title-link[href]:hover, .ss-product-list__product-title-link:hover {
  color: #000;
  opacity: 0.7; }

.ss-product-list__actions {
  margin: 75px 0; }
  @media (max-width: 767px) {
    .ss-product-list__actions {
      margin: 40px 0; } }
  .ss-product-list__actions-button {
    display: block;
    font-size: 20px;
    line-height: 54px;
    width: 100%;
    max-width: 400px;
    height: 54px;
    margin: 0 auto;
    color: #000000;
    background: #eee3d6;
    text-align: center;
    border-radius: 27px;
    padding: 0 64px;
    box-sizing: border-box;
    position: relative; }
    @media (max-width: 767px) {
      .ss-product-list__actions-button {
        font-size: 17px;
        height: 45px;
        line-height: 45px;
        max-width: 275px;
        padding: 0 36px 0 36px; } }
    .ss-product-list__actions-button:visited, .ss-product-list__actions-button:hover, .ss-product-list__actions-button:link {
      color: #000000; }
    .ss-product-list__actions-button:before {
      position: absolute;
      top: 50%;
      left: 44px;
      transform: scale(-1, 1) translateY(-50%);
      content: "";
      display: block;
      width: 10px;
      height: 17px;
      background: url("../../img/top/arrow-column-b.png") no-repeat center; }
      @media (max-width: 767px) {
        .ss-product-list__actions-button:before {
          width: 8px;
          height: 14px;
          left: 23px;
          background-size: cover; } }

#main .comPhotoUl .list-search-zero {
  float: none;
  margin: 109px 0 0;
  width: auto; }
  @media (max-width: 767px) {
    #main .comPhotoUl .list-search-zero {
      margin: 0; } }

#main .ss-onayami {
  margin-top: 55px;
  margin-bottom: 52px; }
  @media (max-width: 767px) {
    #main .ss-onayami {
      margin-top: 42px; } }

.ss-onayami__title {
  display: flex;
  flex-direction: column;
  align-items: center; }
  #main .ss-onayami__title {
    border: none;
    padding: 0;
    margin-bottom: 17px; }
    @media (max-width: 767px) {
      #main .ss-onayami__title {
        margin-bottom: 13px; } }
  .ss-onayami__title:after {
    content: "";
    display: block;
    width: 422px;
    height: 17px;
    background: url("../../img/top/line_title.png") no-repeat center; }
    @media (max-width: 767px) {
      .ss-onayami__title:after {
        width: 349.5px;
        height: 14px;
        background: url("../../img/top/line_title_sp.png") no-repeat center;
        background-size: cover; } }
  .ss-onayami__title-text {
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0; }
    @media (max-width: 767px) {
      .ss-onayami__title-text {
        flex-direction: row;
        letter-spacing: 0.04em;
        font-size: 20px;
        margin: 0 0 0 -15px; } }
    .ss-onayami__title-text:before {
      content: "";
      display: block;
      width: 34px;
      height: 37px;
      background: url("../../img/top/ico_title_onayami_pc.png") no-repeat center;
      margin: 0 0 3px;
      transform: translateX(5px); }
      @media (max-width: 767px) {
        .ss-onayami__title-text:before {
          width: 29.5px;
          height: 32px;
          margin: 0 11px 0 0;
          background: url("../../img/top/ico_title_onayami_sp.png") no-repeat center;
          background-size: cover;
          transform: translate(0px, -4px); } }

.ss-onayami__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  @media (max-width: 767px) {
    .ss-onayami__list {
      margin: 0 -4.25px; } }
  .ss-onayami__list-item {
    width: calc(100% / 3);
    box-sizing: border-box;
    padding: 15px; }
    @media (max-width: 767px) {
      .ss-onayami__list-item {
        width: calc(100% / 2);
        padding: 7.5px 4.25px; } }

.ss-onayami__link {
  display: flex;
  justify-content: center;
  background: #eee3d6;
  line-height: 50px;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 18px;
  position: relative;
  font-weight: bold; }
  @media (max-width: 767px) {
    .ss-onayami__link {
      line-height: 40px;
      border-radius: 20px;
      font-size: 14px; } }
  .ss-onayami__link:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    right: 23px;
    transform: translateY(-50%) rotate(-45deg);
    border-right: solid 2px #000;
    border-bottom: solid 2px #000; }
    @media (max-width: 767px) {
      .ss-onayami__link:after {
        width: 6px;
        height: 6px;
        border-width: 1px;
        right: 12px; } }
  .ss-onayami__link:hover {
    opacity: 0.7; }

*::-ms-backdrop, .ss-onayami__list-item {
  width: 33.333%; }
  @media (max-width: 767px) {
    *::-ms-backdrop, .ss-onayami__list-item {
      width: 50%; } }

.ss-checked-items.is-cart {
  margin-top: -100px; }
  @media (max-width: 767px) {
    .ss-checked-items.is-cart {
      margin-top: -20px; } }

.ss-footer__logo-wrap {
  position: relative; }

#gFooter .fBox .ss-footer__privacy-mark {
  left: auto;
  right: 50%;
  bottom: -22px;
  margin-right: 219px; }
  @media (max-width: 767px) {
    #gFooter .fBox .ss-footer__privacy-mark {
      width: 68px;
      bottom: -17px;
      right: 50%;
      left: auto;
      margin-right: 89px; } }

.ss-footer__corporate-logo {
  width: 210px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: 165px; }
  @media (max-width: 767px) {
    .ss-footer__corporate-logo {
      width: 50px;
      margin-left: 95px; } }

.ss-footer__corporate-link {
  display: block; }

.ss-onayami__link {
  line-height: 1;
  background: transparent; }
  @media (max-width: 767px) {
    .ss-onayami__link {
      line-height: 1; } }
  .ss-onayami__link:after {
    display: none; }

#gFooter.ss-footer .ss-footer__page-top {
  bottom: 90px; }
  @media (max-width: 767px) {
    #gFooter.ss-footer .ss-footer__page-top {
      bottom: auto; } }

.ss-search-products__actions {
  margin-left: 153px; }
  @media (max-width: 767px) {
    .ss-search-products__actions {
      margin-left: 0; } }

.ss-search-products__search-word-list {
  margin: 29px 0 0; }
  @media (max-width: 767px) {
    .ss-search-products__search-word-list {
      margin: 25px 0 0; } }
  .ss-search-products__search-word-list-item {
    overflow: hidden; }

#gHeader .searchBox li .ss-search-products__search-word-label {
  vertical-align: top;
  font-size: 1.4rem;
  padding: 5px 0 0; }
  @media (max-width: 767px) {
    #gHeader .searchBox li .ss-search-products__search-word-label {
      padding: 0;
      margin: 0 0 9px; } }

#gHeader .searchBox li .ss-search-products__search-word-actions {
  display: flex;
  flex-wrap: wrap;
  margin: -3.25px; }
  @media (max-width: 767px) {
    #gHeader .searchBox li .ss-search-products__search-word-actions {
      margin: -6.25px -3.25px; } }

.ss-search-products__search-word-actions-button {
  font-size: 1.4rem;
  margin: 3.25px;
  height: 30px;
  padding: 0 12px;
  line-height: 28px;
  background: #ededeb;
  border: solid 1px #dcdcdc;
  box-sizing: border-box;
  border-radius: 15px;
  cursor: pointer; }
  @media (max-width: 767px) {
    .ss-search-products__search-word-actions-button {
      font-size: 1.8rem;
      margin: 6.25px 3.25px;
      height: 36px;
      line-height: 34px;
      border-radius: 18px; } }
  .ss-search-products__search-word-actions-button:hover {
    color: #fff;
    border: solid 1px #595455;
    background: #595455; }

.ss-about-product__accordion.comAccordionJs .ss-about-product__accordion-actions {
  max-width: 650px; }

.ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:visited, .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:link {
  color: #000; }

.ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:after {
  right: 74px;
  background-image: url("../../img/detail/ico-arrow-black.png"); }
  @media (max-width: 767px) {
    .ss-about-product__accordion .ss-about-product__accordion-actions .ss-about-product__accordion-link:after {
      right: 21px;
      background-image: url("../../img/detail/ico-arrow-black-sp.png"); } }

.ss-product-detail__cv-actions {
  margin: 0 0 30px; }
  .ss-product-detail__cv-actions-item {
    max-width: 650px; }

.ss-product-detail__cv-link:after {
  right: 70px; }
  @media (max-width: 767px) {
    .ss-product-detail__cv-link:after {
      right: 18px; } }

.ss-category {
  max-width: none;
  margin-bottom: 45px; }
  .ss-category__title {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767px) {
      .ss-category__title {
        margin-top: 14px; } }
    .ss-category__title-text {
      line-height: 1.2;
      font-size: 20px; }
    .ss-category__title-icon {
      width: 28px;
      margin-left: 10px; }
      .ss-category__title-icon-pic {
        transform: translateY(-1px); }
  .ss-category__contents-inner {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto; }
  .ss-category__tab {
    margin: 50px -15px 0;
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }
    @media (max-width: 767px) {
      .ss-category__tab {
        margin: 25px -24px 0;
        padding-left: 4px;
        padding-right: 4px; } }
    .ss-category__tab::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: solid 1px #c1a35e;
      position: absolute;
      left: 0;
      z-index: -1; }
      .ss-category__heading .ss-category__tab::after {
        bottom: 0; }
      .ss-category__footer .ss-category__tab::after {
        top: -1px; }
    .ss-category__tab-contents {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden; }
    .ss-category__tab-list {
      display: flex;
      align-items: flex-end;
      margin: 0 -5px; }
      @media (max-width: 767px) {
        .ss-category__tab-list {
          margin: 0 -1px; } }
      .ss-category__heading .ss-category__tab-list {
        align-items: flex-end; }
      .ss-category__footer .ss-category__tab-list {
        align-items: flex-start; }
      .ss-category__tab-list-item {
        width: calc(100% / 3);
        padding: 0 5px;
        box-sizing: border-box; }
        @media (max-width: 767px) {
          .ss-category__tab-list-item {
            width: 30%;
            padding: 0 1px; } }
        @media (max-width: 767px) {
          .ss-category__tab-list-item.is-active {
            width: 40%; } }
    .ss-category__tab-link {
      display: block;
      border: solid 1px #c1a35e;
      background: #fff;
      padding: 10px 5px; }
      @media (max-width: 767px) {
        .ss-category__heading .ss-category__tab-link {
          border-radius: 6px 6px 0 0; }
        .ss-category__footer .ss-category__tab-link {
          border-radius: 0 0 6px 6px; } }
      .ss-category__tab-link:link, .ss-category__tab-link[href] {
        color: #92672e; }
        .ss-category__tab-link:link:hover, .ss-category__tab-link[href]:hover {
          color: #000;
          background: #EEE3D6; }
        @media (max-width: 767px) {
          .ss-category__tab-link:link, .ss-category__tab-link[href] {
            color: #92672e;
            background: #EEE3D6; } }
      .is-active .ss-category__tab-link {
        background: #EEE3D6;
        cursor: default;
        pointer-events: none; }
        @media (max-width: 767px) {
          .is-active .ss-category__tab-link {
            padding: 14px 5px;
            background: #fff; } }
        .is-active .ss-category__tab-link:link, .is-active .ss-category__tab-link[href] {
          color: #000; }
    .ss-category__tab-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1.3;
      font-weight: bold; }
      .ss-category__tab-label--main {
        font-size: 14px;
        white-space: nowrap; }
        @media (max-width: 767px) {
          .ss-category__tab-label--main {
            font-size: 12px; } }
      .ss-category__tab-label--sub {
        font-size: 12px;
        white-space: nowrap; }
        @media (max-width: 767px) {
          .ss-category__tab-label--sub {
            font-size: 10px; } }
      .ss-category__heading .ss-category__tab-label::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 5px 0 5px;
        border-color: #92672e transparent transparent transparent;
        margin-top: 5px; }
        @media (max-width: 767px) {
          .ss-category__heading .ss-category__tab-label::after {
            display: none; } }
      .ss-category__heading .is-active .ss-category__tab-label::after,
      .ss-category__heading .ss-category__tab-link:hover .ss-category__tab-label::after {
        border-color: #000 transparent transparent transparent; }
      .ss-category__footer .ss-category__tab-label::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 8px 5px;
        border-color: transparent transparent #92672e transparent;
        margin-bottom: 5px; }
        @media (max-width: 767px) {
          .ss-category__footer .ss-category__tab-label::before {
            display: none; } }
      .ss-category__footer .is-active .ss-category__tab-label::before,
      .ss-category__footer .ss-category__tab-link:hover .ss-category__tab-label::before {
        border-color: transparent transparent #000000 transparent; }
  .ss-category__footer {
    display: none; }
    @media (max-width: 767px) {
      .ss-category__footer {
        display: block; } }
  .ss-category__search {
    max-width: 960px;
    margin: auto; }
    .ss-category__search-description {
      text-align: center;
      font-size: 20px;
      margin: 40px 0; }
      @media (max-width: 767px) {
        .ss-category__search-description {
          font-size: 18px;
          margin: 20px 0; } }
      .ss-category__search-description br {
        display: none; }
        @media (max-width: 767px) {
          .ss-category__search-description br {
            display: inline; } }
    .ss-category__search-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media (max-width: 767px) {
        .ss-category__search-list {
          display: block; } }
      .ss-category__search-list-item {
        flex-shrink: 0;
        display: flex;
        width: 48.95833%;
        margin: 10px 0; }
        @media (max-width: 767px) {
          .ss-category__search-list-item {
            width: auto;
            margin: 10px -24px;
            box-sizing: border-box; } }
    .ss-category__search-product {
      flex-grow: 1;
      background: #EEE3D6;
      position: relative;
      z-index: 1; }
      .ss-category__search-product::before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-right: solid 1px #000;
        border-bottom: solid 1px #000;
        position: absolute;
        top: 50%;
        right: 16px;
        z-index: 2;
        transform: translateY(-50%) rotate(-45deg); }
      .ss-category__search-product-link {
        display: block;
        padding: 14px 24px 24px; }
      #main .ss-category__search-product-title {
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding: 0; }
      .ss-category__search-product-title--main {
        font-size: 20px;
        font-weight: bold; }
        @media (max-width: 767px) {
          .ss-category__search-product-title--main {
            font-size: 18px; } }
      .ss-category__search-product-title--sub {
        font-size: 18px;
        font-weight: normal; }
        @media (max-width: 767px) {
          .ss-category__search-product-title--sub {
            font-size: 16px; } }
      .ss-category__search-product-contents {
        display: flex;
        align-items: center;
        margin: 14px auto 0; }
        @media (max-width: 767px) {
          .ss-category__search-product-contents {
            max-width: 327px; } }
      .ss-category__search-product-image {
        width: 120px;
        flex-shrink: 0; }
      .ss-category__search-product-detail {
        flex-grow: 1;
        padding: 0 14px;
        box-sizing: border-box; }
      .ss-category__search-product-list-item {
        font-size: 16px;
        line-height: 1.5;
        padding: 2px 0;
        display: flex;
        align-items: flex-start; }
        @media (max-width: 767px) {
          .ss-category__search-product-list-item {
            font-size: 14px; } }
        .ss-category__search-product-list-item::before {
          content: "・"; }
  #main .ss-category .ss-product-list__title {
    margin-bottom: 39px; }
    @media (max-width: 767px) {
      #main .ss-category .ss-product-list__title {
        font-size: 1.8rem;
        margin: 40px auto;
        padding: 0; } }

@media (max-width: 767px) {
  .ss-category__tab-list-item {
    width: 32%; } }

@media (max-width: 767px) {
  .ss-category__tab-list-item.is-active {
    width: 36%; } }

.ss-category__tab-label {
  line-height: 1.4; }
  .ss-category__tab-label--main {
    font-size: 15px; }
    @media (max-width: 767px) {
      .ss-category__tab-label--main {
        font-size: 14px; } }
  .ss-category__tab-label--sub {
    font-size: 15px; }
    @media (max-width: 767px) {
      .ss-category__tab-label--sub {
        font-size: 14px; } }

@media (max-width: 767px) {
  .ss-category__search-product-title--main {
    font-size: 18px; } }

@media (max-width: 767px) {
  .ss-category__search-product-title--sub {
    font-size: 18px; } }

@media (max-width: 767px) {
  .ss-category__search-product-list-item {
    font-size: 18px; } }

.ss-shopping-login__heading {
  margin: 0 auto 20px;
  width: 900px;
  overflow: hidden; }
  .ss-shopping-login__heading-item {
    text-align: center; }
    .ss-shopping-login__heading-item:first-child {
      float: left;
      width: 444px; }
    .ss-shopping-login__heading-item:last-child {
      float: right;
      width: 444px; }
  .ss-shopping-login__heading-text {
    font-weight: bold;
    font-size: 18px; }

.ss-shopping-login__scroll {
  margin-top: 8px; }
  #main .comScroll .ss-shopping-login__scroll-body {
    height: 140px;
    padding: 16px;
    margin: 0 0 8px; }
    @media (max-width: 767px) {
      #main .comScroll .ss-shopping-login__scroll-body {
        height: 300px; } }
  #main .comLoginBox .rBox .ss-shopping-login__scroll-title {
    font-size: 1.4rem;
    text-align: center;
    border: none; }

.ss-form__title-elective {
  align-self: flex-start;
  padding: 0 11px 1px;
  float: right;
  font-size: 1.2rem;
  font-weight: normal;
  background-color: #ddd;
  line-height: 28px;
  margin: 0 10px 0 0;
  display: inline-block;
  white-space: nowrap; }
  @media (max-width: 767px) {
    .ss-form__title-elective {
      padding: 0 11px; } }

.ss-shopping-deliv {
  margin-bottom: 66px; }
  .ss-shopping-deliv__title {
    position: relative; }
    #main .step2 .ss-shopping-deliv__title {
      margin-bottom: 43px;
      padding: 18px 0;
      font-size: 2.5rem;
      text-align: center;
      font-weight: normal;
      background-color: #F4EFEB; }
      @media (max-width: 767px) {
        #main .step2 .ss-shopping-deliv__title {
          padding: 15px 0 16px;
          font-size: 2.0rem; } }
    .ss-shopping-deliv__title::after {
      content: "";
      display: block;
      width: 100vw;
      height: 100%;
      background: #F4EFEB;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%); }
    .ss-shopping-deliv__title-text {
      display: inline-block;
      position: relative; }
  @media (max-width: 767px) {
    .ss-shopping-deliv__address {
      margin: 0 0px 15px; } }
  .ss-shopping-deliv__notes {
    font-size: 16px;
    line-height: 1.84;
    margin-bottom: 8px; }
    @media (max-width: 767px) {
      .ss-shopping-deliv__notes {
        text-align: center; } }
  @media (max-width: 767px) {
    .ss-shopping-deliv__add {
      text-align: center;
      font-size: 16px; } }
  .ss-shopping-deliv__add-link {
    padding: 0 0 0 24px;
    display: inline-block;
    background: url(../../img/common/icon14.png) no-repeat left top 4px;
    background-size: 16px 16px; }
  .ss-shopping-deliv__accordion {
    display: none;
    margin-top: 20px; }
    #main .step2 .ss-shopping-deliv__accordion .ss-shopping-payment__title {
      margin-bottom: 16px; }
  .ss-shopping-deliv__actions {
    margin-top: 40px; }
    #main .step2 .ss-shopping-deliv__actions {
      margin-bottom: 60px; }

.ss-shopping-complete__navi {
  overflow: hidden;
  margin: 40px 0 60px;
  padding: 0 20px; }
  @media (max-width: 767px) {
    .ss-shopping-complete__navi {
      padding: 0 16px; } }
  .ss-shopping-complete__navi-list {
    display: flex;
    margin: -10px;
    justify-content: center;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .ss-shopping-complete__navi-list {
        margin: -5px; } }
    .ss-shopping-complete__navi-list-item {
      margin: 10px;
      width: 100%;
      max-width: 220px; }
      @media (max-width: 767px) {
        .ss-shopping-complete__navi-list-item {
          margin: 5px;
          width: auto;
          max-width: none; } }
  .ss-shopping-complete__navi-link {
    display: block;
    border: solid 1px #f28e1e;
    line-height: 48px;
    border-radius: 24px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 24px;
    position: relative;
    text-align: center;
    background-color: #fff; }
    @media (max-width: 767px) {
      .ss-shopping-complete__navi-link {
        line-height: 40px;
        border-radius: 20px; } }
    .ss-shopping-complete__navi-link:link {
      color: #f28e1e; }
    .ss-shopping-complete__navi-link:hover {
      background-color: #f7f7f7; }
    .ss-shopping-complete__navi-link::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color: #f28e1e #f28e1e transparent transparent;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%) rotate(45deg); }
      @media (max-width: 767px) {
        .ss-shopping-complete__navi-link::after {
          right: 13px; } }
    .ss-shopping-complete__navi-link-text {
      color: #f28e1e;
      display: block; }

.ss-amazon-pay__info {
  padding: 0 0 20px; }
  .ss-amazon-pay__info--main {
    font-size: 18px;
    color: #f5000d; }
  .ss-amazon-pay__info--sub {
    margin: 10px 0 0; }

.ss-payment__info {
  padding: 0 0 20px; }
  @media (max-width: 767px) {
    .ss-payment__info {
      padding: 0 20px 20px; } }
  .ss-payment__info--main {
    font-size: 18px;
    color: #f5000d; }
  .ss-payment__info--sub {
    margin: 10px 0 0; }

#main .step2 .tableBox th input[type="radio"] + label {
  background-image: url("../../img/common/radio_img03.png?20211129"); }

#main .step2 .tableBox th input[type="radio"]:checked + label {
  background-image: url("../../img/common/radio_img05.png?20211129"); }

.ss-form__password {
  position: relative;
  max-width: 388px; }
  .ss-form__password-actions {
    position: absolute;
    top: 9px;
    right: 7px;
    cursor: pointer; }
    .is-present .ss-form__password-actions {
      top: 5px; }
    @media (max-width: 767px) {
      .ss-form__password-actions {
        top: 15px; }
        .is-present .ss-form__password-actions {
          top: 12px; } }
    .ss-form__password-actions-item--on {
      display: none; }
      .is-hidden .ss-form__password-actions-item--on {
        display: block; }
    .ss-form__password-actions-item--off {
      display: block; }
      .is-hidden .ss-form__password-actions-item--off {
        display: none; }
    .ss-form__password-actions svg {
      vertical-align: top; }

.ss-shopping-payment__method-text-break--pc {
  display: inline; }
  @media (max-width: 767px) {
    .ss-shopping-payment__method-text-break--pc {
      display: none; } }

#main .step3 .topBox .ss-shopping__confirm-summary {
  width: 240px; }
  @media (max-width: 767px) {
    #main .step3 .topBox .ss-shopping__confirm-summary {
      width: 100%; } }

#main .step3 .topBox .ss-shopping__confirm-summary-num {
  white-space: nowrap; }

#main .step3 .topBox .ss-shopping__confirm-summary span.ss-shopping__confirm-summary-num {
  width: 130px; }
  @media (max-width: 767px) {
    #main .step3 .topBox .ss-shopping__confirm-summary span.ss-shopping__confirm-summary-num {
      width: 120px; } }

.ss-shopping-complete__title {
  white-space: nowrap; }
  @media (max-width: 374px) {
    .ss-shopping-complete__title {
      font-size: 16px; } }

.ss-shopping-payment__customer-regist-link:link {
  text-decoration: underline; }

.ss-shopping-payment__customer-regist-link:hover {
  text-decoration: none; }

.ss-amazon-pay__form-list {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .ss-amazon-pay__form-list {
      margin: 0 -5px; } }
  .ss-amazon-pay__form-list-item {
    margin-right: 27px;
    display: inline-block; }
    @media (max-width: 767px) {
      .ss-amazon-pay__form-list-item {
        display: flex;
        margin: 0 5px; } }
    .ss-amazon-pay__form-list-item span {
      min-width: 44px;
      display: inline-block; }
      @media (max-width: 767px) {
        .ss-amazon-pay__form-list-item span {
          font-size: 1.5rem;
          width: auto;
          min-width: 44px;
          padding-top: 12px; } }
    .ss-amazon-pay__form-list-item input[type="text"] {
      width: 100%;
      max-width: 147px;
      padding: 2px 8px;
      height: 35px;
      font-size: 1.4rem;
      box-sizing: border-box;
      appearance: none;
      -webkit-appearance: none;
      border: 1px solid #C4C4C3;
      border-radius: 0; }
      @media (max-width: 767px) {
        .ss-amazon-pay__form-list-item input[type="text"] {
          width: 85%;
          padding: 2px 12px;
          height: 46px;
          font-size: 1.25rem; } }

.ss-amazon-pay__form-zip {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px; }
  .ss-amazon-pay__form-zip.mb-10 {
    margin-bottom: 10px; }
  .ss-amazon-pay__form-zip-item {
    padding: 5px;
    box-sizing: border-box; }
    .ss-amazon-pay__form-zip-item input[type="text"] {
      width: 100%;
      max-width: 60px;
      padding: 2px 8px;
      height: 35px;
      font-size: 1.4rem;
      box-sizing: border-box;
      appearance: none;
      -webkit-appearance: none;
      border: 1px solid #C4C4C3;
      border-radius: 0; }
      @media (max-width: 767px) {
        .ss-amazon-pay__form-zip-item input[type="text"] {
          padding: 2px 12px;
          height: 46px;
          font-size: 1.25rem; } }

.ss-amazon-pay__form-address {
  margin: -5px 0; }
  .ss-amazon-pay__form-address-item {
    padding: 5px 0; }
    .ss-amazon-pay__form-address-item select {
      padding-left: 8px;
      width: 150px;
      height: 35px;
      box-sizing: border-box;
      font-size: 1.4rem;
      border: 1px solid #C4C4C3;
      border-radius: 5px; }
      @media (max-width: 767px) {
        .ss-amazon-pay__form-address-item select {
          height: 46px; } }
    .ss-amazon-pay__form-address-item input[type="text"] {
      width: 100%;
      max-width: 320px;
      padding: 2px 8px;
      height: 35px;
      font-size: 1.4rem;
      box-sizing: border-box;
      appearance: none;
      -webkit-appearance: none;
      border: 1px solid #C4C4C3;
      border-radius: 0; }
      @media (max-width: 767px) {
        .ss-amazon-pay__form-address-item input[type="text"] {
          padding: 2px 12px;
          height: 46px;
          font-size: 1.25rem; } }

.ss-amazon-pay__form-tel {
  display: flex;
  align-items: center; }
  .ss-amazon-pay__form-tel-item input[type="text"] {
    width: 100%;
    max-width: 100%;
    padding: 2px 8px;
    height: 35px;
    font-size: 1.4rem;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #C4C4C3;
    border-radius: 0; }
    @media (max-width: 767px) {
      .ss-amazon-pay__form-tel-item input[type="text"] {
        padding: 2px 12px;
        height: 46px;
        font-size: 1.25rem; } }

.ss-withdrawal__modal {
  color: #4d4d4d;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif; }
  .ss-withdrawal__modal .modaal-inner-wrapper {
    padding-left: 17px;
    padding-right: 17px; }
  .ss-withdrawal__modal .modaal-container {
    color: inherit; }
  .ss-withdrawal__modal .modaal-close {
    position: absolute;
    top: -30px;
    right: 0;
    width: 30px;
    height: 30px;
    background: #000;
    border-radius: 0; }
    @media (max-width: 767px) {
      .ss-withdrawal__modal .modaal-close {
        top: -35px;
        width: 35px;
        height: 35px; } }
    .ss-withdrawal__modal .modaal-close::before, .ss-withdrawal__modal .modaal-close::after {
      width: 1px;
      height: 20px;
      border-radius: 0;
      top: 5px;
      left: 15px;
      background: white; }
      @media (max-width: 767px) {
        .ss-withdrawal__modal .modaal-close::before, .ss-withdrawal__modal .modaal-close::after {
          height: 24px;
          top: 6px;
          left: 17px; } }
    .ss-withdrawal__modal .modaal-close:hover::before, .ss-withdrawal__modal .modaal-close:hover::after {
      background: rgba(255, 255, 255, 0.8); }
  .ss-withdrawal__modal .modaal-content-container {
    padding: 40px; }
    @media (max-width: 767px) {
      .ss-withdrawal__modal .modaal-content-container {
        padding: 30px; } }
    @media (max-width: 374px) {
      .ss-withdrawal__modal .modaal-content-container {
        padding: 20px; } }
  @media (max-width: 767px) {
    .ss-withdrawal__modal-body {
      width: 100%;
      max-width: 280px;
      margin: auto; } }

.ss-withdrawal__contents-item {
  border: solid 1px #53b06d;
  padding: 35px 25px 33px;
  margin: 0 0 20px; }
  @media (max-width: 767px) {
    .ss-withdrawal__contents-item {
      padding: 22px 29px 24px;
      margin: 0 0 10px; } }
  @media (max-width: 374px) {
    .ss-withdrawal__contents-item {
      padding: 22px 10px 24px; } }

.ss-withdrawal__def {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ss-withdrawal__def-term {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.22727;
    letter-spacing: 0.04em;
    text-align: center; }
    @media (max-width: 767px) {
      .ss-withdrawal__def-term {
        font-size: 22px;
        font-feature-settings: 'palt';
        letter-spacing: 0; } }
  .ss-withdrawal__def-desc {
    margin: 16px 0 0; }

.ss-withdrawal__break--sp {
  display: none; }
  @media (max-width: 767px) {
    .ss-withdrawal__break--sp {
      display: inline; } }

.ss-withdrawal__break--pc {
  display: inline; }
  @media (max-width: 767px) {
    .ss-withdrawal__break--pc {
      display: none; } }

.ss-withdrawal__list {
  font-size: 16px; }
  @media (max-width: 767px) {
    .ss-withdrawal__list {
      font-size: 14px; } }
  .ss-withdrawal__list-item {
    display: flex;
    font-size: inherit;
    line-height: 1.875; }
    @media (max-width: 767px) {
      .ss-withdrawal__list-item {
        line-height: 1.57143; } }
    .ss-withdrawal__list-item::before {
      content: "※"; }

.ss-withdrawal__text {
  font-size: inherit;
  line-height: inherit;
  font-feature-settings: 'palt';
  letter-spacing: 0.075em; }
  @media (max-width: 767px) {
    .ss-withdrawal__text {
      letter-spacing: 0; } }
  .ss-withdrawal__text--em {
    font-style: normal;
    font-weight: bold; }
  .ss-withdrawal__text-link {
    color: #4d4d4d; }
    .ss-withdrawal__text-link:-webkit-any-link {
      color: #4d4d4d; }
    .ss-withdrawal__text-link:link {
      color: #4d4d4d; }
    .ss-withdrawal__text-link:link {
      text-decoration: underline; }
    .ss-withdrawal__text-link:hover {
      text-decoration: none; }

.ss-withdrawal__info {
  margin: 20px -10px 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767px) {
    .ss-withdrawal__info {
      flex-wrap: wrap; } }
  .ss-withdrawal__info-image {
    flex-shrink: 0; }
  .ss-withdrawal__info-text {
    margin: 0 0 0 5px;
    font-size: 16px;
    letter-spacing: 0.04em;
    font-feature-settings: 'palt'; }
    @media (max-width: 767px) {
      .ss-withdrawal__info-text {
        font-size: 14px;
        letter-spacing: 0;
        margin: 5px 0 0; } }

.ss-withdrawal__actions-item {
  margin: 40px 0 0;
  text-align: center; }
  @media (max-width: 767px) {
    .ss-withdrawal__actions-item {
      margin: 35px 0 0; } }

.ss-withdrawal__actions-button {
  width: 100%;
  max-width: 400px;
  height: 60px;
  margin: 0 auto;
  border: none;
  border-radius: 30px;
  background: #53b06d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  font-family: inherit;
  cursor: pointer; }
  @media (max-width: 767px) {
    .ss-withdrawal__actions-button {
      max-width: 280px;
      height: 50px;
      border-radius: 25px;
      padding: 0 15px; } }
  .ss-withdrawal__actions-button-text {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-feature-settings: 'palt';
    letter-spacing: 0.05em; }
    @media (max-width: 767px) {
      .ss-withdrawal__actions-button-text {
        font-size: 16px;
        letter-spacing: 0.03em; } }

.ss-withdrawal__actions-link {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: bold; }
  @media (max-width: 767px) {
    .ss-withdrawal__actions-link {
      font-size: 14px; } }
  .ss-withdrawal__actions-link:-webkit-any-link {
    color: #4d4d4d; }
  .ss-withdrawal__actions-link:link {
    color: #4d4d4d; }
  .ss-withdrawal__actions-link:link {
    text-decoration: underline; }
  .ss-withdrawal__actions-link:hover {
    text-decoration: none; }

.ss-repeat__modal .modaal-close {
  position: absolute;
  top: -30px;
  right: 0;
  width: 30px;
  height: 30px;
  background: #000;
  border-radius: 0; }
  @media (max-width: 767px) {
    .ss-repeat__modal .modaal-close {
      top: -35px;
      width: 35px;
      height: 35px; } }
  .ss-repeat__modal .modaal-close::before, .ss-repeat__modal .modaal-close::after {
    width: 1px;
    height: 20px;
    border-radius: 0;
    top: 5px;
    left: 15px;
    background: white; }
    @media (max-width: 767px) {
      .ss-repeat__modal .modaal-close::before, .ss-repeat__modal .modaal-close::after {
        height: 24px;
        top: 6px;
        left: 17px; } }
  .ss-repeat__modal .modaal-close:hover::before, .ss-repeat__modal .modaal-close:hover::after {
    background: rgba(255, 255, 255, 0.8); }

.ss-repeat__modal .modaal-content-container {
  padding: 0; }

.ss-repeat__break--sp {
  display: none; }
  @media (max-width: 767px) {
    .ss-repeat__break--sp {
      display: block; } }

.ss-repeat__titlebox {
  background-color: #14a83c;
  color: #fff;
  padding: 5px 0;
  text-align: center;
  font-size: 18px; }
  @media (max-width: 767px) {
    .ss-repeat__titlebox {
      font-size: 16px; } }

.ss-repeat__contents {
  padding: 20px;
  margin: 20px;
  border: 2px solid #C8C8C7; }
  @media (max-width: 767px) {
    .ss-repeat__contents {
      padding: 15px;
      margin: 15px; } }
  .ss-repeat__contents-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; }
    .ss-repeat__contents-item_img {
      width: 32.5%; }
    .ss-repeat__contents-item_textbox {
      width: 64%; }
    .ss-repeat__contents-item_name {
      margin-bottom: 5px;
      display: block;
      font-size: 1.8rem;
      font-weight: bold;
      color: #040000;
      font-family: 'Hiragino Kaku Gothic ProN'; }
      @media (max-width: 767px) {
        .ss-repeat__contents-item_name {
          font-size: 1.4rem; } }
    .ss-repeat__contents-item_pricetext, .ss-repeat__contents-item_priceinctaxtext {
      font-size: 1.4rem;
      display: flex;
      align-items: flex-end; }
      @media (max-width: 767px) {
        .ss-repeat__contents-item_pricetext, .ss-repeat__contents-item_priceinctaxtext {
          font-size: 1.2rem; } }
    .ss-repeat__contents-item_price, .ss-repeat__contents-item_priceinctax {
      font-size: 1.8rem;
      font-weight: bold;
      color: #040000;
      padding: 0 5px; }
      @media (max-width: 767px) {
        .ss-repeat__contents-item_price, .ss-repeat__contents-item_priceinctax {
          font-size: 1.4rem; } }
  .ss-repeat__contents-select {
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      .ss-repeat__contents-select {
        margin-bottom: 10px; } }
    .ss-repeat__contents-select_text {
      padding-bottom: 10px;
      text-align: left;
      vertical-align: middle;
      font-size: 1.6rem;
      display: block; }
      @media (max-width: 767px) {
        .ss-repeat__contents-select_text {
          font-size: 1.3rem; } }
    .ss-repeat__contents-select_box {
      width: 175px;
      height: 45px;
      padding-left: 10px;
      border: 1px solid #B9B9B9;
      border-radius: 5px;
      font-size: 1.6rem;
      font-weight: bold;
      box-sizing: border-box;
      margin-left: 20px; }
      @media (max-width: 767px) {
        .ss-repeat__contents-select_box {
          margin-left: 15px;
          width: calc(100% - 58px); } }
      .ss-repeat__contents-select_box.ml {
        margin-left: 36px; }
        @media (max-width: 767px) {
          .ss-repeat__contents-select_box.ml {
            margin-left: 28px; } }

@media (max-width: 767px) {
  .ss-repeat__actions {
    padding-bottom: 10px; } }

.ss-repeat__actions-button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 259px;
  padding: 15px 5px;
  display: block;
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  border-radius: 5px;
  background-color: #dc143c;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer; }
  @media (max-width: 767px) {
    .ss-repeat__actions-button {
      font-size: 1.4rem;
      padding: 10px 5px; } }

.ss-repeat__actions-item {
  text-align: center;
  padding-bottom: 10px; }
  @media (max-width: 767px) {
    .ss-repeat__actions-item {
      padding-bottom: 0px; } }

.ss-repeat__actions-link {
  text-decoration: underline !important; }

.ss-mypage__repurchase-btn {
  display: flex;
  max-width: 110px;
  margin-top: 8px; }
  @media (max-width: 767px) {
    .ss-mypage__repurchase-btn {
      justify-content: center;
      margin: 5px auto 0;
      max-width: 100%; } }
  .ss-mypage__repurchase-btn_link {
    background-color: #FF8B07;
    color: #fff !important;
    line-height: 1;
    padding: 10px 10px;
    display: block;
    width: 100%;
    text-align: center; }
    .ss-mypage__repurchase-btn_link:hover {
      opacity: .8; }
    @media (max-width: 767px) {
      .ss-mypage__repurchase-btn_link {
        padding: 8px 10px; }
        .ss-mypage__repurchase-btn_link:hover {
          opacity: 1; } }

.ss-mypage__repurchase-name_link {
  color: #221714;
  margin-bottom: 4px;
  display: block; }

.ss-credit__info-text--security {
  margin: 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap; }

.ss-credit__info-text-link:link {
  text-decoration: underline; }
  .ss-credit__info-text-link:link:hover {
    text-decoration: none; }

.ss-withdrawal__def-term {
  line-height: 1.50231; }
  @media (max-width: 767px) {
    .ss-withdrawal__def-term {
      line-height: 1.22727; } }

.ss-withdrawal__description {
  font-size: 16px;
  line-height: 1.875;
  margin: 4px auto 0;
  max-width: 430px; }
  @media (max-width: 767px) {
    .ss-withdrawal__description {
      font-size: 14px;
      line-height: 1.57143; } }
  .ss-withdrawal__description .ss-withdrawal__text:first-child {
    margin-bottom: 1.875em; }
    @media (max-width: 767px) {
      .ss-withdrawal__description .ss-withdrawal__text:first-child {
        margin-bottom: 1.57143em; } }

.ss-shopping-payment__shipping {
  margin-top: 68px; }
  @media (max-width: 767px) {
    .ss-shopping-payment__shipping {
      margin-top: 32px; } }
  @media (max-width: 767px) {
    #main .step2 .sec02 .selectUl .ss-shopping-payment__shipping-select {
      width: auto; } }

.ss-shopping-payment__wrapping {
  overflow: hidden;
  margin: 68px 0; }
  @media (max-width: 767px) {
    .ss-shopping-payment__wrapping {
      margin: 32px 0; } }
  .ss-shopping-payment__wrapping-radio {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -15px 0; }
    .ss-shopping-payment__wrapping-radio-item {
      padding: 10px 15px; }
      .ss-shopping-payment__wrapping-radio-item-input {
        display: none; }
      .ss-shopping-payment__wrapping-radio-item-label {
        display: flex;
        align-items: center;
        font-size: 16px; }
        .ss-shopping-payment__wrapping-radio-item-label::before {
          content: "";
          width: 20px;
          height: 20px;
          background: url("../../img/common/radio_img03.png") no-repeat left center;
          background-size: 20px;
          margin-right: 6px;
          flex-shrink: 0; }
          input[type="radio"]:checked + .ss-shopping-payment__wrapping-radio-item-label::before {
            background-image: url("../../img/common/radio_img05.png"); }

.ss-amazon-pay__cart-button {
  display: block !important;
  width: 100% !important;
  max-width: 242px; }
  #main .comSubmit .ss-amazon-pay__cart-button {
    margin: 16px auto; }
  .ss-amazon-pay__cart-button #AmazonPayButton1 {
    height: 55px; }

.ss-shopping-payment__wrapping-description {
  margin: 16px 0 0; }
  @media (max-width: 767px) {
    .ss-shopping-payment__wrapping-description {
      font-size: 14px;
      line-height: 1.71; } }

@media (max-width: 767px) {
  .ss-shopping-payment__wrapping-notes-list, .ss-shopping-payment__wrapping-case-list {
    font-size: 14px;
    line-height: 1.71; } }

.ss-shopping-payment__wrapping-notes-list-item, .ss-shopping-payment__wrapping-case-list-item {
  display: flex; }

.ss-shopping-payment__wrapping-notes-label, .ss-shopping-payment__wrapping-case-label {
  flex-shrink: 0; }

.ss-shopping-payment__wrapping-notes-text, .ss-shopping-payment__wrapping-case-text {
  margin-left: 4px; }

.ss-shopping-payment__wrapping-def-term, .ss-shopping-payment__wrapping-def-desc {
  display: flex; }
  .ss-shopping-payment__wrapping-def-term::before, .ss-shopping-payment__wrapping-def-desc::before {
    width: 1em;
    flex-shrink: 0; }

.ss-shopping-payment__wrapping-def-term {
  margin: 16px 0 0; }
  @media (max-width: 767px) {
    .ss-shopping-payment__wrapping-def-term {
      font-size: 14px;
      line-height: 1.71; } }
  .ss-shopping-payment__wrapping-def-term::before {
    content: "※"; }

.ss-shopping-payment__wrapping-def-desc::before {
  content: ""; }

.ss-shopping-payment__notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  width: 100%;
  max-width: 400px; }
  .ss-shopping-payment__notes-text {
    font-size: 16px;
    text-align: center; }
    @media (max-width: 767px) {
      .ss-shopping-payment__notes-text {
        text-align: left; }
        .ss-shopping-payment__notes-text-break {
          display: none; } }
  .ss-shopping-payment__notes-tel {
    font-size: 22px;
    font-weight: bold; }

.ss-withdrawal__contents-item.is-submit {
  padding-left: 15px;
  padding-right: 15px; }
  @media (max-width: 767px) {
    .ss-withdrawal__contents-item.is-submit {
      padding-left: 5px;
      padding-right: 5px; } }

.ss-withdrawal__submit-title {
  font-size: 0.96154em; }
  @media (max-width: 767px) {
    .ss-withdrawal__submit-title {
      font-size: 0.59091em; } }

.ss-withdrawal__submit-list-item {
  font-size: 23px;
  line-height: 1.69957;
  font-weight: bold;
  text-align: center; }
  @media (max-width: 767px) {
    .ss-withdrawal__submit-list-item {
      font-size: 13px;
      line-height: 1.23077; } }

.ss-withdrawal__submit-notes {
  font-size: 18px;
  text-align: center;
  margin: 18px 0 0; }
  @media (max-width: 767px) {
    .ss-withdrawal__submit-notes {
      font-size: 12.43px;
      line-height: 1.33347; } }
  .ss-withdrawal__submit-notes-break {
    display: none; }
    @media (max-width: 767px) {
      .ss-withdrawal__submit-notes-break {
        display: inline; } }

.ss-shopping-confirm__actions {
  margin-bottom: 79px; }

.ss-ukomi__inr {
  padding: 0px 0px 0;
  width: 1112px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .ss-ukomi__inr {
      padding: 20px 0px 0;
      width: 100%;
      margin: 0 auto; } }

.ss-wrapping {
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 767px) {
    .ss-wrapping {
      justify-content: flex-start; } }
  .ss-wrapping__body {
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .ss-wrapping__body {
        flex-grow: 1; } }
  .ss-wrapping__item {
    padding: 10px 0; }
  .ss-wrapping__input {
    display: none; }
  .ss-wrapping__label {
    display: flex;
    align-items: center; }
    .ss-wrapping__label.is-error {
      background-color: #e200121a; }
    .ss-wrapping__label-image {
      width: 20px;
      height: 20px;
      background: url("../../img/common/radio_img03.png?20211129") no-repeat left center;
      background-size: 20px; }
      .ss-wrapping__input:checked + .ss-wrapping__contents .ss-wrapping__label-image {
        background-image: url("../../img/common/radio_img05.png?20211129"); }
    .ss-wrapping__label-text {
      font-size: 16px;
      margin: 0 0 0 4px; }
  .ss-wrapping__detail {
    display: none;
    align-items: center;
    padding: 10px 0 0; }
    .ss-wrapping__input:checked + .ss-wrapping__contents .ss-wrapping__detail {
      display: flex; }
    .ss-wrapping__detail-image {
      flex-shrink: 0;
      width: 95px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .ss-wrapping__detail-contents {
      flex-grow: 1;
      padding-left: 16px; }
    .ss-wrapping__detail-input {
      display: none; }
    .ss-wrapping__detail-label {
      display: flex;
      align-items: center;
      padding: 6px 0; }
      .ss-wrapping__detail-label-image {
        width: 20px;
        height: 20px;
        background: url("../../img/common/radio_img03.png?20211129") no-repeat left center;
        background-size: 20px; }
        .ss-wrapping__detail-input:checked + .ss-wrapping__detail-label .ss-wrapping__detail-label-image {
          background-image: url("../../img/common/radio_img05.png?20211129"); }
      .ss-wrapping__detail-label-text {
        font-size: 14px;
        margin: 0 0 0 4px; }

#main .comSubmit li .ss-form__submit-button.is-red {
  background-color: #dc143c;
  border-color: #dc143c; }

.ss-withdrawal__submit-text {
  font-size: 23px;
  line-height: 1.69957;
  font-weight: bold;
  text-align: center;
  color: #dc143c; }
  @media (max-width: 767px) {
    .ss-withdrawal__submit-text {
      font-size: 13px;
      line-height: 1.23077; } }

@media (max-width: 767px) {
  #main .ss-refusal__notes.topTxt {
    margin-top: 30px; } }

.ss-refusal__message {
  max-width: 600px;
  margin: 40px auto 60px; }
  @media (max-width: 767px) {
    .ss-refusal__message {
      margin-bottom: 20px; } }
  .ss-refusal__message-term {
    font-weight: bold;
    font-size: 18px; }
  .ss-refusal__message-desc {
    font-size: 16px;
    margin: 20px 0 0; }

.ss-refusal__shop {
  max-width: 600px;
  margin: 0 auto; }

.ss-refusal-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px; }
  .ss-refusal-check__label {
    display: flex;
    align-items: center; }
  .ss-refusal-check__text {
    font-size: 16px;
    line-height: 1.2;
    margin-left: 4px; }

div#zeus_token_card_info_area {
  padding: 13px 0 0; }
  div#zeus_token_card_info_area label {
    margin: 20px 0 10px;
    font-size: 14px;
    font-weight: bold; }
  div#zeus_token_card_info_area input[type='tel'] {
    appearance: none;
    padding: 2px 8px;
    height: 35px;
    font-size: 1.4rem;
    box-sizing: border-box;
    border: 1px solid #C4C4C3;
    border-radius: 0; }
    @media (max-width: 767px) {
      div#zeus_token_card_info_area input[type='tel'] {
        padding: 2px 12px;
        width: 100%;
        height: 46px;
        font-size: 1.25rem; } }
    div#zeus_token_card_info_area input[type='tel']#zeus_token_card_cvv {
      width: auto; }
  div#zeus_token_card_info_area input[type='radio'] {
    margin: 0px 8px 0 0; }
  div#zeus_token_card_info_area span {
    display: inline-block; }

div#zeus_registerd_card_area {
  margin: 20px 0 24px;
  padding: 0 0 24px;
  border-bottom: solid 1px #C4C4C3; }

div#zeus_new_card_area {
  margin: 20px 0; }

#zeus_token_card_expires_note {
  margin-top: 5px; }

#zeus_token_card_expires_month_suffix,
#zeus_token_card_expires_year_suffix {
  padding: 0 5px; }

#main .mailForm .mailTable td #zeus_token_card_expires_month, #main .mailForm .mailTable td
#zeus_token_card_expires_year {
  width: auto; }

.ss-wrapping__label {
  border: solid 1px #fff;
  padding: 4px; }
  .ss-wrapping__label.is-hidden {
    display: none; }
  .ss-wrapping__label-image {
    flex-shrink: 0; }
  .ss-wrapping__label-text {
    flex-grow: 1; }
  .ss-wrapping__input:checked + .ss-wrapping__contents > .ss-wrapping__label {
    background-color: #fff7f7;
    border: solid 1px #C5C5C4; }

.ss-wrapping__detail {
  display: flex;
  padding: 0; }
  .ss-wrapping__detail-image {
    margin-right: 16px; }
  .ss-wrapping__detail-contents {
    padding-left: 0; }
  .ss-wrapping__detail-label {
    border: solid 1px #fff;
    padding: 4px; }
    .ss-wrapping__detail-label-image {
      flex-shrink: 0; }
    .ss-wrapping__detail-label-text {
      flex-grow: 1;
      font-size: 16px; }
    .ss-wrapping__detail-input:checked + .ss-wrapping__detail-label {
      background-color: #fff7f7;
      border: solid 1px #C5C5C4; }

.ss-shopping-login__notes {
  margin-top: 10px; }
  #main .comLoginBox .ss-shopping-login__notes.lBox {
    height: auto; }

#gFooter .copyright {
  margin-top: 42px; }
  @media (max-width: 767px) {
    #gFooter .copyright {
      margin-top: 19px; } }

.is-active-color {
  background: #fff7f7;
  border: solid 2px #acacac; }

.ss-shopping-complete__title {
  white-space: inherit;
  margin: 0 -25px; }
  .ss-shopping-complete__title::before, .ss-shopping-complete__title::after {
    margin: 0 15px; }

.ss-shopping-payment__method-detail {
  display: none; }

.ss-shopping-payment__method-detail.is-open {
  display: block; }

.ss-credit__cvv-info {
  display: inline-flex;
  margin: 0px 0 0 30px;
  transform: translateY(-12px); }
  @media (max-width: 767px) {
    .ss-credit__cvv-info {
      transform: translateY(-15px); } }
  @media (max-width: 424px) {
    .ss-credit__cvv-info {
      margin: 0px 0 0 2px; } }
  .ss-credit__cvv-info-image {
    width: 80px;
    flex-shrink: 0; }
    @media (max-width: 767px) {
      .ss-credit__cvv-info-image {
        width: 100px; } }
    @media (max-width: 424px) {
      .ss-credit__cvv-info-image {
        width: 60px; } }
  .ss-credit__cvv-info-line {
    width: 30px;
    height: 2px;
    background: #df2d18;
    margin: 19px 0 0 -26px; }
    @media (max-width: 767px) {
      .ss-credit__cvv-info-line {
        margin: 25px 0 0 -32px; } }
    @media (max-width: 424px) {
      .ss-credit__cvv-info-line {
        margin: 15px 0 0 -19px;
        height: 1px;
        width: 20px; } }
  .ss-credit__cvv-info-text {
    font-size: 11px;
    margin: 12px 0 0 10px; }
    @media (max-width: 767px) {
      .ss-credit__cvv-info-text {
        margin: 18px 0 0 10px; } }
    @media (max-width: 424px) {
      .ss-credit__cvv-info-text {
        font-size: 10px;
        margin: 7px 0 0 4px; } }

#main .step2 .sec04 .note {
  color: #000; }

#gFooter .fBox .fSns {
  display: none; }

.ss-shopping-payment__notes.is-shopping {
  margin: 15px auto; }

#container.no-padding-top {
  padding-top: 0px !important; }

.ss-policy-agreement {
  margin: 30px auto; }
  .ss-policy-agreement__check {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    .ss-policy-agreement__check-input {
      display: none; }
    .ss-policy-agreement__check-label {
      font-weight: bold;
      padding: 4px 0 4px 28px;
      display: inline-block;
      background: url(../../img/common/check_img03.png) no-repeat left center;
      background-size: 23px 23px; }
      .ss-policy-agreement__check-input:checked + .ss-policy-agreement__check-label {
        background-image: url(../../img/common/check_img04.png); }
  .ss-policy-agreement__contents {
    word-break: break-all; }
    .ss-policy-agreement__contents .comScroll .scroll {
      margin-bottom: 0;
      padding: 13px 26px;
      height: 276px; }
    .ss-policy-agreement__contents .comScroll .scroll p {
      margin: 0; }
    .ss-policy-agreement__contents .comScroll .scroll .title {
      margin-bottom: 11px;
      font-size: 1.6rem;
      text-align: center; }
    .ss-policy-agreement__contents .comScroll .scroll .borderTxt {
      border-top: 1px solid #C8C8C7;
      margin-top: 40px;
      padding-top: 50px; }
    .ss-policy-agreement__contents .comScroll .scroll table {
      margin: 21px 0 44px; }
    .ss-policy-agreement__contents .comScroll .scroll th,
    .ss-policy-agreement__contents .comScroll .scroll td {
      padding: 11px 18px;
      line-height: 1.4; }
    .ss-policy-agreement__contents .comScroll .scroll th {
      width: 25%; }
    .ss-policy-agreement__contents .comScroll .scroll td ul {
      margin-top: 7px; }
    .ss-policy-agreement__contents .comScroll .scroll td ul li {
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.55; }
    @media all and (max-width: 767px) {
      .ss-policy-agreement__contents .comScroll .scroll {
        padding: 8px 16px; }
      .ss-policy-agreement__contents .comScroll .scroll .title {
        margin-bottom: 2px;
        font-size: 1.4rem; }
      .ss-policy-agreement__contents .comScroll .scroll p {
        font-size: 1.25rem; }
      .ss-policy-agreement__contents .comScroll .scroll .borderTxt {
        padding-top: 16px; }
      .ss-policy-agreement__contents .comScroll .scroll th {
        width: 31%; }
      .ss-policy-agreement__contents .comScroll .scroll .table {
        margin: 3px 0 28px;
        border-bottom: 1px solid #C4C4C3; }
      .ss-policy-agreement__contents .comScroll .scroll th,
      .ss-policy-agreement__contents .comScroll .scroll td {
        padding: 8px 13px; }
      .ss-policy-agreement__contents .comScroll .scroll .table th,
      .ss-policy-agreement__contents .comScroll .scroll .table td {
        padding: 5px 13px;
        line-height: 1.84;
        font-size: 1.25rem;
        display: block;
        width: 100%;
        box-sizing: border-box;
        border-bottom: none; }
      .ss-policy-agreement__contents .comScroll .scroll .table th {
        font-weight: bold; }
      .ss-policy-agreement__contents .comScroll .scroll td ul {
        margin-top: 23px; }
      .ss-policy-agreement__contents .comScroll .scroll td ul li:not(:last-child) {
        margin-bottom: 24px; } }

.ss-form__submit-button.js-form-submit:disabled, #main .comSubmit li .ss-form__submit-button.js-form-submit:disabled {
  background-color: #d4d4d4;
  border-color: #d4d4d4;
  cursor: not-allowed; }
